/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskType = "INDIVIDUAL" | "TEAM";
export type Challenge_challenge = {
    readonly id: string;
    readonly task: {
        readonly banner: {
            readonly url: string;
        };
        readonly completion: {
            readonly startedAt: string | null;
        } | null;
        readonly id: string;
        readonly intro: string;
        readonly title: string;
        readonly type: TaskType;
        readonly " $fragmentRefs": FragmentRefs<"TaskPopup_task">;
    };
    readonly " $refType": "Challenge_challenge";
};
export type Challenge_challenge$data = Challenge_challenge;
export type Challenge_challenge$key = {
    readonly " $data"?: Challenge_challenge$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Challenge_challenge">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "Challenge_challenge",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadedFile",
                        "kind": "LinkedField",
                        "name": "banner",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskCompletion",
                        "kind": "LinkedField",
                        "name": "completion",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startedAt",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "intro",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                    },
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "TaskPopup_task"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Challenge",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'ba0964c2eee192d6d27a2b9cc3fde945';
export default node;
