import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { OpenQuestionInteractionField_field$key } from '../../../generated/OpenQuestionInteractionField_field.graphql'
import { never } from '../../../utils/assert'
import { UpdateAnswersAction } from './OpenQuestionInteraction'

import styles from './OpenQuestionInteractionField.scss'

interface OpenQuestionInteractionFieldProps {
  disabled: boolean
  field: OpenQuestionInteractionField_field$key
  update(action: UpdateAnswersAction): void
  value: string
}

export function OpenQuestionInteractionField(
  props: OpenQuestionInteractionFieldProps
): ReactElement {
  const { update } = props
  const field = useFragment(
    graphql`
      fragment OpenQuestionInteractionField_field on OpenQuestionField
      @argumentDefinitions(language: { type: "String!" }) {
        fieldType
        id
        label(language: $language)
      }
    `,
    props.field
  )

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      update({
        id: field.id,
        value: event.target.value,
      })
    },
    [field.id, update]
  )

  const inputId = 'oqif' + field.id
  let inputElement: ReactElement
  switch (field.fieldType) {
    case 'ONE_LINE':
      inputElement = (
        <input
          className={styles.input}
          id={inputId}
          onChange={onChange}
          readOnly={props.disabled}
          type='text'
          value={props.value}
        />
      )
      break
    case 'MULTILINE':
      inputElement = (
        <textarea
          className={styles.input}
          id={inputId}
          onChange={onChange}
          readOnly={props.disabled}
          value={props.value}
        />
      )
      break
    default:
      never(
        field.fieldType,
        `Invalid open question field type: '${field.fieldType}'`
      )
  }

  return (
    <>
      <label className={styles.label} htmlFor={inputId}>
        {field.label}
      </label>

      {inputElement}
    </>
  )
}
