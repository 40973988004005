/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BadgeEarnedPopup_badge = {
    readonly earnedText: string;
    readonly id: string;
    readonly image: {
        readonly url: string;
    };
    readonly pivot: {
        readonly lastClaimedStars: number;
        readonly stars: number;
    } | null;
    readonly " $refType": "BadgeEarnedPopup_badge";
};
export type BadgeEarnedPopup_badge$data = BadgeEarnedPopup_badge;
export type BadgeEarnedPopup_badge$key = {
    readonly " $data"?: BadgeEarnedPopup_badge$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BadgeEarnedPopup_badge">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BadgeEarnedPopup_badge",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "earnedText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UserBadge",
            "kind": "LinkedField",
            "name": "pivot",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastClaimedStars",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stars",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Badge",
    "abstractKey": null
} as any;
(node as any).hash = 'f27d74a596021cdc4c7e7b37766b48ab';
export default node;
