/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskDescriptionMedia_upload = {
    readonly mime: string;
    readonly url: string;
    readonly " $refType": "TaskDescriptionMedia_upload";
};
export type TaskDescriptionMedia_upload$data = TaskDescriptionMedia_upload;
export type TaskDescriptionMedia_upload$key = {
    readonly " $data"?: TaskDescriptionMedia_upload$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskDescriptionMedia_upload">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskDescriptionMedia_upload",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mime",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
        }
    ],
    "type": "UploadedFile",
    "abstractKey": null
} as any;
(node as any).hash = 'd20f1b880e560266625acd624a3a6e37';
export default node;
