import { MouseEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import logo from '../../../../../images/logo/white.svg'
import { EditProfilePopupMutation } from '../../generated/EditProfilePopupMutation.graphql'
import { useOnChangeCallback } from '../../utils/hooks/useOnChangeCallback'
import {
  EditProfilePopup as EditProfilePopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'
import { useRelayMirroredState } from '../../utils/hooks/useRelayMirroredState'
import { EditableAvatar } from '../common/EditableAvatar'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './EditProfilePopup.scss'

export function EditProfilePopup(
  props: PopupProps<EditProfilePopupProps>
): ReactElement {
  const { t } = useTranslation()
  const { closeCurrentPopup } = props

  const user = useFragment(
    graphql`
      fragment EditProfilePopup_user on AuthenticatedUser {
        avatar
        firstName
        function
        id
        lastName
        departments {
          name
        }
        ...EditableAvatar_user
      }
    `,
    props.user
  )

  const [firstName, setFirstName] = useRelayMirroredState(
    user.firstName,
    user.id,
    'firstName'
  )
  const [lastName, setLastName] = useRelayMirroredState(
    user.lastName,
    user.id,
    'lastName'
  )
  const [userFunction, setFunction] = useRelayMirroredState(
    user.function,
    user.id,
    'function'
  )

  const onFirstNameChanged = useOnChangeCallback(setFirstName)
  const onLastNameChanged = useOnChangeCallback(setLastName)
  const onFunctionChanged = useOnChangeCallback(setFunction)

  const department = user.departments[0]?.name

  const [updateProfile, updateProfileResult] =
    useMutation<EditProfilePopupMutation>(graphql`
      mutation EditProfilePopupMutation(
        $firstName: String!
        $lastName: String!
        $function: String!
      ) {
        updateProfile(
          firstName: $firstName
          lastName: $lastName
          function: $function
        ) {
          firstName
          lastName
          function
        }
      }
    `)

  const submit = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      updateProfile({
        variables: {
          firstName,
          lastName,
          function: userFunction,
        },
      }).then(closeCurrentPopup)
    },
    [updateProfile, firstName, lastName, closeCurrentPopup, userFunction]
  )

  return (
    <div className={styles.popup}>
      <div className={styles.banner}>
        <img src={logo} className={styles.logo} />
      </div>
      <div className={styles.content}>
        <h1>{t('profile.edit.header')}</h1>
        <p>{t('profile.edit.description')}</p>

        <h2>Profiel</h2>
        <form className={styles.form}>
          <div className={styles.column}>
            <label className={styles.label} htmlFor='#firstName'>
              {t('profile.fields.firstName')}
            </label>
            <input
              type='text'
              id='firstName'
              className={styles.textField}
              onChange={onFirstNameChanged}
              value={firstName}
            />

            <label className={styles.label} htmlFor='#lastName'>
              {t('profile.fields.lastName')}
            </label>
            <input
              type='text'
              id='lastName'
              className={styles.textField}
              onChange={onLastNameChanged}
              value={lastName}
            />

            <label className={styles.label} htmlFor='#function'>
              {t('profile.fields.function')}
            </label>
            <input
              type='text'
              id='function'
              className={styles.textField}
              onChange={onFunctionChanged}
              value={userFunction}
            />

            {department && (
              <>
                <label className={styles.label}>
                  {t('profile.fields.department')}
                </label>
                <input
                  type='text'
                  className={styles.textField}
                  readOnly
                  value={department}
                />
              </>
            )}
          </div>

          <div className={styles.column}>
            <label className={styles.label} htmlFor='avatar'>
              {t('profile.fields.avatar')}
            </label>

            <div className={styles.avatarContainer}>
              <EditableAvatar user={user} />
            </div>

            <PrimaryButton
              className={styles.button}
              disabled={
                // Don't allow submitting if one of the required things is missing
                !firstName ||
                !lastName ||
                !userFunction ||
                !user.avatar ||
                // Or we're already submitting
                updateProfileResult.loading ||
                // Or we're done (the popup would be currently closing if that's the case)
                updateProfileResult.data !== null
              }
              onClick={submit}
            >
              {t('profile.save')}
            </PrimaryButton>

            {updateProfileResult.error && (
              <GraphQlError error={updateProfileResult.error} />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
