/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Assignment_assignment = {
    readonly id: string;
    readonly availableFrom: string | null;
    readonly isAvailable: boolean;
    readonly task: {
        readonly banner: {
            readonly url: string;
        };
        readonly completion: {
            readonly completedAt: string | null;
        } | null;
        readonly id: string;
        readonly intro: string;
        readonly title: string;
        readonly " $fragmentRefs": FragmentRefs<"TaskPopup_task">;
    };
    readonly " $refType": "Assignment_assignment";
};
export type Assignment_assignment$data = Assignment_assignment;
export type Assignment_assignment$key = {
    readonly " $data"?: Assignment_assignment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Assignment_assignment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "Assignment_assignment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableFrom",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAvailable",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadedFile",
                        "kind": "LinkedField",
                        "name": "banner",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskCompletion",
                        "kind": "LinkedField",
                        "name": "completion",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "completedAt",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "intro",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "TaskPopup_task"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Assignment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '9ce01ff1bd8052d4ae2e61808bae3714';
export default node;
