import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import styles from './TopBar.scss'

interface TopBarProps {
  noLink?: boolean
}

export function TopBar(props: TopBarProps): ReactElement {
  const logo = (
    <svg
      className={styles.logo}
      xmlns='http://www.w3.org/2000/svg'
      width='181'
      height='44.549'
      viewBox='0 0 181 44.549'
    >
      <g transform='translate(-7.968 -7.443)'>
        <path
          d='M41.31,7.443H7.968V35.322L24.639,51.993V18.511L41.31,35.182Z'
          fill='#009286'
        />
        <path
          d='M117.487,85.893l-16.671,16.671V69.082Z'
          transform='translate(-76.176 -50.571)'
          fill='#f3c000'
        />
        <path
          d='M387.722,20.713C381,20.713,381,25.615,381,30.8s0,10.085,6.726,10.085,6.725-4.9,6.725-10.085,0-10.086-6.725-10.086Zm-123.981.419-5.325,19.473h3.924l1.122-4.342h5.6l1.119,4.342h4.342L269.2,21.132Zm14.149,0V40.605h7.144c1.681,0,6.025-.279,6.025-5.182,0-2.662-.7-4.483-3.642-4.9a4.026,4.026,0,0,0,3.363-4.345c0-4.2-2.522-5.043-5.6-5.043Zm17.933,0V40.605H299.6V26.317h.14l5.325,14.288h5.325V21.132h-3.784V34.58l-5.182-13.448Zm30.259,0-5.324,19.473h3.924l1.122-4.342h5.6l1.119,4.342h4.342l-5.461-19.473Zm14.009,0V40.605h3.924V25.616h.14l3.5,14.989h3.921l3.784-14.989V40.605h3.921V21.132h-6.3l-3.22,13.309-3.084-13.309Zm24.516.14V40.605h4.063V32.479h1.119c3.082,0,2.944,1.963,2.944,4.345a8.766,8.766,0,0,0,.419,3.781h3.924c-.42-.84-.421-4.482-.421-5.743,0-3.5-2.662-3.784-3.5-3.784,2.662-.42,3.642-2.239,3.642-4.761,0-3.362-1.82-5.046-4.621-5.046Zm23.115,2.523c2.241,0,2.523,2.1,2.523,7.144s-.141,7.144-2.523,7.144c-2.242,0-2.523-2.1-2.523-7.144S385.481,23.794,387.722,23.794Zm-105.77.279h2.523a2.4,2.4,0,0,1,2.381,2.523,2.27,2.27,0,0,1-2.1,2.523h-2.8Zm86.717,0h1.82c1.261,0,2.1.7,2.1,2.523,0,1.261-.42,2.8-2.1,2.8h-1.82Zm-102.549.843,2.1,8.406H264.3Zm62.341,0,2.1,8.406h-3.921Zm-11.6,1.844-2.575,2.577,2.577,2.575,2.575-2.577Zm-34.905,5.3h2.38c2.1,0,2.662,1.121,2.662,2.8a2.5,2.5,0,0,1-2.8,2.8h-2.241Z'
          transform='translate(-205.479 -10.887)'
          fill='#868686'
        />
      </g>
    </svg>
  )

  return (
    <div className={styles.bar}>
      <div className={styles.container}>
        <div className={styles.row}>
          {props.noLink ? logo : <Link to='/'>{logo}</Link>}
        </div>
      </div>
    </div>
  )
}
