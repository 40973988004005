import { ReactElement, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { DashboardPageQuery } from '../../generated/DashboardPageQuery.graphql'
import { classNames } from '../../utils/classNames'
import { useResizeAwareLayoutEffect } from '../../utils/hooks/useResizeAwareLayoutEffect'
import {
  rectFromBoundingRect,
  TutorialContext,
  TutorialSteps,
} from '../../utils/tutorial'
import { GraphQlError } from '../common/GraphQlError'
import { HeaderEditableAvatar } from '../common/HeaderEditableAvatar'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { NavigationBar } from '../common/NavigationBar'
import { BadgeStats } from '../dashboard/BadgeStats'
import { ChallengesOverview } from '../dashboard/ChallengesOverview'
import { Mission } from '../dashboard/Mission'
import { AssignmentStats } from '../dashboard/stats/AssignmentStats'
import { ChallengeStats } from '../dashboard/stats/ChallengeStats'
import { MissionStats } from '../dashboard/stats/MissionStats'
import { XpStats } from '../dashboard/stats/XpStats'
import { TaskforcesOverview } from '../dashboard/TaskforcesOverview'
import { TeamOverview } from '../dashboard/TeamOverview'

import styles from './DashboardPage.scss'

export function DashboardPage(): ReactElement {
  const { i18n, t } = useTranslation()
  const { data, isLoading, error, retry } = useQuery<DashboardPageQuery>(
    graphql`
      query DashboardPageQuery($language: String!, $pageSize: Int!) {
        me {
          taskforce {
            ...TaskforcesOverview_user
          }
          team {
            ...TeamOverview_team
          }
          ...BadgeStats_stats @arguments(language: $language)
        }
        taskforces {
          ...TaskforcesOverview_taskforces
        }
        ...AssignmentStats_stats @arguments(first: $pageSize)
        ...ChallengeStats_stats @arguments(first: $pageSize)
        ...ChallengesOverview_stats @arguments(first: $pageSize)
        ...Mission_mission @arguments(language: $language)
        ...MissionStats_stats @arguments(first: $pageSize)
        ...XpStats_stats
      }
    `,
    {
      language: i18n.language,
      pageSize: 100,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const [, updateTutorial] = useContext(TutorialContext)

  const dashboardTutorialTarget = useRef<HTMLDivElement>(null)
  const taskforceTutorialTarget = useRef<HTMLDivElement>(null)
  const missionTutorialTarget = useRef<HTMLDivElement>(null)
  const challengesTutorialTarget = useRef<HTMLDivElement>(null)
  const badgesTutorialTarget = useRef<HTMLDivElement>(null)
  const xpTutorialTarget = useRef<HTMLDivElement>(null)
  useResizeAwareLayoutEffect(() => {
    if (dashboardTutorialTarget.current) {
      const rect = dashboardTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.Dashboard,
          text: 'tutorial.dashboard',
          ...rectFromBoundingRect(rect),
        },
      })
    }
    if (taskforceTutorialTarget.current) {
      const rect = taskforceTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.TaskForce,
          text: 'tutorial.taskForce',
          ...rectFromBoundingRect(rect),
        },
      })
    }
    if (missionTutorialTarget.current) {
      const rect = missionTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.Mission,
          text: 'tutorial.mission',
          ...rectFromBoundingRect(rect),
        },
      })
    }
    if (challengesTutorialTarget.current) {
      const rect = challengesTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.Challenges,
          text: 'tutorial.challenges',
          ...rectFromBoundingRect(rect),
        },
      })
    }
    if (badgesTutorialTarget.current) {
      const rect = badgesTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.Badges,
          text: 'tutorial.badges',
          ...rectFromBoundingRect(rect),
        },
      })
    }
    if (xpTutorialTarget.current) {
      const rect = xpTutorialTarget.current.getBoundingClientRect()

      updateTutorial({
        type: 'set',
        value: {
          step: TutorialSteps.XP,
          text: 'tutorial.xp',
          ...rectFromBoundingRect(rect),
        },
      })
    }
  }, [data, isLoading])
  useEffect(
    () => () => {
      updateTutorial({ type: 'remove', step: TutorialSteps.Dashboard })
      updateTutorial({ type: 'remove', step: TutorialSteps.TaskForce })
      updateTutorial({ type: 'remove', step: TutorialSteps.Mission })
      updateTutorial({ type: 'remove', step: TutorialSteps.Challenges })
      updateTutorial({ type: 'remove', step: TutorialSteps.Badges })
      updateTutorial({ type: 'remove', step: TutorialSteps.XP })
    },
    [updateTutorial]
  )

  return (
    <div className={styles.outer}>
      <div className={classNames(styles.container, styles.pageContainer)}>
        <HeaderEditableAvatar />

        <div className={classNames(styles.row, styles.noMargin)}>
          <NavigationBar />
        </div>

        {isLoading && (
          <div className={styles.row}>
            <LoadingIndicator />
          </div>
        )}

        {data && (
          <>
            <div className={styles.row} ref={dashboardTutorialTarget}>
              <div className={styles.summary}>
                <h1>{t('menu.dashboard')}</h1>

                <ul className={styles.stats}>
                  <XpStats stats={data} ref={xpTutorialTarget} />
                  <MissionStats stats={data} />
                  <AssignmentStats stats={data} />
                  <ChallengeStats stats={data} />
                </ul>
              </div>
            </div>

            <div className={styles.row}>
              <Mission
                className={styles.section}
                mission={data}
                ref={missionTutorialTarget}
              />
            </div>

            <div className={styles.row}>
              <ChallengesOverview
                className={styles.section}
                ref={challengesTutorialTarget}
                stats={data}
              />
              {data.me && (
                <BadgeStats
                  className={styles.section}
                  ref={badgesTutorialTarget}
                  stats={data.me}
                />
              )}
            </div>

            <div className={styles.row}>
              <TaskforcesOverview
                className={styles.section}
                ref={taskforceTutorialTarget}
                taskforces={data.taskforces}
                user={data?.me?.taskforce ?? null}
              />

              {data.me?.team && (
                <TeamOverview className={styles.section} team={data.me.team} />
              )}
            </div>
          </>
        )}

        {error && <GraphQlError error={error} retry={retry} />}
      </div>
    </div>
  )
}
