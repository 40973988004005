/* eslint-disable relay/unused-fields */
import * as Sentry from '@sentry/browser'
import { observer } from 'mobx-react'
import {
  createContext,
  ReactElement,
  StrictMode,
  useContext,
  useEffect,
} from 'react'
import { RelayEnvironmentProvider } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { enablePushNotifications, init } from '../js/jsBridge'

import styles from './App.scss'
import { ErrorBoundary } from './components/common/ErrorBoundary'
import { Routing } from './components/common/Routing'
import { environment } from './environment'
import {
  AppEnvironmentSettingsQuery,
  AppEnvironmentSettingsQueryResponse,
} from './generated/AppEnvironmentSettingsQuery.graphql'
import { AppUserQuery } from './generated/AppUserQuery.graphql'
import { StoreProvider, useStores } from './stores'

export const EnvironmentContext = createContext<
  AppEnvironmentSettingsQueryResponse['settings']
>({
  editableTranslations: [],
  language: 'en-US',
  loginWithCompanyUserId: false,
  supportUrl: 'https://www.powerapp.nl/faq',
  privacyPolicyUrl: 'https://www.powerapp.nl/privacy-policy',
  singleSignOn: {
    idpOnly: false,
    idps: [],
  },
  xp: {
    exponentBase: 1.3,
    xpForFirstLevel: 100,
  },
})

export function useEnvironment(): AppEnvironmentSettingsQueryResponse['settings'] {
  return useContext(EnvironmentContext)
}

export const App = observer(function App(): ReactElement {
  useEffect(init, [init])

  return (
    <div className={styles.app}>
      <StrictMode>
        <ErrorBoundary>
          <RelayEnvironmentProvider environment={environment}>
            <StoreProvider>
              {/*<ServiceWorker />*/}

              <InnerApp />
            </StoreProvider>
          </RelayEnvironmentProvider>
        </ErrorBoundary>
      </StrictMode>
    </div>
  )
})

function InnerApp(): ReactElement {
  const { authStore, commonStore } = useStores()

  const environmentSettings = useQuery<AppEnvironmentSettingsQuery>(
    graphql`
      query AppEnvironmentSettingsQuery {
        settings {
          editableTranslations {
            language
            translations {
              key
              value
            }
          }
          language
          loginWithCompanyUserId
          singleSignOn {
            idpOnly
            idps {
              description {
                language
                value
              }
              displayName {
                language
                value
              }
              isHybrid
              loginUrl
              name
              setPasswordInstruction {
                language
                value
              }
            }
          }
          supportUrl
          privacyPolicyUrl
          xp {
            exponentBase
            xpForFirstLevel
          }
        }
      }
    `,
    {}
  )
  const user = useQuery<AppUserQuery>(graphql`
    query AppUserQuery {
      me {
        __typename
        id
        email
        language
      }
    }
  `)

  useEffect(() => {
    if (!user.data) {
      return
    }

    if (user.data.me) {
      enablePushNotifications()

      commonStore.setLanguage(user.data.me.language)

      Sentry.setUser({
        email: user.data.me.email,
        id: user.data.me.id,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [authStore, commonStore, user.data])

  return environmentSettings.data ? (
    <EnvironmentContext.Provider value={environmentSettings.data.settings}>
      <Routing />
    </EnvironmentContext.Provider>
  ) : (
    <div className='loading-placeholder'>
      <img
        alt='DFC Game logo'
        src={process.env.APP_URL + '/style/favicon-310x310.png'}
      />

      <div className='loading-indicator'>
        <div className='loader' />
      </div>
    </div>
  )
}
