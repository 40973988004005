/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserStage = "NEW" | "PROFILE_CREATED" | "READY_FOR_TEAM" | "TEAM_ACKNOWLEDGED" | "TEAM_ASSIGNED";
export type OnboardingPopupCompleteMutationVariables = {
    firstName: string;
    lastName: string;
    function: string;
    language: string;
};
export type OnboardingPopupCompleteMutationResponse = {
    readonly completeOnboarding: {
        readonly firstMission: {
            readonly " $fragmentRefs": FragmentRefs<"TaskforceJoinedPopup_firstMission">;
        } | null;
        readonly taskforce: {
            readonly " $fragmentRefs": FragmentRefs<"TaskforceJoinedPopup_taskforce">;
        };
        readonly user: {
            readonly stage: UserStage;
        };
    };
};
export type OnboardingPopupCompleteMutation = {
    readonly response: OnboardingPopupCompleteMutationResponse;
    readonly variables: OnboardingPopupCompleteMutationVariables;
};



/*
mutation OnboardingPopupCompleteMutation(
  $firstName: String!
  $lastName: String!
  $function: String!
  $language: String!
) {
  completeOnboarding(firstName: $firstName, lastName: $lastName, function: $function) {
    firstMission {
      ...TaskforceJoinedPopup_firstMission_1S2oaG
      id
    }
    taskforce {
      ...TaskforceJoinedPopup_taskforce
      id
    }
    user {
      stage
      id
    }
  }
}

fragment NewMissionPopup_mission_1S2oaG on Mission {
  assignments(filter: ALL, first: 1) {
    pageInfo {
      total
    }
  }
  banner {
    url
    id
  }
  challenges(filter: ALL, first: 1) {
    pageInfo {
      total
    }
  }
  description(language: $language)
  id
  title(language: $language)
}

fragment TaskforceJoinedPopup_firstMission_1S2oaG on Mission {
  id
  ...NewMissionPopup_mission_1S2oaG
}

fragment TaskforceJoinedPopup_taskforce on Taskforce {
  taskforceType
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "firstName"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "function"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "language"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "lastName"
    } as any, v4 = [
        {
            "kind": "Variable",
            "name": "firstName",
            "variableName": "firstName"
        } as any,
        {
            "kind": "Variable",
            "name": "function",
            "variableName": "function"
        } as any,
        {
            "kind": "Variable",
            "name": "lastName",
            "variableName": "lastName"
        } as any
    ], v5 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stage",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v8 = [
        {
            "kind": "Literal",
            "name": "filter",
            "value": "ALL"
        } as any,
        {
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any
    ], v9 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "OnboardingPopupCompleteMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "CompleteOnboardingResponse",
                    "kind": "LinkedField",
                    "name": "completeOnboarding",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Mission",
                            "kind": "LinkedField",
                            "name": "firstMission",
                            "plural": false,
                            "selections": [
                                {
                                    "args": (v5 /*: any*/),
                                    "kind": "FragmentSpread",
                                    "name": "TaskforceJoinedPopup_firstMission"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Taskforce",
                            "kind": "LinkedField",
                            "name": "taskforce",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "TaskforceJoinedPopup_taskforce"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "OnboardingPopupCompleteMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "CompleteOnboardingResponse",
                    "kind": "LinkedField",
                    "name": "completeOnboarding",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Mission",
                            "kind": "LinkedField",
                            "name": "firstMission",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v8 /*: any*/),
                                    "concreteType": "AssignmentConnection",
                                    "kind": "LinkedField",
                                    "name": "assignments",
                                    "plural": false,
                                    "selections": (v9 /*: any*/),
                                    "storageKey": "assignments(filter:\"ALL\",first:1)"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UploadedFile",
                                    "kind": "LinkedField",
                                    "name": "banner",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v8 /*: any*/),
                                    "concreteType": "ChallengeConnection",
                                    "kind": "LinkedField",
                                    "name": "challenges",
                                    "plural": false,
                                    "selections": (v9 /*: any*/),
                                    "storageKey": "challenges(filter:\"ALL\",first:1)"
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Taskforce",
                            "kind": "LinkedField",
                            "name": "taskforce",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "taskforceType",
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/),
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "042c8c8c70ff82fb17b249f074091186",
            "id": null,
            "metadata": {},
            "name": "OnboardingPopupCompleteMutation",
            "operationKind": "mutation",
            "text": "mutation OnboardingPopupCompleteMutation(\n  $firstName: String!\n  $lastName: String!\n  $function: String!\n  $language: String!\n) {\n  completeOnboarding(firstName: $firstName, lastName: $lastName, function: $function) {\n    firstMission {\n      ...TaskforceJoinedPopup_firstMission_1S2oaG\n      id\n    }\n    taskforce {\n      ...TaskforceJoinedPopup_taskforce\n      id\n    }\n    user {\n      stage\n      id\n    }\n  }\n}\n\nfragment NewMissionPopup_mission_1S2oaG on Mission {\n  assignments(filter: ALL, first: 1) {\n    pageInfo {\n      total\n    }\n  }\n  banner {\n    url\n    id\n  }\n  challenges(filter: ALL, first: 1) {\n    pageInfo {\n      total\n    }\n  }\n  description(language: $language)\n  id\n  title(language: $language)\n}\n\nfragment TaskforceJoinedPopup_firstMission_1S2oaG on Mission {\n  id\n  ...NewMissionPopup_mission_1S2oaG\n}\n\nfragment TaskforceJoinedPopup_taskforce on Taskforce {\n  taskforceType\n}\n"
        }
    } as any;
})();
(node as any).hash = 'b23941a86a910cea921c0a81fa6315fb';
export default node;
