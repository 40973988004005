/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionInteractionAnswerOption_answerOption = {
    readonly text: string;
    readonly " $refType": "QuestionInteractionAnswerOption_answerOption";
};
export type QuestionInteractionAnswerOption_answerOption$data = QuestionInteractionAnswerOption_answerOption;
export type QuestionInteractionAnswerOption_answerOption$key = {
    readonly " $data"?: QuestionInteractionAnswerOption_answerOption$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"QuestionInteractionAnswerOption_answerOption">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionInteractionAnswerOption_answerOption",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ],
    "type": "AnswerOption",
    "abstractKey": null
} as any;
(node as any).hash = '505e333ebe2e0fee7c36b33d6d98d8d7';
export default node;
