import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, SVGProps, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/classNames'

import { Tutorial, TutorialSteps } from '../../utils/tutorial'
import { BackwardIcon } from './BackwardIcon'
import { ForwardIcon } from './ForwardIcon'
import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'

import styles from './TutorialOverlay.scss'

interface TutorialOverlayProps {
  tutorial: Tutorial
}

export function TutorialOverlay(props: TutorialOverlayProps): ReactElement {
  const { tutorial } = props
  const [currentStep, setCurrentStep] = useState(0 as TutorialSteps)
  const [done, setDone] = useState(false)
  const { t } = useTranslation()

  const updateStep = useCallback(
    (newStep: TutorialSteps): void => {
      if (newStep >= TutorialSteps.__Max) {
        setDone(true)
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        return
      }

      setCurrentStep(newStep)
      const next = tutorial.get(newStep)
      if (next) {
        window.scrollTo({
          left: next.x,
          top: next.y - 100,
          behavior: 'smooth',
        })
      }
    },
    [tutorial]
  )
  const onPrevious = useCallback(() => {
    let newStep = 0 as TutorialSteps
    for (let i = currentStep - 1; i >= 0; --i) {
      if (tutorial.has(i)) {
        newStep = i
        break
      }
    }

    updateStep(newStep)
  }, [currentStep, tutorial, updateStep])
  const onNext = useCallback(() => {
    let newStep = TutorialSteps.__Max
    for (let i = currentStep + 1; i < TutorialSteps.__Max; ++i) {
      if (tutorial.has(i)) {
        newStep = i
        break
      }
    }

    updateStep(newStep)
  }, [currentStep, tutorial, updateStep])

  const hasPrevious = useMemo(() => {
    for (let i = currentStep - 1; i >= 0; --i) {
      if (tutorial.has(i)) {
        return true
      }
    }

    return false
  }, [currentStep, tutorial])
  const hasNext = useMemo(() => {
    for (let i = currentStep + 1; i < TutorialSteps.__Max; ++i) {
      if (tutorial.has(i)) {
        return true
      }
    }

    return false
  }, [currentStep, tutorial])

  const current = props.tutorial.get(currentStep)
  if (!current) {
    return <></>
  }

  const rectProps: SVGProps<SVGRectElement> = {
    x: current.x,
    y: current.y,
    width: current.width,
    height: current.height,
    rx: '10',
    ry: '10',
  }

  return (
    <>
      <svg
        className={classNames(styles.overlay, {
          [styles.done]: done,
        })}
        width='100%'
        height='100%'
      >
        <defs>
          <mask id='mask' x='0' y='0' height='100%' width='100%'>
            <rect x='0' y='0' height='100%' width='100%' fill='#fff' />
            <rect className={styles.maskRect} fill='#000' {...rectProps} />
          </mask>
        </defs>

        <rect
          height='100%'
          width='100%'
          fill='rgba(0, 0, 0, 0.75)'
          mask='url(#mask)'
          fillOpacity='1'
        />
        <rect
          className={classNames(styles.maskRect, styles.border)}
          {...rectProps}
        />
      </svg>

      <div className={classNames(styles.popup, { [styles.done]: done })}>
        <div className={styles.container}>
          <div className={styles.text}>{t(current.text)}</div>

          {hasPrevious && (
            <SecondaryButton
              className={styles.button}
              icon
              onClick={onPrevious}
            >
              <BackwardIcon />
            </SecondaryButton>
          )}
          <PrimaryButton className={styles.button} icon onClick={onNext}>
            {hasNext ? <ForwardIcon /> : <FontAwesomeIcon icon={faTimes} />}
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}
