import { gsap } from 'gsap'
import { normalize } from 'gsap/gsap-core'
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useMutation, useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import bannerUrl from '../../../../../images/task-completed-banner.jpg'
import { useEnvironment } from '../../App'
import { TaskCompletedPopupClaimXpMutation } from '../../generated/TaskCompletedPopupClaimXpMutation.graphql'
import { TaskCompletedPopupUserQuery } from '../../generated/TaskCompletedPopupUserQuery.graphql'
import {
  PopupProps,
  TaskCompletedPopup as TaskCompletedPopupProps,
} from '../../utils/hooks/usePopups'
import { totalXpForLevel } from '../../utils/levels'
import { avatarUrl } from '../../utils/url'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'
import { ProgressBar } from '../common/ProgressBar'

import styles from './TaskCompletedPopup.scss'

export function TaskCompletedPopup(
  props: PopupProps<TaskCompletedPopupProps>
): ReactElement {
  const { t, i18n } = useTranslation()
  const numberFormatter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        signDisplay: 'always',
      }),
    [i18n.language]
  )
  const { xp } = useEnvironment()

  const progressBarElement = useRef<HTMLDivElement>(null)
  const levelElement = useRef<HTMLDivElement>(null)
  const xpElement = useRef<HTMLDivElement>(null)
  const [collecting, setCollecting] = useState(false)

  const user = useQuery<TaskCompletedPopupUserQuery>(
    graphql`
      query TaskCompletedPopupUserQuery {
        me {
          avatar
          xp
          level
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    }
  )

  const task = useFragment(
    graphql`
      fragment TaskCompletedPopup_task on Task
      @argumentDefinitions(language: { type: "String!" }) {
        feedback(language: $language)
        id
        skillXp
      }
    `,
    props.task
  )

  const [skillXp, setSkillXp] = useState(task.skillXp)
  const [claimXp, claimXpResult] =
    useMutation<TaskCompletedPopupClaimXpMutation>(
      graphql`
        mutation TaskCompletedPopupClaimXpMutation($taskId: ID!) {
          claimXp(taskId: $taskId) {
            task {
              completion {
                xpClaimedAt
              }
            }
            user {
              level
              xp
            }
          }
        }
      `,
      { variables: { taskId: task.id } }
    )
  const onCollect = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      if (!user.data?.me) {
        return
      }

      setCollecting(true)

      // Visually count down the XP to 0.
      const xpAnimation = { value: skillXp }
      const animationDuration = 1.5
      gsap.to(xpAnimation, {
        duration: animationDuration,
        ease: 'power1.inOut',
        value: 0,
        onUpdate(): void {
          if (xpElement.current) {
            const rounded = Math.round(xpAnimation.value)
            xpElement.current.innerText = t('xp.count', {
              count: rounded,
              formatted: numberFormatter.format(rounded),
            })
          }
        },
        onComplete(): void {
          setSkillXp(0)

          claimXp().then(() => {
            props.closeCurrentPopup()
          })
        },
      })

      // Visually increase the progress bar to each level hit.
      if (progressBarElement.current) {
        const timeline = gsap.timeline()
        let first = true
        let fromWidth =
          normalize(
            totalXpForLevel(
              user.data.me.level,
              xp.exponentBase,
              xp.xpForFirstLevel
            ),
            totalXpForLevel(
              user.data.me.level + 1,
              xp.exponentBase,
              xp.xpForFirstLevel
            ),
            user.data.me.xp
          ) * 100
        let currentLevel = user.data.me.level
        let currentXp = user.data.me.xp
        let xpLeftToAnimate = skillXp

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // How much XP is needed for the next level?
          const nextLevelXp = totalXpForLevel(
            currentLevel + 1,
            xp.exponentBase,
            xp.xpForFirstLevel
          )
          if (currentXp + xpLeftToAnimate > nextLevelXp) {
            const nextLevel = currentLevel + 1
            // Animate to 100%
            timeline.fromTo(
              progressBarElement.current,
              { width: `${Math.round(fromWidth)}%` },
              {
                duration:
                  ((nextLevelXp - currentXp) / skillXp) * animationDuration,
                ease: first ? 'power1.in' : 'none',
                width: '100%',
                onComplete() {
                  if (levelElement.current) {
                    levelElement.current.innerText = t('level.num', {
                      count: nextLevel,
                    })

                    gsap.timeline().to(levelElement.current, {
                      duration: 0.15,
                      repeat: 1,
                      scale: 2,
                      yoyo: true,
                    })
                  }
                },
              }
            )

            fromWidth = 0
            currentLevel += 1
            xpLeftToAnimate -= nextLevelXp - currentXp
            currentXp = nextLevelXp

            first = false
            continue
          }

          // Otherwise, this should be the last point in the timeline
          timeline.fromTo(
            progressBarElement.current,
            { width: `${Math.round(fromWidth)}%` },
            {
              // delay: durationUsedSoFar,
              duration: (xpLeftToAnimate / skillXp) * animationDuration,
              ease: first ? 'power1.inOut' : 'power1.out',
              width: `${Math.round(
                normalize(
                  totalXpForLevel(
                    currentLevel,
                    xp.exponentBase,
                    xp.xpForFirstLevel
                  ),
                  totalXpForLevel(
                    currentLevel + 1,
                    xp.exponentBase,
                    xp.xpForFirstLevel
                  ),
                  currentXp + xpLeftToAnimate
                ) * 100
              )}%`,
            }
          )

          break
        }
      }
    },
    [
      user.data?.me,
      skillXp,
      t,
      numberFormatter,
      claimXp,
      props,
      xp.exponentBase,
      xp.xpForFirstLevel,
    ]
  )

  return (
    <>
      <img className={styles.banner} src={bannerUrl} aria-hidden />

      <div className={styles.content}>
        <div className={styles.left}>
          <img
            className={styles.avatar}
            src={
              user.data?.me?.avatar ? avatarUrl(user.data.me.avatar) : undefined
            }
          />

          <div className={styles.level} ref={levelElement}>
            {user.data?.me && t('level.num', { count: user.data.me.level })}
          </div>
          {user.data?.me && (
            <ProgressBar
              className={styles.progress}
              progressBarClassName={styles.progressBar}
              hideText
              ref={progressBarElement}
              percentage={
                normalize(
                  totalXpForLevel(
                    user.data.me.level,
                    xp.exponentBase,
                    xp.xpForFirstLevel
                  ),
                  totalXpForLevel(
                    user.data.me.level + 1,
                    xp.exponentBase,
                    xp.xpForFirstLevel
                  ),
                  user.data.me.xp
                ) * 100
              }
            />
          )}

          <PrimaryButton
            className={styles.button}
            disabled={collecting || !user.data?.me}
            onClick={onCollect}
          >
            {t('task.completed.collect')}
          </PrimaryButton>
        </div>

        <div className={styles.right}>
          <div className={styles.xpGain} ref={xpElement}>
            {t('xp.count', {
              count: skillXp,
              formatted: numberFormatter.format(skillXp),
            })}
          </div>

          <h2>{t('task.completed.wellDone')}</h2>
          <div
            className={styles.feedback}
            dangerouslySetInnerHTML={{ __html: task.feedback }}
          />

          {claimXpResult.error && (
            <GraphQlError error={claimXpResult.error} retry={claimXp} />
          )}
        </div>
      </div>
    </>
  )
}
