import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DirectionAwareIconProps {
  ltrIcon: IconProp
  rtlIcon: IconProp
}

export function DirectionAwareIcon(
  props: Omit<FontAwesomeIconProps, 'icon'> & DirectionAwareIconProps
): ReactElement {
  const { i18n } = useTranslation()

  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr')
  useEffect(() => {
    setDirection(i18n.dir(i18n.language))
  }, [i18n])

  const { ltrIcon, rtlIcon, ...rest } = props

  return direction === 'rtl' ? (
    <FontAwesomeIcon icon={rtlIcon} {...rest} />
  ) : (
    <FontAwesomeIcon icon={ltrIcon} {...rest} />
  )
}
