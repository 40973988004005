import { observer } from 'mobx-react'
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useFragment } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { useEnvironment } from '../../App'
import { RoutingGuestRoute_user$key } from '../../generated/RoutingGuestRoute_user.graphql'
import { RoutingPrivateRoute_user$key } from '../../generated/RoutingPrivateRoute_user.graphql'
import { RoutingUserQuery } from '../../generated/RoutingUserQuery.graphql'
import { WebService } from '../../services/WebService'

import { useStores } from '../../stores'
import { onEnterPopup, onExitPopup } from '../../utils/animations'
import {
  canShowPopup,
  Popup as PopupType,
  PopupControllerProps,
  usePopups,
} from '../../utils/hooks/usePopups'
import { TutorialContext, useTutorial } from '../../utils/tutorial'
import { AuthenticationCmsRedirector } from '../pages/auth/AuthenticationCmsRedirector'
import { AuthenticationForgotPasswordPage } from '../pages/auth/AuthenticationForgotPasswordPage'
import { AuthenticationLoggedOutPage } from '../pages/auth/AuthenticationLoggedOutPage'
import { AuthenticationLogoutPage } from '../pages/auth/AuthenticationLogoutPage'
import { AuthenticationPasswordExpiredPage } from '../pages/auth/AuthenticationPasswordExpiredPage'

import { SetPassword, SetPasswordAction } from '../pages/auth/SetPassword'
import { TestAuthenticationScreen } from '../pages/auth/TestAuthenticationScreen'
import { BadgesScreen } from '../pages/BadgesScreen'
import { DashboardPage } from '../pages/DashboardPage'
import { ChallengesScreen } from '../pages/missions/ChallengesScreen'
import { CurrentMissionScreen } from '../pages/missions/CurrentMissionScreen'
import { BottomBar } from './BottomBar'

import { ErrorBoundary } from './ErrorBoundary'
import { GraphQlError } from './GraphQlError'
import { Popup } from './Popup'
import styles from './Routing.scss'
import { TopBar } from './TopBar'
import { TutorialOverlay } from './TutorialOverlay'

type GuestRouteProps<Path extends string> = Omit<RouteProps<Path>, 'render'> &
  Required<Pick<RouteProps<Path>, 'render'>> & {
    forceLogout?: boolean
    user: RoutingGuestRoute_user$key | null | undefined
  }

type PrivateRouteProps<Path extends string> = Omit<RouteProps<Path>, 'render'> &
  Required<Pick<RouteProps<Path>, 'render'>> & {
    user: RoutingPrivateRoute_user$key | null | undefined
  }

// The reason this code block is reused with a minor tweak is simple
// Like the PrivateRoute but the user check is reversed to continue with logging in
// The purpose is to build the function component that redirect guests to another page than users.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GuestRoute = observer(function GuestRoute<Path extends string = string>(
  routeProps: GuestRouteProps<Path>
): ReactElement {
  const { render, forceLogout, ...rest } = routeProps
  const { authStore, commonStore } = useStores()

  const user = useFragment(
    graphql`
      fragment RoutingGuestRoute_user on AuthenticatedUser {
        loginType
      }
    `,
    routeProps.user || null
  )

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !forceLogout) {
          let url = '/'
          if (commonStore.postAuthRedirect) {
            url = commonStore.postAuthRedirect

            commonStore.setPostAuthRedirect(undefined)
          }

          return <Redirect to={url} />
        }

        if (user && forceLogout) {
          authStore.logout(user.loginType)
        }

        return render(props)
      }}
    />
  )
})

const PrivateRoute = observer(function PrivateRoute<
  Path extends string = string
>(routeProps: PrivateRouteProps<Path>): ReactElement {
  const { render, ...rest } = routeProps
  const { commonStore } = useStores()

  const user = useFragment(
    graphql`
      fragment RoutingPrivateRoute_user on AuthenticatedUser {
        __typename
      }
    `,
    routeProps.user || null
  )

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          const target = commonStore.postAuthRedirect
          if (target) {
            commonStore.setPostAuthRedirect(undefined)
            return <Redirect to={target} />
          }

          return render(props)
        }

        commonStore.setPostAuthRedirect(
          props.location.pathname + props.location.search
        )

        return <Redirect to='/login' />
      }}
    />
  )
})

export const PopupContext = createContext<PopupControllerProps<PopupType>>(
  undefined as never
)

export const Routing = observer(function Routing(): ReactElement {
  const { i18n } = useTranslation()
  const { singleSignOn } = useEnvironment()
  const { commonStore } = useStores()
  const [privacyPolicyPopupShown, setPrivacyPolicyPopupShown] = useState(false)

  const popupController = usePopups()
  const tutorial = useTutorial()

  useEffect(() => {
    WebService.getInstance().popupController = popupController

    return () => {
      WebService.getInstance().popupController = undefined
    }
  }, [popupController])

  const [, fixBug] = useState(0)

  const user = useQuery<RoutingUserQuery>(
    graphql`
      query RoutingUserQuery($language: String!) {
        currentMission: missions(filter: CURRENT, first: 1) {
          edges {
            node {
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...MissionCompletedPopup_currentMission
            }
          }
        }
        completedMissions: missions(
          filter: COMPLETION_UNACKNOWLEDGED
          first: 10
        ) {
          __id
          edges {
            node {
              id
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...MissionCompletedPopup_completedMission
                @arguments(language: $language)
            }
          }
        }
        me {
          firstLogin
          idp
          loginType
          stage
          team {
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...TeamJoinedPopup_team
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...OnboardingPopup_user
          ...RoutingGuestRoute_user
          ...RoutingPrivateRoute_user
        }
        newMissions: missions(filter: START_UNACKNOWLEDGED, first: 10) {
          __id
          edges {
            node {
              id
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...NewMissionPopup_mission @arguments(language: $language)
            }
          }
        }
        pendingBadges {
          id
          pivot {
            lastClaimedStars
            stars
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...BadgeEarnedPopup_badge @arguments(language: $language)
        }
        pendingXpClaims {
          id
          completion {
            xpClaimedAt
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...TaskCompletedPopup_task @arguments(language: $language)
        }
      }
    `,
    { language: i18n.language },
    {
      onComplete() {
        // This fixes a bug where sometimes the Routing component did not
        // re-render when the query completed. Forcing a state change causes a
        // render to occur.
        fixBug((p) => p + 1)
      },
    }
  )

  // Trigger onboarding popup
  useEffect(() => {
    if (
      user.data?.me &&
      user.data.me.stage === 'NEW' &&
      !popupController.hasPopup('onboarding')
    ) {
      popupController.openPopup({ type: 'onboarding', user: user.data.me })
    }
  }, [popupController, user])

  // Trigger team joined popup
  useEffect(() => {
    if (
      user.data?.me?.team &&
      user.data.me.stage !== 'TEAM_ACKNOWLEDGED' &&
      !popupController.hasPopup('team-joined')
    ) {
      popupController.openPopup({
        team: user.data.me.team,
        type: 'team-joined',
      })
    }
  }, [popupController, user])

  // Trigger privacy policy pop-up if not shown previously.
  useEffect(() => {
    if (
      !privacyPolicyPopupShown &&
      user.data?.me &&
      user.data.me.firstLogin &&
      user.data.me.loginType === 'SAML' &&
      !singleSignOn.idps.find((idp) => idp.name === user.data?.me?.idp)
        ?.isHybrid
    ) {
      popupController.openPopup({
        type: 'privacy-policy',
      })
      setPrivacyPolicyPopupShown(true)
    }
  }, [user, privacyPolicyPopupShown, singleSignOn.idps, popupController])

  // Open task completed pop-ups for pending XP claims.
  useEffect(() => {
    if (user.data?.pendingXpClaims) {
      for (const task of user.data.pendingXpClaims) {
        if (
          !task.completion?.xpClaimedAt &&
          !popupController.hasPopup(
            'task-completed',
            (popup) => popup.popupKey === task.id
          )
        ) {
          popupController.openPopup({
            popupKey: task.id,
            task,
            type: 'task-completed',
          })
        }
      }
    }
  }, [popupController, user.data?.pendingXpClaims])

  // Open badge popups for unclaimed badges
  useEffect(() => {
    if (user.data?.pendingBadges) {
      for (const badge of user.data.pendingBadges) {
        if (
          badge.pivot &&
          badge.pivot.lastClaimedStars < badge.pivot.stars &&
          !popupController.hasPopup(
            'badge-earned',
            (popup) => popup.popupKey === badge.id
          )
        ) {
          popupController.openPopup({
            popupKey: badge.id,
            badge,
            type: 'badge-earned',
          })
        }
      }
    }
  }, [popupController, user.data?.pendingBadges])

  // Open popups for unacknowledged new/completed missions
  useEffect(() => {
    if (
      user.data?.completedMissions &&
      (user.data?.currentMission?.edges.length ?? 0) > 0 &&
      user.data?.currentMission?.edges[0].node
    ) {
      const currentMission = user.data?.currentMission?.edges[0].node
      for (const mission of user.data.completedMissions.edges) {
        if (
          !popupController.hasPopup(
            'mission-completed',
            (popup) => popup.popupKey === mission.node.id
          )
        ) {
          popupController.openPopup({
            connection: user.data.completedMissions.__id,
            currentMission,
            completedMission: mission.node,
            popupKey: mission.node.id,
            type: 'mission-completed',
          })
        }
      }
    }
    if (user.data?.newMissions) {
      for (const mission of user.data.newMissions.edges) {
        if (
          !popupController.hasPopup(
            'new-mission',
            (popup) => popup.popupKey === mission.node.id
          )
        ) {
          popupController.openPopup({
            connection: user.data.newMissions.__id,
            mission: mission.node,
            popupKey: mission.node.id,
            type: 'new-mission',
          })
        }
      }
    }
  }, [popupController, user.data?.completedMissions, user.data?.currentMission?.edges, user.data?.newMissions])

  const popup = popupController.popups.find((p) =>
    canShowPopup(p, user.data?.me)
  )

  useEffect(() => {
    // Set commonStore to initialized as soon as user data is loaded.
    if (user.data && !commonStore.appInitialized) {
      commonStore.setAppInitialized(true)
    }
  }, [commonStore, commonStore.appInitialized, user.data])

  const popupRef = useRef<HTMLDivElement>(null)

  const upToDatePopup = popupController.upToDatePopup
  const transitioningBetweenPopups = useRef(false)
  const onEnter = useCallback((): void => {
    if (popupRef.current) {
      onEnterPopup(popupRef.current, !transitioningBetweenPopups.current)
    }
  }, [])
  const onExit = useCallback((): void => {
    if (popupRef.current) {
      transitioningBetweenPopups.current = !!upToDatePopup.current
      onExitPopup(popupRef.current, !transitioningBetweenPopups.current)
    }
  }, [upToDatePopup])

  // Prevent flash of guest route when logged in
  if (!user.data) {
    return user.error ? (
      <GraphQlError error={user.error} retry={user.retry} />
    ) : (
      <></>
    )
  }

  return (
    <ErrorBoundary>
      <PopupContext.Provider value={popupController}>
        <TutorialContext.Provider value={tutorial}>
          <SwitchTransition>
            {popup ? (
              <Transition
                appear={true}
                mountOnEnter
                nodeRef={popupRef}
                onEnter={onEnter}
                onExit={onExit}
                timeout={!transitioningBetweenPopups.current ? 1000 : 500}
                unmountOnExit
                key={popup.popupKey}
              >
                <Popup
                  {...popupController}
                  {...popup}
                  ref={popupRef}
                  key={popup.popupKey}
                />
              </Transition>
            ) : (
              <Transition appear={true} timeout={0}>
                <></>
              </Transition>
            )}
          </SwitchTransition>

          {tutorial[2] && <TutorialOverlay tutorial={tutorial[0]} />}

          {!user.data.me || user.data.me.stage !== 'NEW' ? (
            <Router basename='/app'>
              <div className={styles.router}>
                <TopBar />

                <div className={styles.route}>
                  <Switch>
                    <GuestRoute
                      exact
                      path='/login'
                      render={() => <TestAuthenticationScreen />}
                      user={user.data.me}
                    />

                    <PrivateRoute
                      exact
                      path='/login/cms'
                      render={(props) => (
                        <AuthenticationCmsRedirector {...props} />
                      )}
                      user={user.data.me}
                    />

                    <Route
                      exact
                      path='/logout'
                      render={() => <AuthenticationLogoutPage />}
                    />

                    <Route
                      exact
                      path='/logged-out'
                      render={() => <AuthenticationLoggedOutPage />}
                    />

                    <GuestRoute
                      exact
                      path='/password/remind'
                      render={(props) => (
                        <AuthenticationForgotPasswordPage {...props} />
                      )}
                      user={user.data.me}
                    />

                    <GuestRoute
                      exact
                      path='/password/expired/:email/:days/:token'
                      render={(props) => (
                        <AuthenticationPasswordExpiredPage {...props} />
                      )}
                      user={user.data.me}
                    />

                    <GuestRoute
                      exact
                      path='/password/accept-invitation/:token/:email?/:language?'
                      forceLogout
                      render={(props) => (
                        <SetPassword
                          action={SetPasswordAction.Activation}
                          {...props}
                        />
                      )}
                      user={user.data.me}
                    />

                    <GuestRoute
                      exact
                      path='/password/reset/:token/:email?/:language?'
                      forceLogout
                      render={(props) => (
                        <SetPassword
                          action={SetPasswordAction.PasswordReset}
                          {...props}
                        />
                      )}
                      user={user.data.me}
                    />

                    <PrivateRoute
                      exact
                      path='/'
                      render={() => <DashboardPage />}
                      user={user.data.me}
                    />

                    <PrivateRoute
                      exact
                      path='/missions/current'
                      render={() => <CurrentMissionScreen />}
                      user={user.data.me}
                    />

                    <PrivateRoute
                      exact
                      path='/challenges'
                      render={() => <ChallengesScreen />}
                      user={user.data.me}
                    />

                    <PrivateRoute
                      exact
                      path='/badges'
                      render={() => <BadgesScreen />}
                      user={user.data.me}
                    />
                  </Switch>
                </div>

                <BottomBar />
              </div>
            </Router>
          ) : (
            <div className={styles.router}>
              <TopBar noLink />

              <BottomBar />
            </div>
          )}
        </TutorialContext.Provider>
      </PopupContext.Provider>
    </ErrorBoundary>
  )
})
