import { Dispatch, useReducer } from 'react'
import { DataID, useRelayEnvironment } from 'react-relay'
import { commitLocalUpdate } from 'relay-hooks/lib'
import { Primitive } from 'relay-runtime/lib/store/RelayStoreTypes'

export function useRelayMirroredState<T extends Primitive | Primitive[]>(
  initialValue: T,
  dataId: DataID,
  fieldName: string
): [T, Dispatch<T>] {
  const environment = useRelayEnvironment()

  return useReducer((_: T, newValue: T): T => {
    commitLocalUpdate(environment, (store) => {
      const current = store.get(dataId)
      current?.setValue(newValue, fieldName)
    })

    return newValue
  }, initialValue)
}
