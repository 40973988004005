import { gsap } from 'gsap'

export function onEnterPopup(
  node: HTMLElement,
  shouldFadeInBackdrop: boolean
): void {
  const backdrop = shouldFadeInBackdrop ? getBackdrop(node) : null
  let delay = 0
  if (backdrop && shouldFadeInBackdrop) {
    delay = 0.5
    gsap.fromTo(backdrop, { opacity: 0 }, { opacity: 0.9 })
  }

  gsap.fromTo(
    node,
    {
      opacity: 0,
      transform: 'translateY(-50px)',
    },
    {
      delay,
      duration: 0.5,
      opacity: 1,
      transform: 'none',
    }
  )
}

export function onExitPopup(
  node: HTMLElement,
  shouldFadeOutBackdrop: boolean
): void {
  gsap.fromTo(
    node,
    {
      opacity: 1,
      transform: 'none',
    },
    {
      duration: 0.5,
      opacity: 0,
      transform: 'translateY(50px)',
    }
  )

  const backdrop = shouldFadeOutBackdrop ? getBackdrop(node) : null
  if (backdrop && shouldFadeOutBackdrop) {
    gsap.fromTo(backdrop, { opacity: 0.9 }, { delay: 0.5, opacity: 0 })
  }
}

function getBackdrop(node: HTMLElement): Element | null {
  const dialog = node.parentElement?.parentElement?.parentElement
  const backdrop = dialog?.nextElementSibling

  if (backdrop instanceof HTMLDivElement) {
    return backdrop
  }

  return null
}
