import { observer } from 'mobx-react'
import {
  FormEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { OpenQuestionInteraction_question$key } from '../../../generated/OpenQuestionInteraction_question.graphql'
import { OpenQuestionInteraction_task$key } from '../../../generated/OpenQuestionInteraction_task.graphql'
import { OpenQuestionInteractionSubmitMutation } from '../../../generated/OpenQuestionInteractionSubmitMutation.graphql'
import { useStores } from '../../../stores'
import { PopupProps } from '../../../utils/hooks/usePopups'
import { GraphQlError } from '../../common/GraphQlError'
import { PrimaryButton } from '../../common/PrimaryButton'
import { Feedback } from './Feedback'

import styles from './OpenQuestionInteraction.scss'
import { OpenQuestionInteractionField } from './OpenQuestionInteractionField'

interface OpenQuestionInteractionProps {
  question: OpenQuestionInteraction_question$key
  task: OpenQuestionInteraction_task$key
}

export interface UpdateAnswersAction {
  id: string
  value: string
}

export const OpenQuestionInteraction = observer(
  function OpenQuestionInteraction(
    props: PropsWithChildren<PopupProps & OpenQuestionInteractionProps>
  ): ReactElement {
    const { commonStore } = useStores()
    const { t, i18n } = useTranslation()
    const { closeCurrentPopup, replaceCurrentPopup } = props

    const question = useFragment(
      graphql`
        fragment OpenQuestionInteraction_question on OpenQuestionInteraction
        @argumentDefinitions(language: { type: "String!" }) {
          fields {
            id
            ...OpenQuestionInteractionField_field
              @arguments(language: $language)
          }
          previousSubmissionResult {
            fieldResponses {
              field {
                id
              }
              value
            }
            taskSubmission {
              feedbackText(language: $language)
              feedbackUpload {
                ...Feedback_upload
              }
            }
          }
          id
        }
      `,
      props.question
    )
    const task = useFragment(
      graphql`
        fragment OpenQuestionInteraction_task on Task {
          completion {
            completedAt
          }
        }
      `,
      props.task
    )

    const [answers, updateAnswers] = useReducer(
      (previous: Record<string, string>, action: UpdateAnswersAction) => ({
        ...previous,
        [action.id]: action.value,
      }),
      question.fields,
      (fields) =>
        Object.fromEntries(
          fields.map((field) => [
            field.id,
            question.previousSubmissionResult?.fieldResponses?.find(
              (fr) => fr.field.id === field.id
            )?.value ?? '',
          ])
        )
    )

    const [submit, submitResult] =
      useMutation<OpenQuestionInteractionSubmitMutation>(graphql`
        mutation OpenQuestionInteractionSubmitMutation(
          $id: ID!
          $answers: [OpenQuestionAnswer!]!
          $challengesConnection: ID!
          $language: String!
        ) {
          submitOpenQuestion(id: $id, answers: $answers) {
            taskSubmission {
              challenge {
                id @deleteEdge(connections: [$challengesConnection])
              }
              feedbackText(language: $language)
              feedbackUpload {
                ...Feedback_upload
              }
              nextAssignment {
                ...Assignment_assignment @arguments(language: $language)
              }
              newChallenge
                @appendNode(
                  connections: [$challengesConnection]
                  edgeTypeName: "ChallengeEdge"
                ) {
                ...Challenge_challenge @arguments(language: $language)
              }
              task {
                completion {
                  completedAt
                }
                ...OpenQuestionInteraction_task
                ...TaskCompletedPopup_task @arguments(language: $language)
              }
            }
          }
        }
      `)

    const onSubmit = useCallback(
      (event: FormEvent): void => {
        event.preventDefault()

        submit({
          variables: {
            id: question.id,
            language: i18n.language,
            answers: Object.entries(answers).map(([id, value]) => ({
              field: id,
              value,
            })),
            challengesConnection: commonStore.challengesConnection,
          },
        })
      },
      [
        answers,
        commonStore.challengesConnection,
        i18n.language,
        question.id,
        submit,
      ]
    )

    const onClose = useCallback(() => {
      if (!submitResult.data?.submitOpenQuestion) {
        return
      }

      if (submitResult.data.submitOpenQuestion.taskSubmission.task) {
        replaceCurrentPopup({
          task: submitResult.data.submitOpenQuestion.taskSubmission.task,
          type: 'task-completed',
        })
      } else {
        closeCurrentPopup()
      }
    }, [
      closeCurrentPopup,
      replaceCurrentPopup,
      submitResult.data?.submitOpenQuestion,
    ])

    const submitResultToShow =
      question.previousSubmissionResult ??
      submitResult.data?.submitOpenQuestion ??
      null

    return (
      <form className={styles.question} onSubmit={onSubmit}>
        <div className={styles.fields}>
          {question.fields.map((field) => (
            <div className={styles.field} key={field.id}>
              <OpenQuestionInteractionField
                disabled={submitResult.loading || submitResultToShow !== null}
                field={field}
                update={updateAnswers}
                value={answers[field.id]}
              />
            </div>
          ))}
        </div>

        {submitResultToShow ? (
          <>
            <Feedback
              html={submitResultToShow.taskSubmission.feedbackText}
              upload={submitResultToShow.taskSubmission.feedbackUpload}
            />

            <div className={styles.buttons}>
              {submitResult.data ? (
                <PrimaryButton onClick={onClose}>
                  {t('interactions.question.close')}
                </PrimaryButton>
              ) : (
                props.children
              )}
            </div>
          </>
        ) : (
          <>
            {submitResult.error && <GraphQlError error={submitResult.error} />}

            <div className={styles.buttons}>
              {!task.completion?.completedAt && (
                <PrimaryButton
                  className={styles.primaryButton}
                  disabled={
                    Object.values(answers).some(
                      (answer) => answer.trim().length === 0
                    ) || submitResult.loading
                  }
                  onClick={onSubmit}
                >
                  {t('interactions.question.submit')}
                </PrimaryButton>
              )}

              {props.children}
            </div>
          </>
        )}
      </form>
    )
  }
)
