/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditProfilePopup_user = {
    readonly avatar: string | null;
    readonly firstName: string;
    readonly function: string;
    readonly id: string;
    readonly lastName: string;
    readonly departments: ReadonlyArray<{
        readonly name: string;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"EditableAvatar_user">;
    readonly " $refType": "EditProfilePopup_user";
};
export type EditProfilePopup_user$data = EditProfilePopup_user;
export type EditProfilePopup_user$key = {
    readonly " $data"?: EditProfilePopup_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditProfilePopup_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfilePopup_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "function",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Department",
            "kind": "LinkedField",
            "name": "departments",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditableAvatar_user"
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '66c4975fe30def9f57dd6e0ccc22eebb';
export default node;
