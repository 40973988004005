import { forwardRef, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { Link } from 'react-router-dom'
import { graphql } from 'relay-runtime'

import placeholder from '../../../../../images/badge-empty-dashboard.svg'
import {
  BadgeStats_stats$data,
  BadgeStats_stats$key,
} from '../../generated/BadgeStats_stats.graphql'
import { classNames } from '../../utils/classNames'
import { ForwardIcon } from '../common/ForwardIcon'
import { TertiaryButton } from '../common/TertiaryButton'

import styles from './BadgeStats.scss'

interface BadgeStatsProps {
  className?: string
  stats: BadgeStats_stats$key
}

export const BadgeStats = forwardRef<HTMLDivElement, BadgeStatsProps>(
  function BadgeStats(props, ref): ReactElement {
    const { i18n, t } = useTranslation()
    const { badges } = useFragment(
      graphql`
        fragment BadgeStats_stats on AuthenticatedUser
        @argumentDefinitions(language: { type: "String!" }) {
          badges {
            image {
              url
            }
            skill {
              name(language: $language)
            }
            pivot {
              updatedAt
            }
          }
        }
      `,
      props.stats
    )

    const latestBadge = useMemo(():
      | BadgeStats_stats$data['badges'][0]
      | undefined => {
      const badgesForSorting = [...badges]
      badgesForSorting.sort(
        (a, b) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          new Date(b.pivot!.updatedAt!).getTime() -
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          new Date(a.pivot!.updatedAt!).getTime()
      )

      return badgesForSorting[0]
    }, [badges])

    let earnedAt: Date | null = null
    let isThisYear = false
    if (latestBadge?.pivot?.updatedAt) {
      earnedAt = new Date(latestBadge.pivot.updatedAt)
      isThisYear = earnedAt.getFullYear() === new Date().getFullYear()
    }

    const dateFormatter = useMemo(
      () =>
        new Intl.DateTimeFormat(
          i18n.language,
          isThisYear
            ? {
                day: 'numeric',
                month: 'long',
              }
            : { dateStyle: 'long' }
        ),
      [i18n.language, isThisYear]
    )

    return (
      <div className={classNames(props.className, styles.outer)} ref={ref}>
        <h2>{t('menu.badges')}</h2>

        <Link
          className={classNames(styles.panel, {
            [styles.placeholder]: !latestBadge,
          })}
          to='/badges'
        >
          <h3>
            {t(latestBadge ? 'badges.lastEarnedBadge' : 'badges.noneEarnedYet')}
          </h3>

          <div className={styles.banner}>
            <img src={latestBadge ? latestBadge.image.url : placeholder} />

            {latestBadge ? (
              <div className={styles.description}>
                <strong>{latestBadge.skill.name}</strong>
                {earnedAt && (
                  <>
                    <hr />
                    <div className={styles.date}>
                      {dateFormatter.format(earnedAt)}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={styles.description}>{t('badges.motivator')}</div>
            )}
          </div>

          <TertiaryButton className={styles.button} icon>
            <ForwardIcon />
          </TertiaryButton>
        </Link>
      </div>
    )
  }
)
