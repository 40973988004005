import enUs from './en-US'
import neutral from './neutral'
import nlNl from './nl-NL'

export const locales = {
  neutral: { translation: neutral },

  'en-US': { translation: enUs },
  'nl-NL': { translation: nlNl },
}
