/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserStage = "NEW" | "PROFILE_CREATED" | "READY_FOR_TEAM" | "TEAM_ACKNOWLEDGED" | "TEAM_ASSIGNED";
export type TeamJoinedPopupAcknowledgeMutationVariables = {};
export type TeamJoinedPopupAcknowledgeMutationResponse = {
    readonly acknowledgeTeamJoined: {
        readonly user: {
            readonly stage: UserStage;
        } | null;
    };
};
export type TeamJoinedPopupAcknowledgeMutation = {
    readonly response: TeamJoinedPopupAcknowledgeMutationResponse;
    readonly variables: TeamJoinedPopupAcknowledgeMutationVariables;
};



/*
mutation TeamJoinedPopupAcknowledgeMutation {
  acknowledgeTeamJoined {
    user {
      stage
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stage",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "TeamJoinedPopupAcknowledgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamJoinedAcknowledgementResult",
                    "kind": "LinkedField",
                    "name": "acknowledgeTeamJoined",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "TeamJoinedPopupAcknowledgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamJoinedAcknowledgementResult",
                    "kind": "LinkedField",
                    "name": "acknowledgeTeamJoined",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "3059b91d47da0ee6c0f657c01e87baeb",
            "id": null,
            "metadata": {},
            "name": "TeamJoinedPopupAcknowledgeMutation",
            "operationKind": "mutation",
            "text": "mutation TeamJoinedPopupAcknowledgeMutation {\n  acknowledgeTeamJoined {\n    user {\n      stage\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '39670fac052b8490f2daf0f3679dd994';
export default node;
