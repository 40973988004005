import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { MissionStats_stats$key } from '../../../generated/MissionStats_stats.graphql'
import { Stat } from './Stat'

interface MissionStatsProps {
  stats: MissionStats_stats$key
}

export function MissionStats(props: MissionStatsProps): ReactElement {
  const { t } = useTranslation()
  const stats = useFragment(
    graphql`
      fragment MissionStats_stats on Query
      @argumentDefinitions(first: { type: "Int!" }) {
        missionsExceptKickOff: missions(
          first: $first
          filter: ALL_EXCEPT_KICKOFF
        ) {
          pageInfo {
            total
          }
        }
        completed: missions(first: $first, filter: COMPLETED) {
          pageInfo {
            total
          }
        }
      }
    `,
    props.stats
  )

  return (
    <Stat
      caption={t('mission.stat.completed')}
      color='#8BBF53'
      number={stats.completed?.pageInfo.total ?? 0}
      percentage={
        stats.missionsExceptKickOff?.pageInfo.total
          ? ((stats.completed?.pageInfo.total ?? 0) /
              stats.missionsExceptKickOff.pageInfo.total) *
            100
          : 0
      }
    />
  )
}
