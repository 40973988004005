/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HeaderEditableAvatarQueryVariables = {};
export type HeaderEditableAvatarQueryResponse = {
    readonly me: {
        readonly avatar: string | null;
        readonly " $fragmentRefs": FragmentRefs<"EditProfilePopup_user">;
    } | null;
};
export type HeaderEditableAvatarQuery = {
    readonly response: HeaderEditableAvatarQueryResponse;
    readonly variables: HeaderEditableAvatarQueryVariables;
};



/*
query HeaderEditableAvatarQuery {
  me {
    avatar
    ...EditProfilePopup_user
    id
  }
}

fragment EditProfilePopup_user on AuthenticatedUser {
  avatar
  firstName
  function
  id
  lastName
  departments {
    name
    id
  }
  ...EditableAvatar_user
}

fragment EditableAvatar_user on AuthenticatedUser {
  avatar
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "HeaderEditableAvatarQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditProfilePopup_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "HeaderEditableAvatarQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "function",
                            "storageKey": null
                        },
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Department",
                            "kind": "LinkedField",
                            "name": "departments",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "0b592ca269ce655752d8299b93301080",
            "id": null,
            "metadata": {},
            "name": "HeaderEditableAvatarQuery",
            "operationKind": "query",
            "text": "query HeaderEditableAvatarQuery {\n  me {\n    avatar\n    ...EditProfilePopup_user\n    id\n  }\n}\n\nfragment EditProfilePopup_user on AuthenticatedUser {\n  avatar\n  firstName\n  function\n  id\n  lastName\n  departments {\n    name\n    id\n  }\n  ...EditableAvatar_user\n}\n\nfragment EditableAvatar_user on AuthenticatedUser {\n  avatar\n}\n"
        }
    } as any;
})();
(node as any).hash = '147f05dc3b482c31e5976583ff18bebd';
export default node;
