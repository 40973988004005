/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NewMissionPopup_mission = {
    readonly assignments: {
        readonly pageInfo: {
            readonly total: number;
        };
    } | null;
    readonly banner: {
        readonly url: string;
    };
    readonly challenges: {
        readonly pageInfo: {
            readonly total: number;
        };
    } | null;
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly " $refType": "NewMissionPopup_mission";
};
export type NewMissionPopup_mission$data = NewMissionPopup_mission;
export type NewMissionPopup_mission$key = {
    readonly " $data"?: NewMissionPopup_mission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NewMissionPopup_mission">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "filter",
            "value": "ALL"
        } as any,
        {
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ], v2 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "NewMissionPopup_mission",
        "selections": [
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "concreteType": "AssignmentConnection",
                "kind": "LinkedField",
                "name": "assignments",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": "assignments(filter:\"ALL\",first:1)"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "banner",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "concreteType": "ChallengeConnection",
                "kind": "LinkedField",
                "name": "challenges",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": "challenges(filter:\"ALL\",first:1)"
            },
            {
                "alias": null,
                "args": (v2 /*: any*/),
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v2 /*: any*/),
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "type": "Mission",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '563394cc524be22d38462ec7f6fa63fc';
export default node;
