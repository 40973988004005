import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Header } from '../../containers/Header'
import { useStores } from '../../stores'

import styles from './InternetRequiredPopup.scss'

export function InternetRequiredPopup(): ReactElement {
  const history = useHistory()
  const { commonStore } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      if (!commonStore.online) {
        history.push('/')
      }
    }
  }, [commonStore, history])

  return (
    <div className={styles.popup}>
      <Header duel wide>
        <h1 id='popup-label'>{t('error.unavailableOffline.title')}</h1>
        <p>{t('error.unavailableOffline.subtitle')}</p>

        <div className={styles.circle}>
          <FontAwesomeIcon icon={faWifiSlash} />
        </div>
      </Header>

      <div className={styles.explanation}>
        {t('error.unavailableOffline.explanation')}
      </div>
    </div>
  )
}
