/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskforceType = "CORRUPTION" | "EMBEZZLING" | "FRAUD" | "MONEY_LAUNDERING";
export type TaskforceJoinedPopup_taskforce = {
    readonly taskforceType: TaskforceType;
    readonly " $refType": "TaskforceJoinedPopup_taskforce";
};
export type TaskforceJoinedPopup_taskforce$data = TaskforceJoinedPopup_taskforce;
export type TaskforceJoinedPopup_taskforce$key = {
    readonly " $data"?: TaskforceJoinedPopup_taskforce$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskforceJoinedPopup_taskforce">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskforceJoinedPopup_taskforce",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskforceType",
            "storageKey": null
        }
    ],
    "type": "Taskforce",
    "abstractKey": null
} as any;
(node as any).hash = '7f050a3eaf912d1656e66e079d7b7195';
export default node;
