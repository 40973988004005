/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PushTokenPlatform = "ANDROID" | "IOS";
export type jsBridgeStorePushTokenMutationVariables = {
    token: string;
    platform: PushTokenPlatform;
    device: string;
};
export type jsBridgeStorePushTokenMutationResponse = {
    readonly storePushToken: {
        readonly __typename: string;
    };
};
export type jsBridgeStorePushTokenMutation = {
    readonly response: jsBridgeStorePushTokenMutationResponse;
    readonly variables: jsBridgeStorePushTokenMutationVariables;
};



/*
mutation jsBridgeStorePushTokenMutation(
  $token: String!
  $platform: PushTokenPlatform!
  $device: String!
) {
  storePushToken(device: $device, platform: $platform, token: $token) {
    __typename
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "device"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "platform"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "token"
    } as any, v3 = [
        {
            "kind": "Variable",
            "name": "device",
            "variableName": "device"
        } as any,
        {
            "kind": "Variable",
            "name": "platform",
            "variableName": "platform"
        } as any,
        {
            "kind": "Variable",
            "name": "token",
            "variableName": "token"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "jsBridgeStorePushTokenMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "PushToken",
                    "kind": "LinkedField",
                    "name": "storePushToken",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "jsBridgeStorePushTokenMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "PushToken",
                    "kind": "LinkedField",
                    "name": "storePushToken",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a529aec8d7bdf506323de82381110a9c",
            "id": null,
            "metadata": {},
            "name": "jsBridgeStorePushTokenMutation",
            "operationKind": "mutation",
            "text": "mutation jsBridgeStorePushTokenMutation(\n  $token: String!\n  $platform: PushTokenPlatform!\n  $device: String!\n) {\n  storePushToken(device: $device, platform: $platform, token: $token) {\n    __typename\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '95789fd9eccddc5b8e06771fe2da1d1d';
export default node;
