/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EditProfilePopupMutationVariables = {
    firstName: string;
    lastName: string;
    function: string;
};
export type EditProfilePopupMutationResponse = {
    readonly updateProfile: {
        readonly firstName: string;
        readonly lastName: string;
        readonly function: string;
    };
};
export type EditProfilePopupMutation = {
    readonly response: EditProfilePopupMutationResponse;
    readonly variables: EditProfilePopupMutationVariables;
};



/*
mutation EditProfilePopupMutation(
  $firstName: String!
  $lastName: String!
  $function: String!
) {
  updateProfile(firstName: $firstName, lastName: $lastName, function: $function) {
    firstName
    lastName
    function
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "firstName"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "function"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "lastName"
    } as any, v3 = [
        {
            "kind": "Variable",
            "name": "firstName",
            "variableName": "firstName"
        } as any,
        {
            "kind": "Variable",
            "name": "function",
            "variableName": "function"
        } as any,
        {
            "kind": "Variable",
            "name": "lastName",
            "variableName": "lastName"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "function",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "EditProfilePopupMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "updateProfile",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "EditProfilePopupMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "updateProfile",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "83cf551ded0a4eed282ee6cb0c72f6b2",
            "id": null,
            "metadata": {},
            "name": "EditProfilePopupMutation",
            "operationKind": "mutation",
            "text": "mutation EditProfilePopupMutation(\n  $firstName: String!\n  $lastName: String!\n  $function: String!\n) {\n  updateProfile(firstName: $firstName, lastName: $lastName, function: $function) {\n    firstName\n    lastName\n    function\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '7a1bb30561cca3f30c925e88d150e257';
export default node;
