/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskforcesOverview_taskforces = ReadonlyArray<{
    readonly id: string;
    readonly lastSnapshotRank: number;
    readonly totalXp: number;
    readonly " $fragmentRefs": FragmentRefs<"Taskforce_taskforce">;
    readonly " $refType": "TaskforcesOverview_taskforces";
}>;
export type TaskforcesOverview_taskforces$data = TaskforcesOverview_taskforces;
export type TaskforcesOverview_taskforces$key = ReadonlyArray<{
    readonly " $data"?: TaskforcesOverview_taskforces$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskforcesOverview_taskforces">;
}>;



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "plural": true
    },
    "name": "TaskforcesOverview_taskforces",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSnapshotRank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalXp",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Taskforce_taskforce"
        }
    ],
    "type": "Taskforce",
    "abstractKey": null
} as any;
(node as any).hash = '0bd9fdd5be486ca6a9fce6576c99a427';
export default node;
