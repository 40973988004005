import styles from './KalturaEmbed.scss'

interface KalturaEmbedProps {
  url: string
}

export function KalturaEmbed(props: KalturaEmbedProps) {
  return (
    <iframe
      allow='autoplay *; fullscreen *; encrypted-media *'
      allowFullScreen
      className={styles.kaltura}
      frameBorder='0'
      referrerPolicy='no-referrer-when-downgrade'
      // TODO: Test if this works on ABN laptops without the default sandbox
      // policy of `allow-top-navigation`.
      sandbox='allow-forms allow-same-origin allow-scripts allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation'
      src={props.url}
    />
  )
}
