/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type InteractionOutsideGameDeleteUploadMutationVariables = {
    taskId: string;
};
export type InteractionOutsideGameDeleteUploadMutationResponse = {
    readonly deleteUploadedFile: {
        readonly completion: {
            readonly uploadedFile: {
                readonly __typename: string;
            } | null;
        } | null;
    } | null;
};
export type InteractionOutsideGameDeleteUploadMutationRawResponse = {
    readonly deleteUploadedFile: ({
        readonly completion: ({
            readonly uploadedFile: ({
                readonly __typename: "UploadedFile";
                readonly id: string;
            }) | null;
            readonly id: string;
        }) | null;
        readonly id: string;
    }) | null;
};
export type InteractionOutsideGameDeleteUploadMutation = {
    readonly response: InteractionOutsideGameDeleteUploadMutationResponse;
    readonly variables: InteractionOutsideGameDeleteUploadMutationVariables;
    readonly rawResponse: InteractionOutsideGameDeleteUploadMutationRawResponse;
};



/*
mutation InteractionOutsideGameDeleteUploadMutation(
  $taskId: ID!
) {
  deleteUploadedFile(taskId: $taskId) {
    completion {
      uploadedFile {
        __typename
        id
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "InteractionOutsideGameDeleteUploadMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "deleteUploadedFile",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UploadedFile",
                                    "kind": "LinkedField",
                                    "name": "uploadedFile",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "InteractionOutsideGameDeleteUploadMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "deleteUploadedFile",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UploadedFile",
                                    "kind": "LinkedField",
                                    "name": "uploadedFile",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "34c304aad2559ce14a831db5b1fd7983",
            "id": null,
            "metadata": {},
            "name": "InteractionOutsideGameDeleteUploadMutation",
            "operationKind": "mutation",
            "text": "mutation InteractionOutsideGameDeleteUploadMutation(\n  $taskId: ID!\n) {\n  deleteUploadedFile(taskId: $taskId) {\n    completion {\n      uploadedFile {\n        __typename\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '977051a175269fe42cd976943fa95cb2';
export default node;
