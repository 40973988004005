import { PropsWithChildren, ReactElement } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { Interaction_interactable$key } from '../../../generated/Interaction_interactable.graphql'
import { Interaction_task$key } from '../../../generated/Interaction_task.graphql'
import { never } from '../../../utils/assert'
import { PopupProps } from '../../../utils/hooks/usePopups'
import { InteractionOutsideGame } from './InteractionOutsideGame'
import { LinkInteraction } from './LinkInteraction'
import { LtiInteraction } from './LtiInteraction'
import { OpenQuestionInteraction } from './OpenQuestionInteraction'
import { QuestionInteraction } from './QuestionInteraction'

interface InteractionProps {
  interactable: Interaction_interactable$key
  task: Interaction_task$key
  taskType: 'assignment' | 'challenge'
}

export function Interaction(
  props: PropsWithChildren<PopupProps<InteractionProps>>
): ReactElement {
  const task = useFragment(
    graphql`
      fragment Interaction_task on Task {
        ...InteractionOutsideGame_task
        ...LinkInteraction_task
        ...LtiInteraction_task
        ...OpenQuestionInteraction_task
      }
    `,
    props.task
  )
  const interaction = useFragment(
    graphql`
      fragment Interaction_interactable on Interactable
      @argumentDefinitions(language: { type: "String!" }) {
        __typename
        ...InteractionOutsideGame_interaction @arguments(language: $language)
        ...LinkInteraction_interaction @arguments(language: $language)
        ...LtiInteraction_interaction @arguments(language: $language)
        ...QuestionInteraction_interaction @arguments(language: $language)
        ...OpenQuestionInteraction_question @arguments(language: $language)
      }
    `,
    props.interactable
  )

  switch (interaction.__typename) {
    case 'InteractionOutsideGame':
      return (
        <InteractionOutsideGame
          {...props}
          interaction={interaction}
          task={task}
          taskType={props.taskType}
        >
          {props.children}
        </InteractionOutsideGame>
      )
    case 'LinkInteraction':
      return (
        <LinkInteraction {...props} interaction={interaction} task={task}>
          {props.children}
        </LinkInteraction>
      )
    case 'LtiInteraction':
      return (
        <LtiInteraction interaction={interaction} task={task}>
          {props.children}
        </LtiInteraction>
      )
    case 'QuestionInteraction':
      return (
        <QuestionInteraction {...props} interaction={interaction}>
          {props.children}
        </QuestionInteraction>
      )
    case 'OpenQuestionInteraction':
      return (
        <OpenQuestionInteraction {...props} question={interaction} task={task}>
          {props.children}
        </OpenQuestionInteraction>
      )
    default:
      never(
        interaction.__typename,
        `Invalid interaction type '${interaction.__typename}'`
      )
  }
}
