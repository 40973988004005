import { CSSProperties, ReactElement } from 'react'
import { classNames } from '../../utils/classNames'
import styles from './Star.scss'

export const MAX_STARS = 3

interface StarProps {
  large?: boolean
  lit: boolean
  pop?: boolean
  popDelayIndex?: number
}

export function Star(props: StarProps): ReactElement {
  const style: CSSProperties = {
    animationDelay: `${0.5 + 0.85 * (props.popDelayIndex ?? 0)}s`,
  }

  return (
    <svg
      className={classNames(styles.star, {
        [styles.large]: props.large,
        [styles.lit]: props.lit,
        [styles.pop]: props.pop,
      })}
      style={style}
      viewBox='0 0 24.823 23.05'
    >
      <path
        d='M12.412,0,8.768,7.845,0,8.8l6.516,5.808L4.741,23.05l7.671-4.256,7.671,4.256-1.775-8.438L24.823,8.8l-8.768-.959Z'
        style={style}
        transform='translate(0 0)'
      />
    </svg>
  )
}
