import i18n from 'i18next'

const mapping: Record<string, string> = {
  'en-AU': 'en-US',
  'en-BZ': 'en-US',
  'en-CA': 'en-US',
  'en-CB': 'en-US',
  'en-GB': 'en-US',
  'en-IE': 'en-US',
  'en-JM': 'en-US',
  'en-NZ': 'en-US',
  'en-PH': 'en-US',
  'en-TT': 'en-US',
  'en-ZA': 'en-US',
  'en-ZW': 'en-US',
  en: 'en-US',
  'nl-BE': 'nl-NL',
  nl: 'nl-NL',
}

export function isLanguageSupported(language: string): boolean {
  return !!i18n.getDataByLanguage(language)
}

export async function detectLanguage(): Promise<string> {
  const languages = navigator.languages
    ? navigator.languages
    : [navigator.language]
  for (let language of languages) {
    if (language in mapping) {
      language = mapping[language]
    }

    if (isLanguageSupported(language)) {
      return language
    }
  }

  // Default case is English.
  return 'en-US'
}
