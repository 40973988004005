import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEvent, ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useMutation, useFragment } from 'react-relay'

import assignmentIcon from '../../../../../images/icons/assignment-task.svg'
import challengeIcon from '../../../../../images/icons/challenge-task.svg'
import { TaskPopupStartMutation } from '../../generated/TaskPopupStartMutation.graphql'
import {
  PopupProps,
  TaskPopup as AssignmentPopupProps,
} from '../../utils/hooks/usePopups'
import { SecondaryButton } from '../common/SecondaryButton'
import { Interaction } from './interactions/Interaction'
import { TaskDescriptionMedia } from './TaskDescriptionMedia'

import styles from './TaskPopup.scss'

export function TaskPopup(
  props: PopupProps<AssignmentPopupProps>
): ReactElement {
  const { closeCurrentPopup } = props
  const { i18n, t } = useTranslation()

  const task = useFragment(
    graphql`
      fragment TaskPopup_task on Task
      @argumentDefinitions(language: { type: "String!" }) {
        banner {
          url
        }
        completion {
          completedAt
          startedAt
          xpClaimedAt
        }
        description(language: $language)
        descriptionUpload {
          ...TaskDescriptionMedia_upload
        }
        download {
          fileName
          url
        }
        durationMinutes
        interactable {
          ...Interaction_interactable @arguments(language: $language)
        }
        intro(language: $language)
        id
        skill {
          name(language: $language)
        }
        skillXp
        title(language: $language)
        type
        ...Interaction_task
      }
    `,
    props.task
  )

  const [startTask] = useMutation<TaskPopupStartMutation>(graphql`
    mutation TaskPopupStartMutation($taskId: ID!) @raw_response_type {
      startTask(id: $taskId) {
        id
        completion {
          startedAt
        }
      }
    }
  `)

  useEffect(() => {
    if (!task.completion?.startedAt) {
      startTask({
        optimisticResponse: {
          startTask: {
            id: task.id,
            completion: {
              id: '',
              startedAt: new Date().toISOString(),
            },
          },
        },
        variables: {
          taskId: task.id,
        },
      })
    }
  }, [startTask, task.completion?.startedAt, task.id])

  const closePopup = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      closeCurrentPopup()
    },
    [closeCurrentPopup]
  )

  return (
    <>
      <div className={styles.taskTypeIndicator}>
        {props.taskType === 'assignment' && (
          <img className={styles.taskTypeIcon} src={assignmentIcon} />
        )}
        {props.taskType === 'challenge' && (
          <img className={styles.taskTypeIcon} src={challengeIcon} />
        )}

        <span>{t('task.type.' + props.taskType)}</span>
      </div>

      <div
        className={styles.banner}
        style={{ backgroundImage: `url('${task.banner.url}')` }}
      />

      <div className={styles.content}>
        <h1>{task.title}</h1>

        <div className={styles.assignmentInfo}>
          <div className={styles.stats}>
            <dl>
              <dt>{t('task.type.label')}</dt>
              <dd>
                {t(`task.type.${task.type.toLowerCase()}.${props.taskType}`)}
              </dd>
              <dt>{t('task.duration')}</dt>
              <dd>
                {t('common.minutes', {
                  count: task.durationMinutes,
                  formatted: new Intl.NumberFormat(i18n.language).format(
                    task.durationMinutes
                  ),
                })}
              </dd>
              <dt>{t('task.skill')}</dt>
              <dd>{task.skill.name}</dd>
            </dl>
          </div>

          {!task.completion?.xpClaimedAt ? (
            <div className={styles.xp}>
              <div className={styles.number}>
                {new Intl.NumberFormat(i18n.language, {
                  signDisplay: 'always',
                }).format(task.skillXp)}
              </div>
              <div className={styles.label}>{t('xp.label')}</div>
            </div>
          ) : (
            <div className={styles.completed}>
              <div className={styles.circle}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              {t('task.completed.title')}
            </div>
          )}
        </div>

        <div className={styles.description}>
          <p>{task.intro}</p>

          <div dangerouslySetInnerHTML={{ __html: task.description }} />
        </div>

        <TaskDescriptionMedia upload={task.descriptionUpload} />

        {task.download && (
          <a
            className={styles.download}
            download={task.download.fileName}
            href={task.download.url}
          >
            {t('task.downloadFile', {
              filename: task.download.fileName,
            })}
          </a>
        )}

        <Interaction
          {...props}
          interactable={task.interactable}
          task={task}
          taskType={props.taskType}
        >
          <SecondaryButton onClick={closePopup}>
            {task.completion?.completedAt
              ? t('common.Close')
              : t('common.Cancel')}
          </SecondaryButton>
        </Interaction>
      </div>
    </>
  )
}
