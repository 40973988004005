/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BadgeStats_stats = {
    readonly badges: ReadonlyArray<{
        readonly image: {
            readonly url: string;
        };
        readonly skill: {
            readonly name: string;
        };
        readonly pivot: {
            readonly updatedAt: string | null;
        } | null;
    }>;
    readonly " $refType": "BadgeStats_stats";
};
export type BadgeStats_stats$data = BadgeStats_stats;
export type BadgeStats_stats$key = {
    readonly " $data"?: BadgeStats_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BadgeStats_stats">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BadgeStats_stats",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadedFile",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "skill",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "language",
                                    "variableName": "language"
                                }
                            ],
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserBadge",
                    "kind": "LinkedField",
                    "name": "pivot",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = 'abfdc29ec841b93da2c29376a76b473d';
export default node;
