/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskCompletedPopup_task = {
    readonly feedback: string;
    readonly id: string;
    readonly skillXp: number;
    readonly " $refType": "TaskCompletedPopup_task";
};
export type TaskCompletedPopup_task$data = TaskCompletedPopup_task;
export type TaskCompletedPopup_task$key = {
    readonly " $data"?: TaskCompletedPopup_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskCompletedPopup_task">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskCompletedPopup_task",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "feedback",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skillXp",
            "storageKey": null
        }
    ],
    "type": "Task",
    "abstractKey": null
} as any;
(node as any).hash = '1e8124493313ae45c2e3649e5076db04';
export default node;
