/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TaskPopupStartMutationVariables = {
    taskId: string;
};
export type TaskPopupStartMutationResponse = {
    readonly startTask: {
        readonly id: string;
        readonly completion: {
            readonly startedAt: string | null;
        } | null;
    } | null;
};
export type TaskPopupStartMutationRawResponse = {
    readonly startTask: ({
        readonly id: string;
        readonly completion: ({
            readonly startedAt: string | null;
            readonly id: string;
        }) | null;
    }) | null;
};
export type TaskPopupStartMutation = {
    readonly response: TaskPopupStartMutationResponse;
    readonly variables: TaskPopupStartMutationVariables;
    readonly rawResponse: TaskPopupStartMutationRawResponse;
};



/*
mutation TaskPopupStartMutation(
  $taskId: ID!
) {
  startTask(id: $taskId) {
    id
    completion {
      startedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "taskId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startedAt",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TaskPopupStartMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "startTask",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TaskPopupStartMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "startTask",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ec745a1bd0a32872811d95a0c651db83",
            "id": null,
            "metadata": {},
            "name": "TaskPopupStartMutation",
            "operationKind": "mutation",
            "text": "mutation TaskPopupStartMutation(\n  $taskId: ID!\n) {\n  startTask(id: $taskId) {\n    id\n    completion {\n      startedAt\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3fcc52177ca54bb82268c9d82193f5b3';
export default node;
