import { observer } from 'mobx-react'
import { PropsWithChildren, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import { LinkInteraction_interaction$key } from '../../../generated/LinkInteraction_interaction.graphql'
import { LinkInteraction_task$key } from '../../../generated/LinkInteraction_task.graphql'
import { LinkInteractionSubmitMutation } from '../../../generated/LinkInteractionSubmitMutation.graphql'
import { useStores } from '../../../stores'
import { PopupProps } from '../../../utils/hooks/usePopups'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './_interactions.scss'

interface LinkInteractionProps {
  interaction: LinkInteraction_interaction$key
  task: LinkInteraction_task$key
}

export const LinkInteraction = observer(function LinkInteraction(
  props: PopupProps<PropsWithChildren<LinkInteractionProps>>
): ReactElement {
  const { i18n, t } = useTranslation()
  const { commonStore } = useStores()
  const { closeCurrentPopup, replaceCurrentPopup } = props

  const interaction = useFragment(
    graphql`
      fragment LinkInteraction_interaction on LinkInteraction
      @argumentDefinitions(language: { type: "String!" }) {
        launchUrl
        linkText(language: $language)
      }
    `,
    props.interaction
  )
  const task = useFragment(
    graphql`
      fragment LinkInteraction_task on Task {
        id
      }
    `,
    props.task
  )
  const [submit] = useMutation<LinkInteractionSubmitMutation>(graphql`
    mutation LinkInteractionSubmitMutation(
      $challengesConnection: ID!
      $language: String!
      $taskId: ID!
    ) {
      submitTask(id: $taskId) {
        challenge {
          id @deleteEdge(connections: [$challengesConnection])
        }
        nextAssignment {
          ...Assignment_assignment @arguments(language: $language)
        }
        newChallenge
          @appendNode(
            connections: [$challengesConnection]
            edgeTypeName: "ChallengeEdge"
          ) {
          ...Challenge_challenge @arguments(language: $language)
        }
        task {
          completion {
            completedAt
          }
          id
          ...TaskCompletedPopup_task @arguments(language: $language)
        }
      }
    }
  `)

  const onLinkClicked = useCallback(() => {
    // NOTE: Not calling `preventDefault()` here, so the link is opened normally.
    // While the user is gone, we call `submitTask` and prepare the pop-up.
    submit({
      variables: {
        taskId: task.id,
        challengesConnection: commonStore.challengesConnection,
        language: i18n.language,
      },
    }).then((response) => {
      if (response.submitTask.task) {
        replaceCurrentPopup({
          popupKey: response.submitTask.task.id,
          type: 'task-completed',
          task: response.submitTask.task,
        })
      } else {
        closeCurrentPopup()
      }
    })
  }, [
    closeCurrentPopup,
    commonStore.challengesConnection,
    i18n.language,
    replaceCurrentPopup,
    submit,
    task.id,
  ])

  return (
    <div className={styles.buttons}>
      <PrimaryButton
        className={styles.primaryButton}
        component='a'
        href={interaction.launchUrl}
        onClick={onLinkClicked}
        target='_blank'
      >
        {interaction.linkText ?? t('interactions.link.open')}
      </PrimaryButton>

      {props.children}
    </div>
  )
})
