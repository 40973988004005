import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { AssignmentStats_stats$key } from '../../../generated/AssignmentStats_stats.graphql'
import { Stat } from './Stat'

interface AssignmentStatsProps {
  stats: AssignmentStats_stats$key
}

export function AssignmentStats(props: AssignmentStatsProps): ReactElement {
  const { t } = useTranslation()
  const stats = useFragment(
    graphql`
      fragment AssignmentStats_stats on Query
      @argumentDefinitions(first: { type: "Int!" }) {
        missions(first: $first, filter: ALL) {
          edges {
            node {
              allAssignments: assignments(first: $first, filter: ALL) {
                pageInfo {
                  total
                }
              }
              completedAssignments: assignments(
                first: $first
                filter: COMPLETED
              ) {
                pageInfo {
                  total
                }
              }
            }
          }
        }
      }
    `,
    props.stats
  )

  const completedAssignments =
    stats.missions?.edges
      .map((edge) => edge.node)
      .map((mission) => mission.completedAssignments?.pageInfo.total ?? 0)
      .reduce((acc, completed) => acc + completed, 0) ?? 0
  const totalAssignments =
    stats.missions?.edges
      .map((edge) => edge.node)
      .map((mission) => mission.allAssignments?.pageInfo.total ?? 0)
      .reduce((acc, completed) => acc + completed, 0) ?? 0

  return (
    <Stat
      caption={t('assignments.stat.completed')}
      color='#F3C000'
      number={completedAssignments}
      percentage={
        totalAssignments > 0
          ? (completedAssignments / totalAssignments) * 100
          : 0
      }
    />
  )
}
