import { observer } from 'mobx-react'
import { PropsWithChildren, ReactElement } from 'react'

import { classNames } from '../utils/classNames'

import styles from './Page.scss'

type PageProps =
  | {
      wide?: boolean
    }
  | {
      narrow?: boolean
    }

export const Page = observer(function Page(
  props: PropsWithChildren<PageProps>
): ReactElement {
  const wide = 'wide' in props && props.wide
  const narrow = !wide && 'narrow' in props && props.narrow

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          className={classNames({
            [styles.pageWide]: wide,
            [styles.pageNarrow]: narrow,
            [styles.page]: !wide && !narrow,
          })}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
})
