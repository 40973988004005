/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InteractionOutsideGame_task = {
    readonly id: string;
    readonly completion: {
        readonly completedAt: string | null;
        readonly id: string;
        readonly uploadedFile: {
            readonly fileName: string;
            readonly url: string;
        } | null;
    } | null;
    readonly " $refType": "InteractionOutsideGame_task";
};
export type InteractionOutsideGame_task$data = InteractionOutsideGame_task;
export type InteractionOutsideGame_task$key = {
    readonly " $data"?: InteractionOutsideGame_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InteractionOutsideGame_task">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "InteractionOutsideGame_task",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "TaskCompletion",
                "kind": "LinkedField",
                "name": "completion",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadedFile",
                        "kind": "LinkedField",
                        "name": "uploadedFile",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fileName",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Task",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '3a5664cbce26d42d652ffc90765e67c7';
export default node;
