/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AvatarCropPopupSubmitAvatarMutationVariables = {
    file: unknown;
};
export type AvatarCropPopupSubmitAvatarMutationResponse = {
    readonly changeAvatar: {
        readonly avatar: string | null;
        readonly id: string;
    };
};
export type AvatarCropPopupSubmitAvatarMutation = {
    readonly response: AvatarCropPopupSubmitAvatarMutationResponse;
    readonly variables: AvatarCropPopupSubmitAvatarMutationVariables;
};



/*
mutation AvatarCropPopupSubmitAvatarMutation(
  $file: Upload!
) {
  changeAvatar(file: $file) {
    avatar
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "file"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "file",
                    "variableName": "file"
                }
            ],
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "changeAvatar",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ffbe58c389d7eec4019da8101f219877",
            "id": null,
            "metadata": {},
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "operationKind": "mutation",
            "text": "mutation AvatarCropPopupSubmitAvatarMutation(\n  $file: Upload!\n) {\n  changeAvatar(file: $file) {\n    avatar\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '9ed20f4284f1d5c88ed650028677786b';
export default node;
