import { forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import cup from '../../../../../images/silver-cup.png'

import { TaskforcesOverview_taskforces$key } from '../../generated/TaskforcesOverview_taskforces.graphql'
import { TaskforcesOverview_user$key } from '../../generated/TaskforcesOverview_user.graphql'
import { classNames } from '../../utils/classNames'
import { Taskforce } from './Taskforce'

import styles from './TaskforcesOverview.scss'

interface TaskforcesOverviewProps {
  className?: string
  taskforces: TaskforcesOverview_taskforces$key
  user: TaskforcesOverview_user$key | null
}

export const TaskforcesOverview = forwardRef<
  HTMLDivElement,
  TaskforcesOverviewProps
>(function TaskforcesOverview(props, ref): ReactElement {
  const { i18n, t } = useTranslation()

  const taskforces = [
    ...useFragment(
      graphql`
        fragment TaskforcesOverview_taskforces on Taskforce
        @relay(plural: true) {
          id
          lastSnapshotRank
          totalXp
          ...Taskforce_taskforce
        }
      `,
      props.taskforces
    ),
  ]
  const userTaskforce = useFragment(
    graphql`
      fragment TaskforcesOverview_user on Taskforce {
        id
      }
    `,
    props.user
  )
  taskforces.sort((a, b) => b.totalXp - a.totalXp)

  let lastXp = -1
  let userTaskforceRank = 0
  for (const taskforce of taskforces) {
    if (taskforce.totalXp > lastXp) {
      ++userTaskforceRank
    }

    if (taskforce.id === userTaskforce?.id) {
      break
    }
  }

  lastXp = Number.MAX_SAFE_INTEGER
  let lastRank = 0
  let lastRankSkip = 0
  const rankings: Record<string, number> = {}
  for (const taskforce of taskforces) {
    if (taskforce.totalXp < lastXp) {
      lastXp = taskforce.totalXp
      lastRank += lastRankSkip + 1
      lastRankSkip = 0
    } else {
      ++lastRankSkip
    }

    rankings[taskforce.id] = lastRank
  }

  const lastMaxSnapshotRank = Math.max(
    ...taskforces.map((tf) => tf.lastSnapshotRank)
  )
  const newMaxSnapshotRank = lastRank

  return (
    <div className={classNames(props.className, styles.overview)} ref={ref}>
      <h2>{t('taskforce.title')}</h2>

      <div className={styles.panel}>
        <div className={styles.rank}>
          <img src={cup} className={styles.cup} />
          <div className={styles.number}>
            {t('taskforce.rank', {
              ord: t(
                'taskforce.ord.' +
                  new Intl.PluralRules(i18n.language, {
                    type: 'ordinal',
                  }).select(userTaskforceRank),
                {
                  num: userTaskforceRank,
                }
              ),
            })}
          </div>
        </div>

        <ol className={styles.list}>
          {taskforces?.map((taskforce) => (
            <Taskforce
              key={taskforce.id}
              containsCurrentUser={userTaskforce?.id === taskforce.id}
              rank={rankings[taskforce.id]}
              stillInLastPlace={
                rankings[taskforce.id] === newMaxSnapshotRank &&
                taskforce.lastSnapshotRank === lastMaxSnapshotRank
              }
              taskforce={taskforce}
            />
          ))}
        </ol>
      </div>
    </div>
  )
})
