import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEvent, ReactElement, useCallback, useContext } from 'react'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import defaultAvatar from '../../../../../images/default-avatar.png'
import { HeaderEditableAvatarQuery } from '../../generated/HeaderEditableAvatarQuery.graphql'
import { classNames } from '../../utils/classNames'
import { avatarUrl } from '../../utils/url'

import styles from './HeaderEditableAvatar.scss'
import { PopupContext } from './Routing'

export function HeaderEditableAvatar(): ReactElement {
  const { openPopup } = useContext(PopupContext)

  const user = useQuery<HeaderEditableAvatarQuery>(
    graphql`
      query HeaderEditableAvatarQuery {
        me {
          avatar
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...EditProfilePopup_user
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    }
  )

  const onClick = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      if (!user.data?.me) {
        return
      }

      openPopup(
        {
          type: 'edit-profile',
          user: user.data.me,
        },
        true
      )
    },
    [openPopup, user.data?.me]
  )

  return (
    <div className={styles.placement}>
      <div
        className={classNames(styles.avatar, {
          [styles.loading]: user.data === null,
          [styles.defaultAvatar]: user.data?.me?.avatar === null,
        })}
        onClick={onClick}
      >
        {user && (
          <img
            src={
              user.data?.me?.avatar
                ? avatarUrl(user.data.me.avatar)
                : defaultAvatar
            }
          />
        )}

        <div className={styles.penAttachment}>
          <FontAwesomeIcon icon={faPen} />
        </div>
      </div>
    </div>
  )
}
