import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useFragment } from 'react-relay'
import { Redirect } from 'react-router'

import { MissionScreen_mission$key } from '../../../generated/MissionScreen_mission.graphql'
import { classNames } from '../../../utils/classNames'
import { GraphQlError } from '../../common/GraphQlError'
import { HeaderEditableAvatar } from '../../common/HeaderEditableAvatar'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { NavigationBar } from '../../common/NavigationBar'
import { Assignment } from '../../missions/Assignment'

import styles from './MissionScreen.scss'

interface MissionScreenProps {
  error: Error | null
  isLoading: boolean
  mission: MissionScreen_mission$key | null
}

export function MissionScreen(props: MissionScreenProps): ReactElement {
  const { t } = useTranslation()

  const mission = useFragment(
    graphql`
      fragment MissionScreen_mission on Mission
      @argumentDefinitions(
        assignmentsLimit: { type: "Int!" }
        language: { type: "String!" }
      ) {
        banner {
          url
        }
        description(language: $language)
        title(language: $language)
        assignments(first: $assignmentsLimit) {
          edges {
            node {
              id
              ...Assignment_assignment @arguments(language: $language)
            }
          }
        }
      }
    `,
    props.mission
  )

  if (!mission && !props.isLoading && !props.error) {
    // TODO
    return <Redirect to='/' />
  }

  return (
    <div className={styles.outer}>
      <div className={styles.container}>
        <HeaderEditableAvatar />

        <div className={styles.row}>
          <NavigationBar />
        </div>

        {props.isLoading && <LoadingIndicator />}

        {props.error && <GraphQlError error={props.error} />}

        {mission && (
          <>
            <section className={classNames(styles.row, styles.mission)}>
              <div className={styles.col}>
                <h1>{mission.title}</h1>
                <p>{mission.description}</p>
              </div>
              <div className={styles.col}>
                <img
                  alt={mission.title}
                  className={styles.missionImage}
                  src={mission.banner.url}
                />
              </div>
            </section>

            <section className={styles.assignments}>
              <div className={styles.row}>
                <div className={styles.title}>
                  <h2>{t('assignments.header')}</h2>
                </div>
              </div>

              <div className={styles.row}>
                {mission.assignments?.edges
                  .map((edge) => edge.node)
                  .map((assignment) => (
                    <div className={styles.assignment} key={assignment.id}>
                      <Assignment assignment={assignment} />
                    </div>
                  ))}
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  )
}
