/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OpenQuestionInteraction_question = {
    readonly fields: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"OpenQuestionInteractionField_field">;
    }>;
    readonly previousSubmissionResult: {
        readonly fieldResponses: ReadonlyArray<{
            readonly field: {
                readonly id: string;
            };
            readonly value: string | null;
        }>;
        readonly taskSubmission: {
            readonly feedbackText: string;
            readonly feedbackUpload: {
                readonly " $fragmentRefs": FragmentRefs<"Feedback_upload">;
            } | null;
        };
    } | null;
    readonly id: string;
    readonly " $refType": "OpenQuestionInteraction_question";
};
export type OpenQuestionInteraction_question$data = OpenQuestionInteraction_question;
export type OpenQuestionInteraction_question$key = {
    readonly " $data"?: OpenQuestionInteraction_question$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OpenQuestionInteraction_question">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "OpenQuestionInteraction_question",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "OpenQuestionField",
                "kind": "LinkedField",
                "name": "fields",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "OpenQuestionInteractionField_field"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "OpenQuestionSubmitResult",
                "kind": "LinkedField",
                "name": "previousSubmissionResult",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "OpenQuestionFieldResponse",
                        "kind": "LinkedField",
                        "name": "fieldResponses",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "OpenQuestionField",
                                "kind": "LinkedField",
                                "name": "field",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubmitTaskResult",
                        "kind": "LinkedField",
                        "name": "taskSubmission",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": (v1 /*: any*/),
                                "kind": "ScalarField",
                                "name": "feedbackText",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "UploadedFile",
                                "kind": "LinkedField",
                                "name": "feedbackUpload",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "Feedback_upload"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "type": "OpenQuestionInteraction",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '4e3dfa2e7b652bc956de5236c8bbb898';
export default node;
