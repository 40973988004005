/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MissionCompletedPopupAcknowledgeCompletionMutationVariables = {
    id: string;
    connection: string;
};
export type MissionCompletedPopupAcknowledgeCompletionMutationResponse = {
    readonly acknowledgeMissionCompletion: {
        readonly id: string;
    } | null;
};
export type MissionCompletedPopupAcknowledgeCompletionMutation = {
    readonly response: MissionCompletedPopupAcknowledgeCompletionMutationResponse;
    readonly variables: MissionCompletedPopupAcknowledgeCompletionMutationVariables;
};



/*
mutation MissionCompletedPopupAcknowledgeCompletionMutation(
  $id: ID!
) {
  acknowledgeMissionCompletion(id: $id) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "connection"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "MissionCompletedPopupAcknowledgeCompletionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Mission",
                    "kind": "LinkedField",
                    "name": "acknowledgeMissionCompletion",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "MissionCompletedPopupAcknowledgeCompletionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Mission",
                    "kind": "LinkedField",
                    "name": "acknowledgeMissionCompletion",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "deleteEdge",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "id",
                            "handleArgs": [
                                {
                                    "items": [
                                        {
                                            "kind": "Variable",
                                            "name": "connections.0",
                                            "variableName": "connection"
                                        }
                                    ],
                                    "kind": "ListValue",
                                    "name": "connections"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f7578cd65c2d2de936a4426616dfe3a3",
            "id": null,
            "metadata": {},
            "name": "MissionCompletedPopupAcknowledgeCompletionMutation",
            "operationKind": "mutation",
            "text": "mutation MissionCompletedPopupAcknowledgeCompletionMutation(\n  $id: ID!\n) {\n  acknowledgeMissionCompletion(id: $id) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5fc4f33cc8f57879e2d050ab6d5c5ab5';
export default node;
