import { observer } from 'mobx-react'
import { PropsWithChildren } from 'react'
import { classNames } from '../utils/classNames'

import styles from './Header.scss'

interface HeaderProps {
  duel?: boolean
  flat?: boolean
  wide?: boolean
  streamItem?: boolean
}

export const Header = observer(function Header(
  props: PropsWithChildren<HeaderProps>
) {
  return (
    <header className={styles.header}>
      <div
        className={classNames(styles.backdrop, {
          [styles.duel]: props.duel,
          [styles.flat]: props.flat,
          [styles.streamItem]: props.streamItem,
        })}
      >
        {props.duel ? (
          <svg
            viewBox='0 0 414.001 333.582'
            preserveAspectRatio='xMidYMid slice'
          >
            <g transform='translate(0 -73.415)'>
              <g transform='translate(176.957 0.008)'>
                <path
                  d='M157.535-22.541,97.949,227.4h59.586L97.549,477.339H334.592V-22.541H157.535Z'
                  transform='translate(-97.549 22.541)'
                />
              </g>
            </g>
          </svg>
        ) : (
          <div className={styles.graphic}>
            <div className={styles.gradient} />
          </div>
        )}
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div
            className={
              props.wide ? styles.headerContentWide : styles.headerContent
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </header>
  )
})
