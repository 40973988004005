import { ReactElement } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import placeholder from '../../../../../images/badge-empty.svg'
import { Badge_badge$key } from '../../generated/Badge_badge.graphql'
import { classNames } from '../../utils/classNames'
import { Tooltip } from '../common/Tooltip'

import styles from './Badge.scss'
import { Star } from './Star'

interface BadgeProps {
  badge: Badge_badge$key
  stars: number
}

export function Badge(props: BadgeProps): ReactElement {
  const badge = useFragment(
    graphql`
      fragment Badge_badge on Badge
      @argumentDefinitions(language: { type: "String!" }) {
        image {
          url
        }
        skill {
          name(language: $language)
        }
      }
    `,
    props.badge
  )

  return (
    <div
      className={classNames(styles.badge, {
        [styles.muted]: props.stars === 0,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.title}>{badge.skill.name}</div>
        <div className={styles.content}>
          <img
            className={styles.image}
            src={props.stars > 0 ? badge.image.url : placeholder}
          />

          {props.stars > 0 && (
            <div>
              <Star lit={props.stars >= 1} />
              <Star lit={props.stars >= 2} />
              <Star lit={props.stars >= 3} />
            </div>
          )}
        </div>
      </div>

      <Tooltip text={badge.skill.name} />
    </div>
  )
}
