import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { QuestionInteractionAnswerOption_answerOption$key } from '../../../generated/QuestionInteractionAnswerOption_answerOption.graphql'
import { classNames } from '../../../utils/classNames'
import styles from './QuestionInteractionAnswerOption.scss'

export const enum AnswerOptionFeedback {
  CORRECT,
  INCORRECT,
}

interface QuestionInteractionAnswerOptionProps {
  answerOption: QuestionInteractionAnswerOption_answerOption$key
  disabled: boolean
  feedback?: AnswerOptionFeedback
  onClick(): void
  selected: boolean
}

export function QuestionInteractionAnswerOption(
  props: QuestionInteractionAnswerOptionProps
): ReactElement {
  const answerOption = useFragment(
    graphql`
      fragment QuestionInteractionAnswerOption_answerOption on AnswerOption
      @argumentDefinitions(language: { type: "String!" }) {
        text(language: $language)
      }
    `,
    props.answerOption
  )

  return (
    <div
      className={classNames(styles.answer, {
        [styles.selected]: props.selected,
        [styles.disabled]: props.disabled,
      })}
      role='option'
      onClick={props.disabled ? undefined : props.onClick}
    >
      <div
        className={classNames(styles.circle, {
          [styles.feedback]: props.feedback !== undefined,
          [styles.correct]:
            props.feedback === AnswerOptionFeedback.CORRECT && props.selected,
        })}
      >
        {props.feedback === AnswerOptionFeedback.CORRECT && (
          <FontAwesomeIcon icon={faCheck} />
        )}
        {props.feedback === AnswerOptionFeedback.INCORRECT && (
          <FontAwesomeIcon icon={faTimes} />
        )}
      </div>

      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: answerOption.text }}
      />
    </div>
  )
}
