/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LinkInteraction_interaction = {
    readonly launchUrl: string;
    readonly linkText: string | null;
    readonly " $refType": "LinkInteraction_interaction";
};
export type LinkInteraction_interaction$data = LinkInteraction_interaction;
export type LinkInteraction_interaction$key = {
    readonly " $data"?: LinkInteraction_interaction$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LinkInteraction_interaction">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkInteraction_interaction",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "launchUrl",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "linkText",
            "storageKey": null
        }
    ],
    "type": "LinkInteraction",
    "abstractKey": null
} as any;
(node as any).hash = '6ea18c1e48d4f21d3d0f7ce45e337f1a';
export default node;
