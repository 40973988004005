/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MissionCompletedPopup_completedMission = {
    readonly banner: {
        readonly url: string;
    };
    readonly completedText: string;
    readonly nextMissionAvailableFrom: string | null;
    readonly id: string;
    readonly " $refType": "MissionCompletedPopup_completedMission";
};
export type MissionCompletedPopup_completedMission$data = MissionCompletedPopup_completedMission;
export type MissionCompletedPopup_completedMission$key = {
    readonly " $data"?: MissionCompletedPopup_completedMission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MissionCompletedPopup_completedMission">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MissionCompletedPopup_completedMission",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "banner",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "completedText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nextMissionAvailableFrom",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Mission",
    "abstractKey": null
} as any;
(node as any).hash = 'f32fa5313efd0221d5510c40571a8c49';
export default node;
