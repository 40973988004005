/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BadgesScreenQueryVariables = {
    language: string;
};
export type BadgesScreenQueryResponse = {
    readonly me: {
        readonly avatar: string | null;
        readonly level: number;
        readonly xp: number;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"BadgesList_badges">;
};
export type BadgesScreenQuery = {
    readonly response: BadgesScreenQueryResponse;
    readonly variables: BadgesScreenQueryVariables;
};



/*
query BadgesScreenQuery(
  $language: String!
) {
  me {
    avatar
    level
    xp
    id
  }
  ...BadgesList_badges_1S2oaG
}

fragment Badge_badge_1S2oaG on Badge {
  image {
    url
    id
  }
  skill {
    name(language: $language)
    id
  }
}

fragment BadgesList_badges_1S2oaG on Query {
  badges {
    id
    isReachable
    skill {
      name(language: $language)
      id
    }
    ...Badge_badge_1S2oaG
  }
  me {
    badges {
      id
      pivot {
        stars
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xp",
        "storageKey": null
    } as any, v4 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BadgesScreenQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "args": (v4 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "BadgesList_badges"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BadgesScreenQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badges",
                            "plural": true,
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserBadge",
                                    "kind": "LinkedField",
                                    "name": "pivot",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "stars",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": true,
                    "selections": [
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isReachable",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "skill",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UploadedFile",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b272dc6e76489b89a372d5875f096e6d",
            "id": null,
            "metadata": {},
            "name": "BadgesScreenQuery",
            "operationKind": "query",
            "text": "query BadgesScreenQuery(\n  $language: String!\n) {\n  me {\n    avatar\n    level\n    xp\n    id\n  }\n  ...BadgesList_badges_1S2oaG\n}\n\nfragment Badge_badge_1S2oaG on Badge {\n  image {\n    url\n    id\n  }\n  skill {\n    name(language: $language)\n    id\n  }\n}\n\nfragment BadgesList_badges_1S2oaG on Query {\n  badges {\n    id\n    isReachable\n    skill {\n      name(language: $language)\n      id\n    }\n    ...Badge_badge_1S2oaG\n  }\n  me {\n    badges {\n      id\n      pivot {\n        stars\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1857987eb23174f86b94568b0471b55f';
export default node;
