import { observer } from 'mobx-react'
import { ReactElement } from 'react'
import appLogo from '../../../../../images/logo/white.svg'
import { Header } from '../../containers/Header'

import styles from './AuthHeader.scss'

export const AuthHeader = observer(function AuthHeader(): ReactElement {
  return (
    <Header flat>
      <div className={styles.logoLoginContainer}>
        <img className={styles.logo} src={appLogo} />
      </div>
    </Header>
  )
})
