import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { AvatarCropPopupSubmitAvatarMutation } from '../../generated/AvatarCropPopupSubmitAvatarMutation.graphql'
import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import {
  AvatarCropPopup as AvatarCropPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'

import { PrimaryButton } from '../common/PrimaryButton'
import { SliderInput } from '../common/SliderInput'

import styles from './AvatarCropPopup.scss'

export function AvatarCropPopup(
  props: PopupProps<AvatarCropPopupProps>
): ReactElement {
  const { t } = useTranslation()
  const cropperContainer = useRef<HTMLDivElement>(null)
  const cropper = useRef<AvatarEditor>(null)

  const [cropperSize, setCropperSize] = useState(300)
  const [imageScalePercentage, setImageScalePercentage] = useState(100)

  const [uploadAvatar, uploadResult] =
    useMutation<AvatarCropPopupSubmitAvatarMutation>(
      graphql`
        mutation AvatarCropPopupSubmitAvatarMutation($file: Upload!) {
          changeAvatar(file: $file) {
            avatar
            id
          }
        }
      `,
      { variables: { file: null } }
    )

  // Don't extend the cropper outside of its container
  useEffect(() => {
    const listener = () => {
      if (cropperContainer.current) {
        setCropperSize(Math.min(cropperContainer.current.offsetWidth, 300))
      }
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  // And on the first render as well
  useLayoutEffect(() => {
    if (cropperContainer.current) {
      setCropperSize(Math.min(cropperContainer.current.offsetWidth, 300))
    }
  }, [])

  const onSubmit = useCallback((): void => {
    if (!cropper.current) {
      return
    }

    // Retrieve crop data
    cropper.current.getImageScaledToCanvas().toBlob((result) => {
      if (!result) {
        // Now what?
        return
      }

      // Create form data object
      const formData = new FormData()
      formData.append('avatar', result, props.filename)

      // Send to server
      uploadAvatar({ uploadables: { file: result } }).finally(
        props.closeCurrentPopup
      )
    }, props.mime)
  }, [props.closeCurrentPopup, props.filename, props.mime, uploadAvatar])
  const onSubmitKeyPressHandler = useEnterKeyHandler(onSubmit)

  const onScaleChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setImageScalePercentage(parseInt(event.target.value, 10))
    },
    []
  )

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('profile.profilePictureTitle')}</h1>
      <p>{t('profile.pictureEdit')}</p>

      <div className={styles.outerCropper} ref={cropperContainer}>
        <AvatarEditor
          borderRadius={cropperSize / 2}
          className={styles.cropper}
          color={[36, 41, 52, 0.25]}
          height={cropperSize}
          image={props.image}
          ref={cropper}
          scale={imageScalePercentage / 100}
          width={cropperSize}
        />
      </div>

      <div className={styles.scaleSlider}>
        <div className={classNames(styles.size, styles.small)}>
          <FontAwesomeIcon icon={faImage} />
        </div>
        <SliderInput
          min={100}
          max={500}
          value={imageScalePercentage}
          postfix='%'
          onChange={onScaleChanged}
        />
        <div className={classNames(styles.size, styles.large)}>
          <FontAwesomeIcon icon={faImage} />
        </div>
      </div>

      <PrimaryButton
        className={styles.button}
        disabled={uploadResult.loading}
        onClick={onSubmit}
        onKeyPress={onSubmitKeyPressHandler}
        tabIndex={0}
      >
        {t('profile.picture-submit')}
      </PrimaryButton>
    </div>
  )
}
