import { forwardRef, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../../utils/classNames'

import styles from './Stat.scss'

interface Segment {
  color: string

  percentage: number
}

interface BaseProps {
  caption?: string
  captionClassName?: string
  className?: string
  number: number
  numberLabel?: string
  scale?: number
}

type StatProps =
  | (BaseProps & Segment)
  | (BaseProps & {
      segments: Segment[]
    })

// Confusingly, these values need to different, otherwise the circle's stroke
// gets cut off outside the viewport.
const PROGRESS_CIRCLE_RADIUS = 94
const SVG_VIEWPORT = 108

export const Stat = forwardRef<HTMLDivElement, StatProps>(function Stat(
  props,
  ref
): ReactElement {
  const segments: Segment[] =
    'segments' in props
      ? props.segments
      : [
          {
            color: props.color,
            percentage: props.percentage,
          },
        ]

  // Inspired by https://codepen.io/JMChristensen/pen/AGbeEy
  const strokeDashArray = Math.PI * PROGRESS_CIRCLE_RADIUS

  const { i18n } = useTranslation()
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.language),
    [i18n.language]
  )

  let segmentSum = 0
  return (
    <div className={classNames(styles.container, props.className)} ref={ref}>
      <div
        className={styles.chartContainer}
        style={{ transform: `scale(${props.scale ?? 1})` }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={styles.chart}
          width={SVG_VIEWPORT}
          height={SVG_VIEWPORT}
          viewBox={`0 0 ${SVG_VIEWPORT} ${SVG_VIEWPORT}`}
        >
          <circle
            cx={SVG_VIEWPORT / 2}
            cy={SVG_VIEWPORT / 2}
            r={PROGRESS_CIRCLE_RADIUS / 2}
            className={styles.track}
          />

          {segments.map((segment, index) => {
            const rotation = 360 * (segmentSum / 100) - 90
            segmentSum += segment.percentage

            return (
              <circle
                key={index}
                cx={SVG_VIEWPORT / 2}
                cy={SVG_VIEWPORT / 2}
                r={PROGRESS_CIRCLE_RADIUS / 2}
                className={styles.progress}
                stroke={segment.color}
                strokeDasharray={strokeDashArray}
                strokeDashoffset={
                  ((100 - segment.percentage) / 100) * strokeDashArray
                }
                style={{
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            )
          })}
        </svg>

        <div className={styles.chartText}>
          <div
            className={classNames(styles.number, {
              [styles.numberWithLabel]: props.numberLabel,
            })}
          >
            {numberFormatter.format(props.number)}
          </div>

          {props.numberLabel && (
            <div className={styles.numberLabel}>{props.numberLabel}</div>
          )}
        </div>
      </div>

      {props.caption && (
        <div className={classNames(styles.caption, props.captionClassName)}>
          {props.caption}
        </div>
      )}
    </div>
  )
})
