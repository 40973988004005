import i18n from 'i18next'
import cookies from 'js-cookie'
import { action, makeObservable, observable } from 'mobx'
import { isLanguageSupported } from '../../locales/detectLanguage'

import { WebService } from '../services/WebService'

export class CommonStore {
  private static readonly INTENDED_URL_COOKIE_NAME = 'commonStore:intendedUrl'

  @observable
  public appInitialized: boolean

  @observable
  public challengesConnection: string

  @observable
  public language: string

  @observable
  public online: boolean

  @observable
  public postAuthRedirect?: string

  public webservice: WebService

  public constructor(webservice: WebService) {
    this.webservice = webservice
    this.webservice.commonStore = this

    this.appInitialized = false
    this.challengesConnection = ''
    this.language = 'en-US'
    this.online = true

    const intendedUrl = cookies.get(CommonStore.INTENDED_URL_COOKIE_NAME)
    if (intendedUrl) {
      this.postAuthRedirect = intendedUrl
    }

    makeObservable(this)
  }

  @action
  public setAppInitialized(flag: boolean): void {
    this.appInitialized = flag
  }

  @action
  public setChallengesConnection(value: string): void {
    this.challengesConnection = value
  }

  @action
  public async setLanguage(language: string): Promise<void> {
    if (!isLanguageSupported(language)) {
      return
    }

    this.language = language

    await i18n.changeLanguage(language)

    document.documentElement.setAttribute('dir', i18n.dir(language))
    document.documentElement.setAttribute('lang', language)
  }

  @action
  public setOnline(online: boolean): void {
    this.online = online
  }

  @action
  public setPostAuthRedirect(url?: string): void {
    this.postAuthRedirect = url

    if (url) {
      cookies.set(CommonStore.INTENDED_URL_COOKIE_NAME, url)
    } else {
      cookies.remove(CommonStore.INTENDED_URL_COOKIE_NAME)
    }
  }
}
