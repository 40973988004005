import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import bannerUrl from '../../../../../images/task-completed-banner.jpg'
import { BadgeEarnedPopupClaimMutation } from '../../generated/BadgeEarnedPopupClaimMutation.graphql'
import {
  BadgeEarnedPopup as BadgeEarnedPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'
import { MAX_STARS, Star } from '../badges/Star'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './BadgeEarnedPopup.scss'

export function BadgeEarnedPopup(
  props: PopupProps<BadgeEarnedPopupProps>
): ReactElement {
  const { closeCurrentPopup } = props
  const { t } = useTranslation()

  const badge = useFragment(
    graphql`
      fragment BadgeEarnedPopup_badge on Badge
      @argumentDefinitions(language: { type: "String!" }) {
        earnedText(language: $language)
        id
        image {
          url
        }
        pivot {
          lastClaimedStars
          stars
        }
      }
    `,
    props.badge
  )

  const [claim, claimResult] = useMutation<BadgeEarnedPopupClaimMutation>(
    graphql`
      mutation BadgeEarnedPopupClaimMutation($badgeId: ID!) {
        claimBadge(badgeId: $badgeId) {
          pivot {
            lastClaimedStars
          }
        }
      }
    `,
    {
      variables: { badgeId: badge.id },
    }
  )

  const [closing, setClosing] = useState(false)

  const closePopup = useCallback(() => {
    claim().then(() => {
      setClosing(true)
      closeCurrentPopup()
    })
  }, [claim, closeCurrentPopup])

  const stars = []
  for (let i = 0; i < MAX_STARS; ++i) {
    stars.push(
      <Star
        large
        lit={!!badge.pivot && i < badge.pivot?.stars}
        pop={
          !!badge.pivot &&
          badge.pivot.lastClaimedStars <= i &&
          badge.pivot.stars > i
        }
        popDelayIndex={i - (badge.pivot?.lastClaimedStars ?? 0)}
        key={i}
      />
    )
  }

  return (
    <>
      <img className={styles.banner} src={bannerUrl} aria-hidden />

      <div className={styles.content}>
        <div className={styles.left}>
          <img className={styles.badge} src={badge.image.url} />

          <div className={styles.stars}>{stars}</div>

          <PrimaryButton
            className={styles.button}
            disabled={claimResult.loading || closing}
            onClick={closePopup}
          >
            {t('common.Close')}
          </PrimaryButton>
        </div>

        <div className={styles.right}>
          <h2>{t('badges.earned.wellDone')}</h2>
          <div
            className={styles.feedback}
            dangerouslySetInnerHTML={{
              __html: badge.earnedText,
            }}
          />

          {claimResult.error && (
            <GraphQlError error={claimResult.error} retry={closePopup} />
          )}
        </div>
      </div>
    </>
  )
}
