import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import teamJoinedBanner from '../../../../../images/team-joined.jpg'
import { TeamJoinedPopupAcknowledgeMutation } from '../../generated/TeamJoinedPopupAcknowledgeMutation.graphql'

import {
  PopupProps,
  TeamJoinedPopup as TeamJoinedPopupProps,
} from '../../utils/hooks/usePopups'
import { TeamMember } from '../dashboard/TeamMember'
import { GraphQlError } from './GraphQlError'
import { PrimaryButton } from './PrimaryButton'

import styles from './TeamJoinedPopup.scss'

export function TeamJoinedPopup(
  props: PopupProps<TeamJoinedPopupProps>
): ReactElement {
  const { closeCurrentPopup } = props
  const [closing, setClosing] = useState(false)
  const { t } = useTranslation()
  const team = useFragment(
    graphql`
      fragment TeamJoinedPopup_team on Team {
        members {
          ... on Node {
            id
          }
          ...TeamMember_user
        }
      }
    `,
    props.team
  )

  const [acknowledge, acknowledgementResult] =
    useMutation<TeamJoinedPopupAcknowledgeMutation>(graphql`
      mutation TeamJoinedPopupAcknowledgeMutation {
        acknowledgeTeamJoined {
          user {
            stage
          }
        }
      }
    `)

  const onClose = useCallback(() => {
    acknowledge({ variables: {} }).then(() => {
      setClosing(true)
      closeCurrentPopup()
    })
  }, [acknowledge, closeCurrentPopup])

  return (
    <>
      <img src={teamJoinedBanner} />

      <div className={styles.content}>
        <h1>{t('team.joined')}</h1>
        <p>{t('team.welcome')}</p>

        <h2>Jouw team</h2>
        <ul className={styles.list}>
          {team.members.map((user) => (
            <TeamMember user={user} key={user.id} />
          ))}
        </ul>

        {acknowledgementResult.error && (
          <GraphQlError error={acknowledgementResult.error} />
        )}

        <PrimaryButton
          className={styles.button}
          disabled={closing || acknowledgementResult.loading}
          onClick={onClose}
        >
          {t('team.start')}
        </PrimaryButton>
      </div>
    </>
  )
}
