import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import { MissionCompletedPopupAcknowledgeCompletionMutation } from '../../generated/MissionCompletedPopupAcknowledgeCompletionMutation.graphql'
import {
  MissionCompletedPopup as MissionCompletedPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './MissionCompletedPopup.scss'

function formatRelativeTime(
  nextMissionAvailableFrom: Date,
  language: string
): string {
  const formatter = new Intl.RelativeTimeFormat(language, { numeric: 'auto' })

  const seconds =
    (nextMissionAvailableFrom.getTime() - new Date().getTime()) / 1000
  const hours = Math.ceil(seconds / 3600)
  const days = Math.round(hours / 24)

  if (days > 0 && days % 7 === 0) {
    return formatter.format(days / 7, 'weeks')
  }

  return formatter.format(days, 'days')
}

export function MissionCompletedPopup(
  props: PopupProps<MissionCompletedPopupProps>
): ReactElement {
  const { closeCurrentPopup, connection } = props
  const [closing, setClosing] = useState(false)
  const { i18n, t } = useTranslation()
  const completedMission = useFragment(
    graphql`
      fragment MissionCompletedPopup_completedMission on Mission
      @argumentDefinitions(language: { type: "String!" }) {
        banner {
          url
        }
        completedText(language: $language)
        nextMissionAvailableFrom
        id
      }
    `,
    props.completedMission
  )
  const currentMission = useFragment(
    graphql`
      fragment MissionCompletedPopup_currentMission on Mission {
        id
      }
    `,
    props.currentMission
  )

  const [acknowledge, acknowledgementResult] =
    useMutation<MissionCompletedPopupAcknowledgeCompletionMutation>(graphql`
      mutation MissionCompletedPopupAcknowledgeCompletionMutation(
        $id: ID!
        $connection: ID!
      ) {
        acknowledgeMissionCompletion(id: $id) {
          id @deleteEdge(connections: [$connection])
        }
      }
    `)
  const onClose = useCallback(() => {
    acknowledge({
      variables: { id: completedMission.id, connection },
    }).then(() => {
      closeCurrentPopup()
      setClosing(true)
    })
  }, [acknowledge, closeCurrentPopup, connection, completedMission.id])

  const newMissionNotStartedYet = currentMission.id === completedMission.id
  const nextMissionAvailableFrom =
    completedMission.nextMissionAvailableFrom === null
      ? null
      : new Date(completedMission.nextMissionAvailableFrom)

  return (
    <>
      <div
        className={styles.banner}
        style={{ backgroundImage: `url('${completedMission.banner.url}')` }}
      />

      <div className={styles.content}>
        <h1>{t('mission.completed')}</h1>

        <div
          dangerouslySetInnerHTML={{ __html: completedMission.completedText }}
        />

        {newMissionNotStartedYet &&
          (nextMissionAvailableFrom ? (
            <p>
              {t('mission.nextAvailableFrom', {
                availableFrom: formatRelativeTime(
                  nextMissionAvailableFrom,
                  i18n.language
                ),
              })}
            </p>
          ) : (
            <p>{t('mission.nextMissionUnknown')}</p>
          ))}

        {acknowledgementResult.error && (
          <GraphQlError error={acknowledgementResult.error} />
        )}

        <div className={styles.buttons}>
          <PrimaryButton
            disabled={acknowledgementResult.loading || closing}
            onClick={onClose}
          >
            {t('common.Close')}
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}
