import { ReactElement } from 'react'

import styles from './Tooltip.scss'

interface TooltipProps {
  text: string
}

export function Tooltip(props: TooltipProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.tooltip}>{props.text}</div>
    </div>
  )
}
