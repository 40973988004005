const translations = {
  assignments: {
    header: 'Assignments',
    availableAfterPreviousAssignments:
      'This assignment will become available after you complete the previous assignment(s) in this mission',
    availableFromDate: 'This assignment is available from {{date}}',
    stat: {
      completed: 'Completed assignments',
    },
    name_one: 'Assignment',
    name_other: 'Assignments',
  },
  task: {
    type: {
      label: 'Type',
      individual: {
        assignment: 'Individual assignment',
        challenge: 'Individual challenge',
      },
      team: {
        assignment: 'Team assignment',
        challenge: 'Team challenge',
      },
      assignment: 'Assignment',
      challenge: 'Challenge',
    },
    duration: 'Duration',
    skill: 'Skill',
    downloadFile: 'Download {{filename}}',
    completed: {
      title: 'Completed',
      collect: 'Collect',
      wellDone: 'Well done!',
    },
  },
  interactions: {
    link: {
      open: 'Open the link',
    },
    lti: {
      open: 'Open TrainTool',
    },
    outsideGame: {
      deleteFile: 'Delete uploaded file',
      submit: {
        assignment: 'Complete assignment',
        challenge: 'Complete challenge',
      },
      uploaded: 'Your file has been uploaded.',
      uploading: 'Your file is being uploaded.',
      uploadFile: 'Upload your file',
      yourFile: 'Your uploaded file.',
    },
    question: {
      instruction: {
        single: 'You may select one answer.',
        multiple: 'Multiple answers are allowed.',
      },
      submit: 'Submit',
      close: 'Continue',
    },
  },
  mission: {
    completed: 'Mission completed',
    nextAvailableFrom:
      "Your mission is accomplished. Well done! The next one starts {{availableFrom}}. There's still plenty to do until then. Play challenges to improve your skills!",
    nextMissionUnknown:
      "Good job. This was your last mission for now, but who knows: you might be called up again soon. The work doesn't stop here: Keep improving by playing challenges. The task force is counting on you!",
    stat: {
      completed: 'Completed missions',
    },
  },
  challenges: {
    title: 'Challenges',
    introText: [
      "There's always something to do!",
      'Did you notice in your mission that you can improve a skill? Or would you like to continue to develop yourself? Then complete the challenges that suit you. For every challenge you complete, you earn XP and unlock a new challenge.',
    ],
    new: 'New',
    empty:
      'Looks like you have completed all available challenges! No need to worry though, more challenges will be available when your next mission starts.',
    dashboard: {
      motivation: 'Keep developing yourself!',
      motivationDone: 'Well done!',
      openChallenges_zero: 'You have completed all challenges in this mission.',
      openChallenges_one: 'There is one challenge available for you:',
      openChallenges_other: 'There are several challenges available for you:',
      individual_one: '{{formatted}} individual challenge',
      individual_other: '{{formatted}} individual challenges',
      team_one: '{{formatted}} team challenge',
      team_other: '{{formatted}} team challenges',
      locked_one: 'There is one more challenge you can unlock.',
      locked_other: 'There are {{formatted}} more challenges you can unlock.',
    },
    stat: {
      completed: 'Completed challenges',
    },
    name_one: 'Challenge',
    name_other: 'Challenges',
  },
  taskforce: {
    title: 'Taskforce ranking',
    rank: '{{ord}} place',
    ord: {
      zero: '{{num}}th',
      one: '{{num}}st',
      two: '{{num}}nd',
      other: '{{num}}th',
    },
    name: {
      corruption: 'Taskforce corruption',
      embezzling: 'Taskforce embezzlement',
      fraud: 'Taskforce fraud',
      money_laundering: 'Taskforce money laundering',
    },
    welcome: {
      corruption:
        'It takes a network to beat a network! Welcome to taskforce Corruption. Together we fight against financial crimes. Collect as much XP as possible so that our taskforce rises to the top of the rankings!',
      embezzling:
        'It takes a network to beat a network! Welcome to taskforce Eclipse. Together we fight against financial crimes. Collect as much XP as possible so that our taskforce rises to the top of the rankings!',
      fraud:
        'It takes a network to beat a network! Welcome to taskforce Fraud. Together we fight against financial crimes. Collect as much XP as possible so that our taskforce rises to the top of the rankings!',
      money_laundering:
        'It takes a network to beat a network! Welcome to taskforce Money Laundering. Together we fight against financial crimes. Collect as much XP as possible so that our taskforce rises to the top of the rankings!',
    },
    start: "Let's get going",
  },
  team: {
    title: 'Team',
    joined: 'You have been added to a team!',
    welcome:
      'Together you are stronger! Your team is essential in completing the missions. Play, spar and strategize to fathom files and push the taskforce to the top of the rankings. What are you waiting for? Go to the Mission screen and start your first team assignment!',
    yours: 'Your team',
    start: "Let's get going",
  },
  badges: {
    intro:
      'Check out which skills you already possess, and which other badges you can still earn to improve yourself!',
    collected: 'Collected badges',
    empty: 'No badges are currently available.',
    lastEarnedBadge: 'Latest earned badge',
    noneEarnedYet: 'You did not earn any badges yet',
    motivator: 'Complete assignments and challenges to earn badges.',
    earned: {
      wellDone: 'Well done!',
    },
  },
  tutorial: {
    dashboard:
      'This is your dashboard. From here you can navigate to the different parts of the game and keep an overview of your missions, challenges and achievements.',
    taskForce:
      'Beat the best! With your team you are part of a large taskforce. Make yourself indispensable to the taskforce and the bank by challenging yourself and earning as many points as possible!',
    mission:
      'Here you can keep track of the progress of your current mission and the assignments that you still have to complete.',
    badges:
      'By completing assignments and challenges successfully, you earn badges for the skills you master. Here you can see where you have already proven yourself and where profits can still be made.',
    challenges:
      'By playing challenges you keep yourself sharp on skills and subject matter and you collect more points for the taskforce.',
    xp: 'For everything you do in the game you earn XP: experience points. With this you keep track of your individual progress and you contribute to the ranking of your taskforce.',
  },
  xp: {
    count: '{{formatted}} xp',
    earned: '{{count}} xp earned',
    label: 'xp',
    labelCaps: 'XP',
    progress: '{{from}} xp / {{to}} xp',
    stat: 'Points earned',
  },
  level: {
    num: 'Level {{count}}',
  },
  popup: {
    confirm: {
      delete: 'Delete',
      keep: 'Keep',
    },
    comeBackLater: {
      heading: 'Come back later',
      explanation1:
        'You have completed all items currently available to you. Please come back later to continue your onboarding.',
      explanation2: 'Your next update will be available on:',
      buttonText: 'Go back to my overview',
    },
    privacyPolicy: {
      heading: 'Privacy policy',
      explanation1: 'By using this app you agree to our privacy policy.',
      buttonText: 'Accept',
    },
  },
  header: {
    'welcome-message': 'Hi {{name}}!',
    welcome: 'Welcome.',
    welcomeBack: 'Welcome back.',
  },
  login: {
    succesful: 'Login successful.',
  },
  menu: {
    dashboard: 'Dashboard',
    mission: 'Mission',
    challenges: 'Challenges',
    badges: 'Badges',
    menu: 'Menu',
    profile: 'Profile',
    'help-and-settings': 'Help & Settings',
    select: 'Select',
    settings: 'Settings',
  },
  error: {
    internetdown: 'Lost connection to the internet!',
    maintenance: {
      title: 'Maintenance',
      subtitle:
        "The PowerApp is currently being upgraded. We're back in a couple of minutes.",
    },
    versionConflict: {
      title: 'New version',
      text: 'There is a new version available of this application. To ensure everyone is running the newest version we will update you to the newest version now.',
      refresh: 'Go to the newest version',
    },
    refreshButton: 'Click to refresh',
    internetup: 'Connected to the internet.',
    timeoutajax: 'The server request has timed out.',
    404: 'Server not found (HTTP 404).',
    500: 'Internal server error (HTTP 500).',
    unknown: 'Unknown error',
    tryReconnect: 'Attempting to reconnect...',
    alreadyAnswered: 'Item already answered.',
    serverError:
      'An error has occurred when contacting the server. Click to try again, or reload the app if the issue persists.',
    retry: 'Try again',
  },
  common: {
    CheckAnswer: 'Check answer',
    CheckAnswers: 'Check answers',
    Emailaddress: 'Email address',
    Name: 'Name',
    Checking: 'Checking...',
    Answer: 'Save my answer',
    Cancel: 'Cancel',
    Close: 'Close',
    browsePrevious: 'Previous',
    browseNext: 'Next',
    Accept: 'Accept',
    'Log out': 'Log out',
    Help: 'Help',
    Hello: 'Hi',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    CorrectNotChosen: 'Would have been correct',
    Round: 'Round',
    Version: 'Version',
    selected: 'Selected',
    'No Rating': 'Not rated yet',
    'No Rank': 'No ranking',
    NoUserSelection: 'No users found',
    monthNames: 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec',
    or: 'or',
    on: 'on',
    off: 'off',
    back: 'Back',
    minutes_one: '{{formatted}} minute',
    minutes_other: '{{formatted}} minutes',
    copyright: '© {{year}} ABN AMRO & Bright Alley',
    faq: 'Frequently asked questions',
  },
  profile: {
    onboarding: {
      header: 'Welcome to The Beat!',
      description:
        "Your efforts are essential to complete the missions. Quickly create your profile to gain access to the necessary files. Once you have passed the screening, you will gain access to your team and your taskforce. Don't wait any longer and take up the challenge!",
      start: 'Start!',
    },
    edit: {
      header: 'Edit your profile',
      description:
        'You can come back here at any time to adjust your name or profile picture.',
    },
    fields: {
      firstName: 'First name',
      lastName: 'Last name',
      function: 'Role',
      department: 'Department',
      avatar: 'Profile photo',
    },
    save: 'Save',
    title: 'Profile',
    subtitle: 'Adjust your profile here.',
    pictureEdit: 'View or edit your picture.',
    'file-submit': 'Upload',
    'picture-submit': 'Use this crop',
    pictureCancel: 'Cancel',
    pictureConfirmDelete:
      'Are you sure you want to delete your profile picture?',
    'picture-nofileselected': 'You did not select a file.',
    'picture-nofile': 'There is no file.',
    'picture-invalidsize':
      'This image is too large. The maximum width or height cannot exceed 2600 pixels.',
    'picture-notimage': 'The file you selected does not appear to be an image.',
    passwordEdit: 'Change password',
    'picture-saved': 'Your profile picture is saved!',
    passwordTitle: 'Change password',
    passwordText:
      'Change your password by entering your current password and 2 times your new password. The new password should be at least 6 characters long.',
    'password-old': 'Current password',
    'password-new': 'New password',
    'password-repeat': 'Confirm new password',
    'password-submit': 'Save new password',
    'password-oldpasswordmismatch': 'Your current password was incorrect',
    'password-newpasswordmismatch': 'The confirmed password did not match.',
    'password-passwordtooshort':
      'The new password should be at least 6 characters long.',
    'password-passwordsaved': 'Your password is saved!',
    profilePictureTitle: 'Profile picture',
    profilePictureText: 'This photo is visible for your colleagues.',
    generalDataTitle: 'Personal information',
    generalDataText: 'You cannot change all personal data by yourself.',
    profilePageTitle: 'Manage your profile.',
    noEditDataText: 'You cannot change this data.',
    profileLanguagePreference: 'Language preference',
    profileLanguageChoice: 'Language choice',
    notificationsTitle: 'Notifications',
    emailNotifications: 'Email notifications',
    pushNotifications: 'Push notifications',
  },
  auth: {
    header: {
      login: 'Log in',
      expired: 'Link expired',
      remind: "Can't remember your password?",
      create: 'Activate your account',
      reset: 'Update your password',
      welcome: 'Welcome!',
    },
    field: {
      email: 'Email address',
      password: 'Password',
      oldPassword: 'Current password',
      newPassword: 'New password',
      newRepeatPassword: 'Repeat new password',
      acceptPrivacyPolicy: 'I accept the <2>privacy policy</2>.',
      viewPrivacyPolicy: 'View the privacy policy.',
      rememberMe: 'Remember me',
    },
    action: {
      itemcompleted: "You've completed an item",
      updateprofile: 'Update profile',
      sendLink: 'Send me a password reset link',
      backToLogin: 'I want to log in',
      sendActivateLink: 'Activate account',
      alreadyActivatedLogin:
        'I have already activated the account and want to log in',
      savePassword: 'Save my new password',
      newPassword: 'Your new password',
      repeatNewPassword: 'Repeat your new password',
      newPasswordPolicyIntro: 'Password strength:',
    },
    selfActivate: {
      header: 'Activate your account',
    },
    logintext: 'Log in',
    remindText:
      "No problem. We'll send you an email so you can set a new password.",
    resetText: 'You can set your new password here.',
    createText:
      "Welcome! Please create a new password and then you're ready to log in.",
    passwords: {
      user: "Sorry, We can't find a user with that e-mail address.",
      token: 'This password reset token is invalid.',
      sent: 'An email with a reset-link has been sent.',
      reset: 'Password has been reset!',
      forgot: "I don't know my password",
      passwordExpiredTitle: 'Password expired',
      submitPasswordExpiredForm: 'Save new password',
      passwordExpiredText:
        'Your password is older than {{days}} days and is expired. You will need to set a new password using the form below, after that you can continue learning in the PowerApp',
      passwordCompleteSsoTitle: 'Complete your account',
      passwordCompleteSsoText:
        'Set a password for your {{app}} account. You will need to log in with your email address and this password when logging in via {{idpText}} is not available.',
    },
  },
}

export default translations

export type Translations = typeof translations
