/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskforceJoinedPopup_firstMission = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NewMissionPopup_mission">;
    readonly " $refType": "TaskforceJoinedPopup_firstMission";
};
export type TaskforceJoinedPopup_firstMission$data = TaskforceJoinedPopup_firstMission;
export type TaskforceJoinedPopup_firstMission$key = {
    readonly " $data"?: TaskforceJoinedPopup_firstMission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskforceJoinedPopup_firstMission">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskforceJoinedPopup_firstMission",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "FragmentSpread",
            "name": "NewMissionPopup_mission"
        }
    ],
    "type": "Mission",
    "abstractKey": null
} as any;
(node as any).hash = '273b3a6e21927fbbae441dbef34683b9';
export default node;
