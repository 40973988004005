import * as Sentry from '@sentry/browser'
import { observer } from 'mobx-react'
import {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { graphql, useMutation } from 'react-relay'
import { useRelayEnvironment } from 'relay-hooks'
import { TestAuthenticationScreenMutation } from '../../../generated/TestAuthenticationScreenMutation.graphql'
import { useStores } from '../../../stores'
import { AuthAlert } from '../../auth/AuthAlert'
import { PrimaryButton } from '../../common/PrimaryButton'
import styles from './TestAuthenticationScreen.scss'

export const TestAuthenticationScreen =
  process.env.NODE_ENV === 'production'
    ? function TestAuthenticationScreen(): ReactElement {
        useEffect(() => {
          location.href =
            'https://mylearning.abnamro.com/cdb-portal-2.0/?$cid=0QEt5PUI003GwjZELkG0032l.x#courses/FRM8nzL4R56gwJ6JR1uRZA/SEARCH/details'
        }, [])

        return <></>
      }
    : observer(function TestAuthenticationScreen(): ReactElement {
        const { authStore, commonStore } = useStores()

        const [email, setEmail] = useState('')
        const [firstName, setFirstName] = useState('')
        const [lastName, setLastName] = useState('')

        const [login] = useMutation<TestAuthenticationScreenMutation>(graphql`
          mutation TestAuthenticationScreenMutation(
            $email: String!
            $firstName: String!
            $lastName: String!
            $deviceName: String!
            $requestAccessToken: Boolean!
          ) {
            login: testLogin(
              email: $email
              firstName: $firstName
              lastName: $lastName
            ) {
              message
              result {
                accessToken(deviceName: $deviceName)
                  @include(if: $requestAccessToken)
                user {
                  __typename
                  id
                  email
                  language
                  stage
                  ...OnboardingPopup_user
                }
              }
            }
          }
        `)

        const relayEnvironment = useRelayEnvironment()
        const onLogin = useCallback(
          (event: FormEvent) => {
            event.preventDefault()

            login({
              variables: {
                email,
                firstName,
                lastName,
                deviceName: navigator.userAgent,
                requestAccessToken: !!process.env.WEBPACK_DEV_SERVER,
              },
              onCompleted(response) {
                if (!response.login.result) {
                  if (response.login.message) {
                    authStore.attemptLoginError(response.login.message)
                  }

                  return
                }

                authStore.attemptLoginSuccess(response.login.result.accessToken)

                commonStore.setLanguage(response.login.result.user.language)

                const id = response.login.result.user.id
                Sentry.setUser({
                  email: response.login.result.user.email,
                  id,
                })

                relayEnvironment.commitUpdate((store) => {
                  const record = store.get(id)
                  if (record) {
                    store.getRoot().setLinkedRecord(record, 'me')
                  }
                })
              },
            })
          },
          [
            authStore,
            commonStore,
            email,
            firstName,
            lastName,
            login,
            relayEnvironment,
          ]
        )

        return (
          <div className={styles.outer}>
            <div className={styles.container}>
              <form className={styles.formContainer} onSubmit={onLogin}>
                <AuthAlert />

                <div className={styles.formGroup}>
                  <input
                    autoComplete='email'
                    className={styles.formControl}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder='E-mailadres'
                    type='email'
                    value={email}
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    autoComplete='given-name'
                    className={styles.formControl}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    placeholder='Voornaam'
                    type='text'
                    value={firstName}
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    autoComplete='family-name'
                    className={styles.formControl}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    placeholder='Achternaam'
                    type='text'
                    value={lastName}
                  />
                </div>

                <PrimaryButton
                  component='input'
                  type='submit'
                  value='Inloggen'
                />
              </form>
            </div>
          </div>
        )
      })
