import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowDownToBracket'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons/faCloudUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment, useMutation } from 'relay-hooks'
import { InteractionOutsideGame_interaction$key } from '../../../generated/InteractionOutsideGame_interaction.graphql'
import { InteractionOutsideGame_task$key } from '../../../generated/InteractionOutsideGame_task.graphql'
import { InteractionOutsideGameDeleteUploadMutation } from '../../../generated/InteractionOutsideGameDeleteUploadMutation.graphql'
import { InteractionOutsideGameSubmitMutation } from '../../../generated/InteractionOutsideGameSubmitMutation.graphql'
import { InteractionOutsideGameUploadMutation } from '../../../generated/InteractionOutsideGameUploadMutation.graphql'
import { useStores } from '../../../stores'
import { classNames } from '../../../utils/classNames'
import { PopupProps } from '../../../utils/hooks/usePopups'
import { GraphQlError } from '../../common/GraphQlError'
import { PrimaryButton } from '../../common/PrimaryButton'
import { SecondaryButton } from '../../common/SecondaryButton'
import { Feedback } from './Feedback'

import styles from './InteractionOutsideGame.scss'

interface InteractionOutsideGameProps {
  interaction: InteractionOutsideGame_interaction$key
  task: InteractionOutsideGame_task$key
  taskType: 'assignment' | 'challenge'
}

export const InteractionOutsideGame = observer(function InteractionOutsideGame(
  props: PropsWithChildren<PopupProps & InteractionOutsideGameProps>
): ReactElement {
  const form = useRef<HTMLFormElement>(null)
  const { closeCurrentPopup, replaceCurrentPopup } = props
  const { commonStore } = useStores()

  const { i18n, t } = useTranslation()
  const task = useFragment(
    graphql`
      fragment InteractionOutsideGame_task on Task {
        id
        completion {
          completedAt
          id
          uploadedFile {
            fileName
            url
          }
        }
      }
    `,
    props.task
  )
  const interaction = useFragment(
    graphql`
      fragment InteractionOutsideGame_interaction on InteractionOutsideGame
      @argumentDefinitions(language: { type: "String!" }) {
        completeButtonText(language: $language)
        previousSubmissionResult {
          feedbackText(language: $language)
          feedbackUpload {
            ...Feedback_upload
          }
        }
        uploadAllowed
        uploadButtonText(language: $language)
      }
    `,
    props.interaction
  )
  const [fileName, setFileName] = useState(
    task.completion?.uploadedFile?.fileName ?? ''
  )

  const [upload, uploadResult] =
    useMutation<InteractionOutsideGameUploadMutation>(graphql`
      mutation InteractionOutsideGameUploadMutation(
        $taskId: ID!
        $file: Upload!
      ) {
        uploadFile(file: $file, taskId: $taskId) {
          completion {
            uploadedFile {
              fileName
              url
            }
          }
        }
      }
    `)
  const [deleteUpload] =
    useMutation<InteractionOutsideGameDeleteUploadMutation>(graphql`
      mutation InteractionOutsideGameDeleteUploadMutation($taskId: ID!)
      @raw_response_type {
        deleteUploadedFile(taskId: $taskId) {
          completion {
            uploadedFile {
              __typename
            }
          }
        }
      }
    `)
  const [submit, submitResult] =
    useMutation<InteractionOutsideGameSubmitMutation>(graphql`
      mutation InteractionOutsideGameSubmitMutation(
        $challengesConnection: ID!
        $language: String!
        $taskId: ID!
      ) {
        submitTask(id: $taskId) {
          challenge {
            id @deleteEdge(connections: [$challengesConnection])
          }
          feedbackText(language: $language)
          feedbackUpload {
            ...Feedback_upload
          }
          nextAssignment {
            ...Assignment_assignment @arguments(language: $language)
          }
          newChallenge
            @appendNode(
              connections: [$challengesConnection]
              edgeTypeName: "ChallengeEdge"
            ) {
            ...Challenge_challenge @arguments(language: $language)
          }
          task {
            completion {
              completedAt
            }
            ...InteractionOutsideGame_task
            ...TaskCompletedPopup_task @arguments(language: $language)
          }
        }
      }
    `)

  const onUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault()

      if (uploadResult.loading) {
        return
      }

      const file = event.currentTarget.files?.[0]
      if (!file) {
        return
      }

      setFileName(file.name)

      upload({
        variables: {
          taskId: task.id,
          file: null,
        },
        uploadables: {
          file,
        },
      }).catch(() => setFileName(''))
    },
    [task.id, upload, uploadResult.loading]
  )
  const onRemoveUpload = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      if (uploadResult.loading) {
        // No way at the moment to cancel an ongoing upload.
        return
      }

      const oldFileName = fileName
      setFileName('')

      deleteUpload({
        variables: {
          taskId: task.id,
        },
        optimisticResponse: {
          deleteUploadedFile: {
            id: task.id,
            completion: {
              id: task.completion?.id ?? '',
              uploadedFile: null,
            },
          },
        },
      }).catch(() => setFileName(oldFileName))
    },
    [deleteUpload, fileName, task.completion?.id, task.id, uploadResult.loading]
  )
  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault()

      if (submitResult.loading) {
        return
      }

      submit({
        variables: {
          challengesConnection: commonStore.challengesConnection,
          language: i18n.language,
          taskId: task.id,
        },
      }).then((response) => {
        if (
          response.submitTask.feedbackUpload ||
          response.submitTask.feedbackText
        ) {
          return
        }

        if (response.submitTask.task?.completion?.completedAt) {
          replaceCurrentPopup({
            popupKey: task.id,
            task: response.submitTask.task,
            type: 'task-completed',
          })
        } else {
          closeCurrentPopup()
        }
      })
    },
    [
      submitResult.loading,
      submit,
      commonStore.challengesConnection,
      i18n.language,
      task,
      replaceCurrentPopup,
      closeCurrentPopup,
    ]
  )

  const onClose = useCallback(() => {
    if (submitResult.data?.submitTask.task?.completion?.completedAt) {
      replaceCurrentPopup({
        popupKey: task.id,
        task: submitResult.data.submitTask.task,
        type: 'task-completed',
      })
    } else {
      closeCurrentPopup()
    }
  }, [
    closeCurrentPopup,
    replaceCurrentPopup,
    submitResult.data?.submitTask.task,
    task.id,
  ])

  const submitResultToShow =
    interaction.previousSubmissionResult ??
    submitResult.data?.submitTask ??
    null

  return (
    <form className={styles.form} onSubmit={onSubmit} ref={form}>
      {interaction.uploadAllowed && fileName && (
        <>
          <hr />

          {uploadResult.loading
            ? t('interactions.outsideGame.uploading')
            : task.completion?.completedAt
            ? t('interactions.outsideGame.yourFile')
            : t('interactions.outsideGame.uploaded')}

          <a
            href={
              task.completion?.completedAt && task.completion?.uploadedFile
                ? task.completion.uploadedFile.url
                : undefined
            }
            className={classNames(styles.fileBox, {
              [styles.completed]:
                task.completion?.completedAt && !!task.completion?.uploadedFile,
            })}
          >
            {!task.completion?.completedAt && (
              <SecondaryButton
                className={styles.deleteButton}
                onClick={onRemoveUpload}
              >
                &times;
              </SecondaryButton>
            )}

            <div className={styles.filename}>{fileName}</div>

            {!task.completion?.completedAt && (
              <div className={styles.progress}>
                <div
                  className={classNames(styles.progressBar, {
                    [styles.progressBarStriped]: uploadResult.loading,
                    [styles.progressBarAnimated]: uploadResult.loading,
                  })}
                  role='progressbar'
                  aria-busy={uploadResult.loading}
                  style={
                    task.completion?.uploadedFile
                      ? {
                          width: '100%',
                        }
                      : {}
                  }
                />
              </div>
            )}

            <div
              className={
                !task.completion?.completedAt
                  ? styles.icon
                  : styles.downloadButton
              }
            >
              <FontAwesomeIcon
                icon={
                  uploadResult.loading
                    ? faCloudUpload
                    : task.completion?.completedAt
                    ? faArrowDownToBracket
                    : faCheck
                }
              />
            </div>
          </a>
        </>
      )}

      {uploadResult.error && <GraphQlError error={uploadResult.error} />}

      {submitResult.error && (
        <GraphQlError
          error={submitResult.error}
          retry={() => form.current?.submit()}
        />
      )}

      {submitResultToShow && (
        <Feedback
          html={submitResultToShow.feedbackText}
          upload={submitResultToShow.feedbackUpload}
        />
      )}

      <div className={styles.buttons}>
        {(submitResult.data?.submitTask.feedbackText ||
          submitResult.data?.submitTask.feedbackUpload) && (
          <PrimaryButton onClick={onClose}>{t('common.Close')}</PrimaryButton>
        )}

        {!task.completion?.completedAt &&
          (task.completion?.uploadedFile || !interaction.uploadAllowed ? (
            <PrimaryButton
              className={styles.primaryButton}
              component='input'
              disabled={submitResult.loading}
              type='submit'
              value={
                interaction.completeButtonText ??
                (t(
                  'interactions.outsideGame.submit.' + props.taskType
                ) as string)
              }
            />
          ) : (
            <PrimaryButton
              className={styles.primaryButton}
              component='label'
              disabled={uploadResult.loading}
            >
              <input
                type='file'
                className={styles.fileInput}
                onChange={onUpload}
              />

              {interaction.uploadButtonText ??
                t('interactions.outsideGame.uploadFile')}
            </PrimaryButton>
          ))}

        {!submitResult.data?.submitTask.feedbackText &&
          !submitResult.data?.submitTask.feedbackUpload &&
          props.children}
      </div>
    </form>
  )
})
