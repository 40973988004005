import { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import forwardIcon from '../../../../../images/icons/forward-icon.svg'
import individualTaskIcon from '../../../../../images/icons/individual-task.svg'
import teamTaskIcon from '../../../../../images/icons/team-task.svg'
import { Challenge_challenge$key } from '../../generated/Challenge_challenge.graphql'
import { classNames } from '../../utils/classNames'
import { PopupContext } from '../common/Routing'
import { TertiaryButton } from '../common/TertiaryButton'
import styles from './Challenge.scss'

interface ChallengeProps {
  challenge: Challenge_challenge$key
}

export function Challenge(props: ChallengeProps): ReactElement {
  const { t } = useTranslation()
  const challenge = useFragment(
    graphql`
      fragment Challenge_challenge on Challenge
      @argumentDefinitions(language: { type: "String!" }) {
        id
        task {
          banner {
            url
          }
          completion {
            startedAt
          }
          id
          intro(language: $language)
          title(language: $language)
          type
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...TaskPopup_task @arguments(language: $language)
        }
      }
    `,
    props.challenge
  )

  const { openPopup } = useContext(PopupContext)
  const onChallengeClicked = useCallback((): void => {
    openPopup({
      type: 'task',
      taskType: 'challenge',
      task: challenge.task,
      popupKey: challenge.id,
    })
  }, [challenge.id, challenge.task, openPopup])

  return (
    <li
      className={classNames(styles.card, {
        [styles.unseen]: !challenge.task.completion?.startedAt,
      })}
      onClick={onChallengeClicked}
    >
      <div
        className={styles.cardImgLeft}
        style={{
          backgroundImage: `url('${challenge.task.banner.url}')`,
        }}
      >
        <div className={styles.taskTypeIcon}>
          <img
            src={
              challenge.task.type === 'INDIVIDUAL'
                ? individualTaskIcon
                : teamTaskIcon
            }
          />
        </div>

        {!challenge.task.completion?.startedAt && (
          <div className={styles.unseenIndicator}>{t('challenges.new')}</div>
        )}
      </div>

      <div className={styles.cardBody}>
        <h5 className={styles.cardTitle}>{challenge.task.title}</h5>
        <p
          className={styles.cardText}
          dangerouslySetInnerHTML={{
            __html: challenge.task.intro,
          }}
        />
      </div>

      <div className={styles.cardFooter}>
        <TertiaryButton className={styles.button} icon>
          <img src={forwardIcon} />
        </TertiaryButton>
      </div>
    </li>
  )
}
