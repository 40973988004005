import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from '../../App'
import { PopupProps } from '../../utils/hooks/usePopups'
import { PrimaryButton } from './PrimaryButton'

import styles from './PrivacyPolicyPopup.scss'

export function PrivacyPolicyPopup(props: PopupProps): ReactElement {
  const { t } = useTranslation()
  const { closeCurrentPopup } = props
  const environment = useEnvironment()

  const confirm = useCallback(() => closeCurrentPopup(), [closeCurrentPopup])

  return (
    <div className={styles.content}>
      <h1>{t('popup.privacyPolicy.heading')}</h1>
      <p>{t('popup.privacyPolicy.explanation1')}</p>
      <p>
        <a href={environment.privacyPolicyUrl} rel='noreferrer' target='_blank'>
          {t('auth.field.viewPrivacyPolicy')}
        </a>
      </p>
      <PrimaryButton className={styles.button} onClick={confirm}>
        {t('popup.privacyPolicy.buttonText')}
      </PrimaryButton>
    </div>
  )
}
