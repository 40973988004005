import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useFragment } from 'react-relay'
import { BadgesList_badges$key } from '../../generated/BadgesList_badges.graphql'
import { Badge } from './Badge'
import styles from './BadgesList.scss'

interface BadgesListProps {
  badges: BadgesList_badges$key
}

export function BadgesList(props: BadgesListProps): ReactElement {
  const { t, i18n } = useTranslation()
  const { badges: unsorted, me } = useFragment(
    graphql`
      fragment BadgesList_badges on Query
      @argumentDefinitions(language: { type: "String!" }) {
        badges {
          id
          isReachable
          skill {
            name(language: $language)
          }
          ...Badge_badge @arguments(language: $language)
        }
        me {
          badges {
            id
            pivot {
              stars
            }
          }
        }
      }
    `,
    props.badges
  )

  const badges = [...unsorted]
  badges.sort((a, b) => {
    if (a.isReachable && !b.isReachable) {
      return -1
    }
    if (!a.isReachable && b.isReachable) {
      return 1
    }
    const aStars =
      me?.badges.find((badge) => badge.id === a.id)?.pivot?.stars ?? 0
    const bStars =
      me?.badges.find((badge) => badge.id === b.id)?.pivot?.stars ?? 0
    if (aStars !== bStars) {
      return bStars - aStars
    }

    return a.skill.name.localeCompare(b.skill.name, i18n.language)
  })

  return badges.length === 0 ? (
    <div className={styles.empty}>
      <p>{t('badges.empty')}</p>
    </div>
  ) : (
    <ol className={styles.list}>
      {badges.map((badge) => (
        <li className={styles.badge} key={badge.id}>
          <Badge
            badge={badge}
            stars={me?.badges.find((b) => b.id === badge.id)?.pivot?.stars ?? 0}
          />
        </li>
      ))}
    </ol>
  )
}
