import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useCallback, useContext } from 'react'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import defaultAvatar from '../../../../../images/default-avatar.png'
import { EditableAvatar_user$key } from '../../generated/EditableAvatar_user.graphql'
import { classNames } from '../../utils/classNames'
import { toDataUrl } from '../../utils/toDataUrl'
import { avatarUrl } from '../../utils/url'

import styles from './EditableAvatar.scss'
import { PopupContext } from './Routing'

interface EditableAvatarProps {
  user: EditableAvatar_user$key | null
}

export function EditableAvatar(props: EditableAvatarProps) {
  const { openPopup } = useContext(PopupContext)

  const user = useFragment(
    graphql`
      fragment EditableAvatar_user on AuthenticatedUser {
        avatar
      }
    `,
    props.user
  )

  const onAvatarChosen = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (
        !event.currentTarget.files ||
        event.currentTarget.files.length === 0
      ) {
        return
      }

      const file = event.currentTarget.files[0]
      toDataUrl(file).then((image) =>
        openPopup(
          {
            image,
            mime: file.type,
            filename: file.name,
            type: 'crop-avatar',
          },
          true
        )
      )
    },
    [openPopup]
  )

  return (
    <label
      className={classNames(styles.avatar, {
        [styles.loading]: props.user === null,
        [styles.defaultAvatar]: user?.avatar === null,
      })}
    >
      {user && (
        <img src={user.avatar ? avatarUrl(user.avatar) : defaultAvatar} />
      )}

      <div className={styles.penAttachment}>
        <FontAwesomeIcon icon={faPen} />
        <input
          type='file'
          id='avatar'
          accept='image/*'
          onChange={onAvatarChosen}
        />
      </div>
    </label>
  )
}
