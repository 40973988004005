/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ChallengeStats_stats = {
    readonly missions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly allChallenges: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
                readonly completedChallenges: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
            };
        }>;
    } | null;
    readonly " $refType": "ChallengeStats_stats";
};
export type ChallengeStats_stats$data = ChallengeStats_stats;
export type ChallengeStats_stats$key = {
    readonly " $data"?: ChallengeStats_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChallengeStats_stats">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    } as any, v1 = [
        {
            "kind": "Literal",
            "name": "filter",
            "value": "ALL"
        } as any,
        (v0 /*: any*/)
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "ChallengeStats_stats",
        "selections": [
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "MissionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Mission",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": "allChallenges",
                                        "args": (v1 /*: any*/),
                                        "concreteType": "ChallengeConnection",
                                        "kind": "LinkedField",
                                        "name": "challenges",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": "completedChallenges",
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": "COMPLETED"
                                            },
                                            (v0 /*: any*/)
                                        ],
                                        "concreteType": "ChallengeConnection",
                                        "kind": "LinkedField",
                                        "name": "challenges",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '5731d244a1cc20c26c98e2de3e7c1c15';
export default node;
