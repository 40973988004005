import {
  DependencyList,
  EffectCallback,
  useEffect,
  useLayoutEffect,
} from 'react'

export function useResizeAwareLayoutEffect(
  callback: EffectCallback,
  deps?: DependencyList
) {
  useEffect(() => {
    window.addEventListener('resize', callback)

    return () => window.removeEventListener('resize', callback)
  }, [callback])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useLayoutEffect(callback, deps)
}
