/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Mission_mission = {
    readonly currentMissions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly description: string;
                readonly phase: {
                    readonly id: string;
                } | null;
                readonly title: string;
            };
        }>;
    } | null;
    readonly phases: ReadonlyArray<{
        readonly acronym: string;
        readonly id: string;
        readonly title: string;
    }>;
    readonly " $refType": "Mission_mission";
};
export type Mission_mission$data = Mission_mission;
export type Mission_mission$key = {
    readonly " $data"?: Mission_mission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Mission_mission">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": (v0 /*: any*/),
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "Mission_mission",
        "selections": [
            {
                "alias": "currentMissions",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filter",
                        "value": "CURRENT"
                    },
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                    }
                ],
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "MissionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Mission",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": (v0 /*: any*/),
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Phase",
                                        "kind": "LinkedField",
                                        "name": "phase",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "missions(filter:\"CURRENT\",first:1)"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Phase",
                "kind": "LinkedField",
                "name": "phases",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": (v0 /*: any*/),
                        "kind": "ScalarField",
                        "name": "acronym",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    (v2 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '5daf856aa1673bacb7e7380ea71218af';
export default node;
