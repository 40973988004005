import { ReactElement } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { TeamMember_user$key } from '../../generated/TeamMember_user.graphql'
import { avatarUrl } from '../../utils/url'

import styles from './TeamMember.scss'

interface TeamMemberProps {
  user: TeamMember_user$key
}

export function TeamMember(props: TeamMemberProps): ReactElement {
  const user = useFragment(
    graphql`
      fragment TeamMember_user on User {
        avatar
        fullName
        function
      }
    `,
    props.user
  )

  return (
    <li className={styles.pane}>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <img src={avatarUrl(user.avatar!)} className={styles.avatar} />

      <div className={styles.description}>
        <div>{user.fullName}</div>
        <div className={styles.role}>{user.function}</div>
      </div>
    </li>
  )
}
