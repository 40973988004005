/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InteractionOutsideGame_interaction = {
    readonly completeButtonText: string | null;
    readonly previousSubmissionResult: {
        readonly feedbackText: string;
        readonly feedbackUpload: {
            readonly " $fragmentRefs": FragmentRefs<"Feedback_upload">;
        } | null;
    } | null;
    readonly uploadAllowed: boolean;
    readonly uploadButtonText: string | null;
    readonly " $refType": "InteractionOutsideGame_interaction";
};
export type InteractionOutsideGame_interaction$data = InteractionOutsideGame_interaction;
export type InteractionOutsideGame_interaction$key = {
    readonly " $data"?: InteractionOutsideGame_interaction$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InteractionOutsideGame_interaction">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "InteractionOutsideGame_interaction",
        "selections": [
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "kind": "ScalarField",
                "name": "completeButtonText",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "SubmitTaskResult",
                "kind": "LinkedField",
                "name": "previousSubmissionResult",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": (v0 /*: any*/),
                        "kind": "ScalarField",
                        "name": "feedbackText",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadedFile",
                        "kind": "LinkedField",
                        "name": "feedbackUpload",
                        "plural": false,
                        "selections": [
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "Feedback_upload"
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uploadAllowed",
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "kind": "ScalarField",
                "name": "uploadButtonText",
                "storageKey": null
            }
        ],
        "type": "InteractionOutsideGame",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd32d9659adfb22b1e2e8654cf461275a';
export default node;
