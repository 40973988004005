/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TeamMember_user = {
    readonly avatar: string | null;
    readonly fullName: string;
    readonly function: string;
    readonly " $refType": "TeamMember_user";
};
export type TeamMember_user$data = TeamMember_user;
export type TeamMember_user$key = {
    readonly " $data"?: TeamMember_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TeamMember_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamMember_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "function",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": "__isUser"
} as any;
(node as any).hash = 'a853a02b5151a0907129dd245e8e64a0';
export default node;
