/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskforceType = "CORRUPTION" | "EMBEZZLING" | "FRAUD" | "MONEY_LAUNDERING";
export type Taskforce_taskforce = {
    readonly lastSnapshotRank: number;
    readonly taskforceType: TaskforceType;
    readonly totalXp: number;
    readonly " $refType": "Taskforce_taskforce";
};
export type Taskforce_taskforce$data = Taskforce_taskforce;
export type Taskforce_taskforce$key = {
    readonly " $data"?: Taskforce_taskforce$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Taskforce_taskforce">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Taskforce_taskforce",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSnapshotRank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskforceType",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalXp",
            "storageKey": null
        }
    ],
    "type": "Taskforce",
    "abstractKey": null
} as any;
(node as any).hash = '2244a6046753d3c1db95459b33d6beea';
export default node;
