import {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { useFragment } from 'react-relay'
import { Link } from 'react-router-dom'
import { graphql } from 'relay-runtime'

import forwardIcon from '../../../../../images/icons/forward-icon.svg'
import { Mission_mission$key } from '../../generated/Mission_mission.graphql'
import { classNames } from '../../utils/classNames'
import { TertiaryButton } from '../common/TertiaryButton'
import { Tooltip } from '../common/Tooltip'

import styles from './Mission.scss'

interface MissionProps {
  className?: string
  mission: Mission_mission$key
}

export const Mission = forwardRef<HTMLDivElement, MissionProps>(
  function Mission(props, ref): ReactElement {
    const { currentMissions: mission, phases } = useFragment(
      graphql`
        fragment Mission_mission on Query
        @argumentDefinitions(language: { type: "String!" }) {
          currentMissions: missions(filter: CURRENT, first: 1) {
            edges {
              node {
                description(language: $language)
                phase {
                  id
                }
                title(language: $language)
              }
            }
          }
          phases {
            acronym(language: $language)
            id
            title(language: $language)
          }
        }
      `,
      props.mission
    )

    const overviewElement = useRef<HTMLDivElement>(null)
    const lastWidth = useRef(0)
    const lastHeight = useRef(0)
    const phase0 = useRef<HTMLDivElement>(null)
    const phase1 = useRef<HTMLDivElement>(null)
    const phase2 = useRef<HTMLDivElement>(null)

    const positionPhases = useCallback(() => {
      if (!overviewElement.current) {
        return
      }

      const height = overviewElement.current.offsetHeight
      const width = overviewElement.current.offsetWidth

      if (height === lastHeight.current && width === lastWidth.current) {
        return
      }

      // Something changed. We need to calculate the position of each phase element.
      // This... will suck. I'm sorry. Hexagons are a bitch. Hexagons in combination
      // with dynamic sizing are a super bitch.
      const IDEAL_WIDTH = 565
      const IDEAL_HEIGHT = 270

      // Define the positions of each element (its center point) in relation to the
      // background image, in percentage, x and y respectively.
      const OFFSET_X_0 = 0.325
      const OFFSET_Y_02 = 0.51
      const positions = [
        [OFFSET_X_0, OFFSET_Y_02],
        [0.5, 0.72],
        [1 - OFFSET_X_0, OFFSET_Y_02],
      ]

      // The background image gets cut off on the left and right side.
      const actualImageWidth = (height / IDEAL_HEIGHT) * IDEAL_WIDTH
      const imageOffset = (actualImageWidth - width) / 2

      for (const [index, phaseRef] of [phase0, phase1, phase2].entries()) {
        if (phaseRef.current) {
          const [x, y, scale] = [
            actualImageWidth * positions[index][0] - imageOffset,
            height * positions[index][1],
            actualImageWidth / IDEAL_WIDTH,
          ]

          phaseRef.current.style.left = x + 'px'
          phaseRef.current.style.top = y + 'px'
          // Important to translate first and THEN scale!
          phaseRef.current.style.transform = `translate(-50%, -50%) scale(${scale})`
        }
      }

      lastWidth.current = width
      lastHeight.current = height
    }, [])

    // Adjust placing of phase elements on every render, and when the screen is
    // resized.
    useLayoutEffect(positionPhases)
    useEffect(() => {
      window.addEventListener('resize', positionPhases)

      return () => {
        window.removeEventListener('resize', positionPhases)
      }
    }, [positionPhases])

    if (!mission?.edges?.[0]) {
      return <></>
    }

    const currentMission = mission.edges[0].node
    const phaseRefs = [phase0, phase1, phase2]

    return (
      <section
        className={classNames(styles.mission, props.className)}
        ref={ref}
      >
        <h2>Huidige missie</h2>

        <div className={styles.container}>
          <div className={styles.row}>
            <div className={classNames(styles.col, styles.col4)}>
              <Link className={styles.description} to='/missions/current'>
                <h3>{currentMission.title}</h3>
                <p>{currentMission.description}</p>

                <TertiaryButton className={styles.button} icon>
                  <img src={forwardIcon} />
                </TertiaryButton>
              </Link>
            </div>

            <div className={classNames(styles.col, styles.col8)}>
              <div className={styles.overview} ref={overviewElement}>
                {phases.map((phase, index) => (
                  <div
                    className={classNames(styles.phase, {
                      [styles.current]: currentMission.phase?.id === phase.id,
                    })}
                    key={phase.id}
                    ref={phaseRefs[index]}
                  >
                    <Tooltip text={phase.title} />

                    {phase.acronym}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
)
