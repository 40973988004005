/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskType = "INDIVIDUAL" | "TEAM";
export type ChallengesOverview_stats = {
    readonly currentMissions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly availableChallenges: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly task: {
                                readonly type: TaskType;
                            };
                        };
                    }>;
                } | null;
                readonly uncompletedChallenges: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
                readonly totalChallenges: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
            };
        }>;
    } | null;
    readonly " $refType": "ChallengesOverview_stats";
};
export type ChallengesOverview_stats$data = ChallengesOverview_stats;
export type ChallengesOverview_stats$key = {
    readonly " $data"?: ChallengesOverview_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChallengesOverview_stats">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "kind": "Literal",
        "name": "first",
        "value": 1
    } as any, v1 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    } as any, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = [
        (v2 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "ChallengesOverview_stats",
        "selections": [
            {
                "alias": "currentMissions",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filter",
                        "value": "CURRENT"
                    },
                    (v0 /*: any*/)
                ],
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "MissionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Mission",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": "availableChallenges",
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": "OPEN"
                                            },
                                            (v1 /*: any*/)
                                        ],
                                        "concreteType": "ChallengeConnection",
                                        "kind": "LinkedField",
                                        "name": "challenges",
                                        "plural": false,
                                        "selections": [
                                            (v2 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ChallengeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Challenge",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Task",
                                                                "kind": "LinkedField",
                                                                "name": "task",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "type",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": "uncompletedChallenges",
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": "UNCOMPLETED"
                                            },
                                            (v1 /*: any*/)
                                        ],
                                        "concreteType": "ChallengeConnection",
                                        "kind": "LinkedField",
                                        "name": "challenges",
                                        "plural": false,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": "totalChallenges",
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": "ALL"
                                            },
                                            (v0 /*: any*/)
                                        ],
                                        "concreteType": "ChallengeConnection",
                                        "kind": "LinkedField",
                                        "name": "challenges",
                                        "plural": false,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": "challenges(filter:\"ALL\",first:1)"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "missions(filter:\"CURRENT\",first:1)"
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '97935dbda273fcc20318d464e5c7d13f';
export default node;
