import { ReactElement, useCallback } from 'react'
import {
  ConfirmYesNoPopup as ConfirmYesNoPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'

import styles from './ConfirmYesNoPopup.scss'

import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'

export function ConfirmYesNoPopup(
  props: PopupProps<ConfirmYesNoPopupProps>
): ReactElement {
  const { closeCurrentPopup } = props

  const confirm = useCallback((): void => {
    props.onConfirm().then(() => {
      ;(props.onClose ?? closeCurrentPopup)()
    })
  }, [closeCurrentPopup, props])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{props.title}</h1>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      <PrimaryButton className={styles.button} onClick={confirm}>
        {props.confirmButtonText}
      </PrimaryButton>
      <SecondaryButton
        className={styles.button}
        onClick={props.onClose ?? closeCurrentPopup}
      >
        {props.cancelButtonText}
      </SecondaryButton>
    </div>
  )
}
