const cache: Record<number, number> = {}

function formula(
  level: number,
  exponentBase: number,
  xpForFirstLevel: number
): number {
  return xpForFirstLevel * Math.pow(exponentBase, level - 2)
}

export function totalXpForLevel(
  level: number,
  exponentBase: number,
  xpForFirstLevel: number
): number {
  if (level <= 1) {
    return 0
  }

  if (level in cache) {
    return cache[level]
  }

  let sum = 0
  for (let l = 2; l <= level; ++l) {
    sum += formula(l, exponentBase, xpForFirstLevel)
  }

  return (cache[level] = Math.round(sum))
}
