import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { useEnvironment } from '../../App'
import { BadgesScreenQuery } from '../../generated/BadgesScreenQuery.graphql'
import { classNames } from '../../utils/classNames'
import { totalXpForLevel } from '../../utils/levels'
import { avatarUrl } from '../../utils/url'
import { BadgesList } from '../badges/BadgesList'
import { GraphQlError } from '../common/GraphQlError'
import { HeaderEditableAvatar } from '../common/HeaderEditableAvatar'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { NavigationBar } from '../common/NavigationBar'
import { ProgressBar } from '../common/ProgressBar'

import styles from './BadgesScreen.scss'

export function BadgesScreen(): ReactElement {
  const { xp } = useEnvironment()
  const { t, i18n } = useTranslation()
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.language),
    [i18n.language]
  )

  const badges = useQuery<BadgesScreenQuery>(
    graphql`
      query BadgesScreenQuery($language: String!) {
        me {
          avatar
          level
          xp
        }
        ...BadgesList_badges @arguments(language: $language)
      }
    `,
    { language: i18n.language },
    { fetchPolicy: 'store-and-network' }
  )

  const xpForThisLevel = totalXpForLevel(
    badges.data?.me?.level ?? 1,
    xp.exponentBase,
    xp.xpForFirstLevel
  )
  const xpForNextLevel = totalXpForLevel(
    (badges.data?.me?.level ?? 1) + 1,
    xp.exponentBase,
    xp.xpForFirstLevel
  )

  return (
    <div className={styles.outer}>
      <div className={classNames(styles.container, styles.pageContainer)}>
        <HeaderEditableAvatar />

        <div className={styles.row}>
          <NavigationBar />
        </div>

        <section className={classNames(styles.row, styles.summaryContainer)}>
          <div className={styles.summary}>
            <div className={styles.avatar}>
              {badges.data?.me?.avatar && (
                <img src={avatarUrl(badges.data?.me?.avatar)} />
              )}
            </div>
            <div className={styles.stats}>
              <h1>{t('menu.badges')}</h1>
              <p>{t('badges.intro')}</p>

              <h3>{t('level.num', { count: badges.data?.me?.level })}</h3>
              {badges.data?.me?.xp !== undefined && (
                <ProgressBar
                  hideText
                  percentage={
                    ((badges.data.me.xp - xpForThisLevel) /
                      (xpForNextLevel - xpForThisLevel)) *
                    100
                  }
                />
              )}

              <span className={styles.xp}>
                {t('xp.progress', {
                  from: numberFormatter.format(badges.data?.me?.xp ?? 0),
                  to: numberFormatter.format(xpForNextLevel),
                })}
              </span>
            </div>
          </div>
        </section>
      </div>

      <section className={styles.badges}>
        <div className={styles.container}>
          <h2 className={classNames(styles.col, styles.listHeader)}>
            {t('badges.collected')}
          </h2>

          {badges.isLoading && <LoadingIndicator />}
          {badges.error && (
            <GraphQlError error={badges.error} retry={badges.retry} />
          )}

          {badges.data && <BadgesList badges={badges.data} />}
        </div>
      </section>
    </div>
  )
}
