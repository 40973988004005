import { MouseEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import logo from '../../../../../images/logo/white.svg'
import { OnboardingPopupCompleteMutation } from '../../generated/OnboardingPopupCompleteMutation.graphql'
import { useOnChangeCallback } from '../../utils/hooks/useOnChangeCallback'
import {
  OnboardingPopup as OnboardingPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'
import { useRelayMirroredState } from '../../utils/hooks/useRelayMirroredState'
import { EditableAvatar } from '../common/EditableAvatar'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './OnboardingPopup.scss'

export function OnboardingPopup(
  props: PopupProps<OnboardingPopupProps>
): ReactElement {
  const { i18n, t } = useTranslation()
  const { replaceCurrentPopup } = props

  const user = useFragment(
    graphql`
      fragment OnboardingPopup_user on AuthenticatedUser {
        avatar
        firstName
        function
        id
        lastName
        departments {
          name
        }
        ...EditableAvatar_user
      }
    `,
    props.user
  )

  const [firstName, setFirstName] = useRelayMirroredState(
    user.firstName,
    user.id,
    'firstName'
  )
  const [lastName, setLastName] = useRelayMirroredState(
    user.lastName,
    user.id,
    'lastName'
  )
  const [userFunction, setFunction] = useRelayMirroredState(
    user.function,
    user.id,
    'function'
  )

  const onFirstNameChanged = useOnChangeCallback(setFirstName)
  const onLastNameChanged = useOnChangeCallback(setLastName)
  const onFunctionChanged = useOnChangeCallback(setFunction)

  const department = user.departments[0]?.name

  const [completeOnboarding, completeOnboardingResult] =
    useMutation<OnboardingPopupCompleteMutation>(graphql`
      mutation OnboardingPopupCompleteMutation(
        $firstName: String!
        $lastName: String!
        $function: String!
        $language: String!
      ) {
        completeOnboarding(
          firstName: $firstName
          lastName: $lastName
          function: $function
        ) {
          firstMission {
            ...TaskforceJoinedPopup_firstMission @arguments(language: $language)
          }
          taskforce {
            ...TaskforceJoinedPopup_taskforce
          }
          user {
            stage
          }
        }
      }
    `)

  const submit = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      completeOnboarding({
        variables: {
          firstName,
          lastName,
          function: userFunction,
          language: i18n.language,
        },
      }).then(({ completeOnboarding: { firstMission, taskforce } }) => {
        replaceCurrentPopup({
          firstMission,
          taskforce,
          type: 'taskforce-joined',
        })
      })
    },
    [
      completeOnboarding,
      firstName,
      i18n.language,
      lastName,
      replaceCurrentPopup,
      userFunction,
    ]
  )

  return (
    <div className={styles.popup}>
      <div className={styles.banner}>
        <img src={logo} className={styles.logo} />
      </div>
      <div className={styles.content}>
        <h1>{t('profile.onboarding.header')}</h1>
        <p>{t('profile.onboarding.description')}</p>

        <h2>Profiel</h2>
        <form className={styles.form}>
          <div className={styles.column}>
            <label className={styles.label} htmlFor='#firstName'>
              {t('profile.fields.firstName')}
            </label>
            <input
              type='text'
              id='firstName'
              className={styles.textField}
              onChange={onFirstNameChanged}
              value={firstName}
            />

            <label className={styles.label} htmlFor='#lastName'>
              {t('profile.fields.lastName')}
            </label>
            <input
              type='text'
              id='lastName'
              className={styles.textField}
              onChange={onLastNameChanged}
              value={lastName}
            />

            <label className={styles.label} htmlFor='#function'>
              {t('profile.fields.function')}
            </label>
            <input
              type='text'
              id='function'
              className={styles.textField}
              onChange={onFunctionChanged}
              value={userFunction}
            />

            {department && (
              <>
                <label className={styles.label}>
                  {t('profile.fields.department')}
                </label>
                <input
                  type='text'
                  className={styles.textField}
                  readOnly
                  value={department}
                />
              </>
            )}
          </div>

          <div className={styles.column}>
            <label className={styles.label} htmlFor='avatar'>
              {t('profile.fields.avatar')}
            </label>

            <div className={styles.avatarContainer}>
              <EditableAvatar user={user} />
            </div>

            <PrimaryButton
              className={styles.button}
              disabled={
                // Don't allow submitting if one of the required things is missing
                !firstName ||
                !lastName ||
                !userFunction ||
                !user.avatar ||
                // Or we're already submitting
                completeOnboardingResult.loading ||
                // Or we're done (the popup would be currently closing if that's the case)
                completeOnboardingResult.data !== null
              }
              onClick={submit}
            >
              {t('profile.onboarding.start')}
            </PrimaryButton>

            {completeOnboardingResult.error && (
              <GraphQlError error={completeOnboardingResult.error} />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
