import { RouteComponentProps } from 'react-router-dom'
import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthHeader } from '../../auth/AuthHeader'
import { Page } from '../../../containers/Page'
import { PrimaryButton } from '../../common/PrimaryButton'
import { PasswordInput } from '../../common/PasswordInput'
import { useStores } from '../../../stores'

import styles from './AuthenticationPasswordExpiredPageProps.scss'
import { useMutation } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { AuthenticationPasswordExpiredPageSetPasswordMutation } from '../../../generated/AuthenticationPasswordExpiredPageSetPasswordMutation.graphql'
import { Severity } from '../../../stores/authStore'

interface AuthenticationPasswordExpiredPageProps {
  days: string
  email: string
  token: string
}

export function AuthenticationPasswordExpiredPage(
  props: RouteComponentProps<AuthenticationPasswordExpiredPageProps>
): ReactElement {
  const { authStore } = useStores()
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordsValidated, setPasswordsValidated] = useState(false)

  const [submitNewPassword] =
    useMutation<AuthenticationPasswordExpiredPageSetPasswordMutation>(graphql`
      mutation AuthenticationPasswordExpiredPageSetPasswordMutation(
        $email: String!
        $token: String!
        $password: String!
        $passwordConfirmation: String!
      ) {
        setPassword(
          email: $email
          token: $token
          password: $password
          passwordConfirmation: $passwordConfirmation
        ) {
          message
          success
        }
      }
    `)

  const onSubmit = useCallback((): void => {
    authStore.setAuthLoading(true)

    submitNewPassword({
      variables: {
        email: props.match.params.email,
        token: props.match.params.token,
        password,
        passwordConfirmation,
      },
    })
      .then(({ setPassword: response }) => {
        authStore.setAuthMessage(
          response.message,
          response.success ? Severity.SUCCESS : Severity.ERROR
        )
        if (response.success) {
          props.history.push('/login')
        }
      })
      .finally(() => authStore.setAuthLoading(false))
  }, [
    authStore,
    password,
    passwordConfirmation,
    props.history,
    props.match.params.email,
    props.match.params.token,
    submitNewPassword,
  ])

  return (
    <>
      <AuthHeader />

      <Page narrow>
        <div className={styles.formGroup + ' ' + styles.formHeader}>
          <div className={styles.head}>
            {t('auth.passwords.passwordExpiredTitle')}
          </div>
          <div className={styles.sub}>
            {t('auth.passwords.passwordExpiredText', {
              days: props.match.params.days,
            })}
          </div>
        </div>

        <form
          action='#'
          method='post'
          className={styles.formContainer}
          onSubmit={onSubmit}
        >
          <PasswordInput
            email={props.match.params.email}
            token={props.match.params.token}
            onPasswordChanged={setPassword}
            onPasswordConfirmationChanged={setPasswordConfirmation}
            onPasswordsValidated={setPasswordsValidated}
          />

          <div className={styles.formFooter}>
            <div>
              <PrimaryButton
                disabled={authStore.authLoading || !passwordsValidated}
                type='submit'
                className={styles.primaryButton}
              >
                {t('auth.action.savePassword')}
              </PrimaryButton>
            </div>
          </div>
        </form>
      </Page>
    </>
  )
}
