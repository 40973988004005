/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskforcesOverview_user = {
    readonly id: string;
    readonly " $refType": "TaskforcesOverview_user";
};
export type TaskforcesOverview_user$data = TaskforcesOverview_user;
export type TaskforcesOverview_user$key = {
    readonly " $data"?: TaskforcesOverview_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskforcesOverview_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskforcesOverview_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Taskforce",
    "abstractKey": null
} as any;
(node as any).hash = '408870a601fd43afdf60c80b59e6686f';
export default node;
