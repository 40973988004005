/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Interaction_task = {
    readonly " $fragmentRefs": FragmentRefs<"InteractionOutsideGame_task" | "LinkInteraction_task" | "LtiInteraction_task" | "OpenQuestionInteraction_task">;
    readonly " $refType": "Interaction_task";
};
export type Interaction_task$data = Interaction_task;
export type Interaction_task$key = {
    readonly " $data"?: Interaction_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Interaction_task">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Interaction_task",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InteractionOutsideGame_task"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LinkInteraction_task"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LtiInteraction_task"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OpenQuestionInteraction_task"
        }
    ],
    "type": "Task",
    "abstractKey": null
} as any;
(node as any).hash = '08b6a1643c0079186f40b13e9ba3f989';
export default node;
