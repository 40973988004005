/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type XpStats_stats = {
    readonly me: {
        readonly xp: number;
    } | null;
    readonly stats: {
        readonly totalXp: number;
    };
    readonly " $refType": "XpStats_stats";
};
export type XpStats_stats$data = XpStats_stats;
export type XpStats_stats$key = {
    readonly " $data"?: XpStats_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"XpStats_stats">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "XpStats_stats",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "xp",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Stats",
            "kind": "LinkedField",
            "name": "stats",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalXp",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any;
(node as any).hash = '3e784325be43059cca2d50888334e59b';
export default node;
