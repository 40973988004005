import { ReactElement } from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Feedback_upload$key } from '../../../generated/Feedback_upload.graphql'
import { TaskDescriptionMedia } from '../TaskDescriptionMedia'

import styles from './Feedback.scss'

interface FeedbackProps {
  html: string | null
  upload: Feedback_upload$key | null
}

export function Feedback(props: FeedbackProps): ReactElement {
  const upload = useFragment(
    graphql`
      fragment Feedback_upload on UploadedFile {
        ...TaskDescriptionMedia_upload
      }
    `,
    props.upload
  )

  if (!props.html && upload === null) {
    return <></>
  }

  return (
    <>
      <hr className={styles.separator} />

      {upload && <TaskDescriptionMedia upload={upload} />}

      {props.html && (
        <div
          className={styles.feedback}
          dangerouslySetInnerHTML={{
            __html: props.html,
          }}
        />
      )}
    </>
  )
}
