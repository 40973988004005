import { CSSProperties, ReactElement, useCallback, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { TaskDescriptionMedia_upload$key } from '../../generated/TaskDescriptionMedia_upload.graphql'
import { KalturaEmbed } from './KalturaEmbed'

import styles from './TaskDescriptionMedia.scss'

interface TaskDescriptionMediaProps {
  upload: TaskDescriptionMedia_upload$key | null
}

export function TaskDescriptionMedia(
  props: TaskDescriptionMediaProps
): ReactElement {
  const upload = useFragment(
    graphql`
      fragment TaskDescriptionMedia_upload on UploadedFile {
        mime
        url
      }
    `,
    props.upload
  )

  const [showLightBox, setShowLightbox] = useState(false)

  const openLightbox = useCallback(() => {
    setShowLightbox(true)
  }, [])
  const closeLightbox = useCallback(() => {
    setShowLightbox(false)
  }, [])

  if (!upload) {
    return <></>
  }

  if (upload.mime.startsWith('image/')) {
    return (
      <>
        <div className={styles.image}>
          <img src={upload.url} onClick={openLightbox} />
        </div>

        {showLightBox && (
          <Lightbox
            mainSrc={upload.url}
            onCloseRequest={closeLightbox}
            reactModalStyle={{
              overlay: {
                zIndex: 2000,
              } as CSSProperties,
            }}
          />
        )}
      </>
    )
  }

  if (upload.mime === 'video/kaltura') {
    return <KalturaEmbed url={upload.url} />
  }

  return <></>
}
