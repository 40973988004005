import { forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { TeamOverview_team$key } from '../../generated/TeamOverview_team.graphql'
import { classNames } from '../../utils/classNames'
import { TeamMember } from './TeamMember'

import styles from './TeamOverview.scss'

interface TeamOverviewProps {
  className?: string
  team: TeamOverview_team$key
}

export const TeamOverview = forwardRef<HTMLDivElement, TeamOverviewProps>(
  function TeamOverview(props, ref): ReactElement {
    const { t } = useTranslation()
    const team = useFragment(
      graphql`
        fragment TeamOverview_team on Team {
          members {
            ... on Node {
              id
            }
            ...TeamMember_user
          }
        }
      `,
      props.team
    )

    return (
      <div className={classNames(props.className, styles.overview)} ref={ref}>
        <h2>{t('team.title')}</h2>

        <ol className={styles.list}>
          {team.members.map((member) => (
            <TeamMember key={member.id} user={member} />
          ))}
        </ol>
      </div>
    )
  }
)
