/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TaskType = "INDIVIDUAL" | "TEAM";
export type TaskPopup_task = {
    readonly banner: {
        readonly url: string;
    };
    readonly completion: {
        readonly completedAt: string | null;
        readonly startedAt: string | null;
        readonly xpClaimedAt: string | null;
    } | null;
    readonly description: string;
    readonly descriptionUpload: {
        readonly " $fragmentRefs": FragmentRefs<"TaskDescriptionMedia_upload">;
    } | null;
    readonly download: {
        readonly fileName: string;
        readonly url: string;
    } | null;
    readonly durationMinutes: number;
    readonly interactable: {
        readonly " $fragmentRefs": FragmentRefs<"Interaction_interactable">;
    };
    readonly intro: string;
    readonly id: string;
    readonly skill: {
        readonly name: string;
    };
    readonly skillXp: number;
    readonly title: string;
    readonly type: TaskType;
    readonly " $fragmentRefs": FragmentRefs<"Interaction_task">;
    readonly " $refType": "TaskPopup_task";
};
export type TaskPopup_task$data = TaskPopup_task;
export type TaskPopup_task$key = {
    readonly " $data"?: TaskPopup_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskPopup_task">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "TaskPopup_task",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "banner",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "TaskCompletion",
                "kind": "LinkedField",
                "name": "completion",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedAt",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xpClaimedAt",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "descriptionUpload",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TaskDescriptionMedia_upload"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "download",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileName",
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationMinutes",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "interactable",
                "plural": false,
                "selections": [
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "Interaction_interactable"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "kind": "ScalarField",
                "name": "intro",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "skill",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "skillXp",
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Interaction_task"
            }
        ],
        "type": "Task",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '9b34f092b874ecd260c489a5eaa7bc3a';
export default node;
