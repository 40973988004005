import {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  ReactElement,
  Ref,
} from 'react'

import styles from './SliderInput.scss'

interface SliderInputProps {
  disabled?: boolean
  label?: string
  min: number
  max: number
  onChange?(event: ChangeEvent<HTMLInputElement>): void
  prefix?: string
  postfix?: string
  step?: number
  value: number
}

export const SliderInput = forwardRef(function SliderInput(
  props: SliderInputProps,
  ref: Ref<HTMLInputElement>
): ReactElement {
  const currentValueStyle = {
    '--current-value': `${
      ((props.value - props.min) / (props.max - props.min)) * 100
    }%`,
    '--thumb-margin': `${
      (((props.value - props.min) / (props.max - props.min)) * 100) / 5 - 10
    }px`,
  } as CSSProperties

  return (
    <input
      className={styles.slider}
      disabled={props.disabled}
      max={props.max}
      min={props.min}
      ref={ref}
      role='slider'
      step={props.step || 1}
      style={currentValueStyle}
      tabIndex={0}
      type='range'
      value={props.value}
      aria-label={props.label}
      aria-valuemax={props.max}
      aria-valuemin={props.min}
      aria-valuenow={props.value}
      aria-valuetext={`${props.prefix || ''}${props.value}${
        props.postfix || ''
      }`}
      onChange={props.onChange}
    />
  )
})
