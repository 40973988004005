/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionInteraction_interaction = {
    readonly answerOptions: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"QuestionInteractionAnswerOption_answerOption">;
    }>;
    readonly id: string;
    readonly multipleAnswersAllowed: boolean;
    readonly previousSubmissionResult: {
        readonly correctAnswers: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly feedbackText: string;
        readonly selectedAnswers: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly taskSubmission: {
            readonly feedbackUpload: {
                readonly " $fragmentRefs": FragmentRefs<"Feedback_upload">;
            } | null;
        };
    } | null;
    readonly text: string;
    readonly " $refType": "QuestionInteraction_interaction";
};
export type QuestionInteraction_interaction$data = QuestionInteraction_interaction;
export type QuestionInteraction_interaction$key = {
    readonly " $data"?: QuestionInteraction_interaction$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"QuestionInteraction_interaction">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v2 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "QuestionInteraction_interaction",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AnswerOption",
                "kind": "LinkedField",
                "name": "answerOptions",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "QuestionInteractionAnswerOption_answerOption"
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "multipleAnswersAllowed",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "QuestionSubmitResult",
                "kind": "LinkedField",
                "name": "previousSubmissionResult",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "correctAnswers",
                        "plural": true,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "kind": "ScalarField",
                        "name": "feedbackText",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "selectedAnswers",
                        "plural": true,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubmitTaskResult",
                        "kind": "LinkedField",
                        "name": "taskSubmission",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "UploadedFile",
                                "kind": "LinkedField",
                                "name": "feedbackUpload",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "Feedback_upload"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            }
        ],
        "type": "QuestionInteraction",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'b63aacd359ff3365f102adf0c2348180';
export default node;
