import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import checkmarkIcon from '../../../../../images/icons/checkmark-icon.svg'
import forwardIcon from '../../../../../images/icons/forward-icon.svg'
import { Assignment_assignment$key } from '../../generated/Assignment_assignment.graphql'
import { classNames } from '../../utils/classNames'
import { PopupContext } from '../common/Routing'
import { TertiaryButton } from '../common/TertiaryButton'
import { Tooltip } from '../common/Tooltip'
import styles from './Assignment.scss'

interface AssignmentProps {
  assignment: Assignment_assignment$key
}

export function Assignment(props: AssignmentProps): ReactElement {
  const { i18n, t } = useTranslation()
  const assignment = useFragment(
    graphql`
      fragment Assignment_assignment on Assignment
      @argumentDefinitions(language: { type: "String!" }) {
        id
        availableFrom
        isAvailable
        task {
          banner {
            url
          }
          completion {
            completedAt
          }
          id
          intro(language: $language)
          title(language: $language)
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...TaskPopup_task @arguments(language: $language)
        }
      }
    `,
    props.assignment
  )
  const availableFrom =
    assignment.availableFrom === null
      ? null
      : new Date(Date.parse(assignment.availableFrom))

  const { openPopup } = useContext(PopupContext)
  const onAssignmentClicked = useCallback((): void => {
    if (assignment.isAvailable) {
      openPopup({
        type: 'task',
        taskType: 'assignment',
        task: assignment.task,
        popupKey: assignment.id,
      })
    }
  }, [assignment.id, assignment.isAvailable, assignment.task, openPopup])

  return (
    <div
      className={classNames(styles.card, {
        [styles.openable]: assignment.isAvailable,
        [styles.notYetAvailable]: !assignment.isAvailable,
        [styles.completed]: !!assignment.task.completion?.completedAt,
      })}
      onClick={onAssignmentClicked}
    >
      <div
        className={styles.cardImgTop}
        style={{
          backgroundImage: `url('${assignment.task.banner.url}')`,
        }}
      />

      <div className={styles.cardBody}>
        <h5 className={styles.cardTitle}>{assignment.task.title}</h5>
        <p
          className={styles.cardText}
          dangerouslySetInnerHTML={{
            __html: assignment.task.intro,
          }}
        />

        {assignment.task.completion?.completedAt ? (
          <TertiaryButton className={styles.button} icon>
            <img src={checkmarkIcon} />
          </TertiaryButton>
        ) : assignment.isAvailable ? (
          <TertiaryButton className={styles.button} icon>
            <img src={forwardIcon} />
          </TertiaryButton>
        ) : availableFrom ? (
          <TertiaryButton
            className={classNames(styles.button, styles.buttonWithText)}
          >
            <Tooltip
              text={t('assignments.availableFromDate', {
                date: availableFrom.toLocaleDateString(i18n.language, {
                  dateStyle: 'long',
                }),
              })}
            />

            <FontAwesomeIcon icon={faLock} className={styles.iconWithMargin} />

            {availableFrom.toLocaleDateString(i18n.language, {
              month: 'short',
              day: 'numeric',
            })}
          </TertiaryButton>
        ) : (
          <TertiaryButton className={styles.button} icon>
            <Tooltip
              text={t('assignments.availableAfterPreviousAssignments')}
            />

            <FontAwesomeIcon icon={faLock} />
          </TertiaryButton>
        )}
      </div>
    </div>
  )
}
