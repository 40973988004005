import { observer } from 'mobx-react'
import { PropsWithChildren, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { QuestionInteraction_interaction$key } from '../../../generated/QuestionInteraction_interaction.graphql'
import { QuestionInteractionSubmitMutation } from '../../../generated/QuestionInteractionSubmitMutation.graphql'
import { useStores } from '../../../stores'
import { PopupProps } from '../../../utils/hooks/usePopups'
import { useSelectedAnswers } from '../../../utils/hooks/useSelectedAnswers'
import { PrimaryButton } from '../../common/PrimaryButton'
import { Feedback } from './Feedback'

import styles from './QuestionInteraction.scss'
import {
  AnswerOptionFeedback,
  QuestionInteractionAnswerOption,
} from './QuestionInteractionAnswerOption'

interface QuestionInteractionProps {
  interaction: QuestionInteraction_interaction$key
}

export const QuestionInteraction = observer(function QuestionInteraction(
  props: PropsWithChildren<PopupProps & QuestionInteractionProps>
): ReactElement {
  const { commonStore } = useStores()
  const { i18n, t } = useTranslation()
  const { closeCurrentPopup, replaceCurrentPopup } = props

  const question = useFragment(
    graphql`
      fragment QuestionInteraction_interaction on QuestionInteraction
      @argumentDefinitions(language: { type: "String!" }) {
        answerOptions {
          id
          ...QuestionInteractionAnswerOption_answerOption
            @arguments(language: $language)
        }
        id
        multipleAnswersAllowed
        previousSubmissionResult {
          correctAnswers {
            id
          }
          feedbackText(language: $language)
          selectedAnswers {
            id
          }
          taskSubmission {
            feedbackUpload {
              ...Feedback_upload
            }
          }
        }
        text(language: $language)
      }
    `,
    props.interaction
  )

  const [selectedAnswers, addAnswer, removeAnswer, setSelectedAnswers] =
    useSelectedAnswers(
      question.previousSubmissionResult?.selectedAnswers.map(
        (answer) => answer.id
      )
    )

  const [submit, submitResult] =
    useMutation<QuestionInteractionSubmitMutation>(graphql`
      mutation QuestionInteractionSubmitMutation(
        $answers: [ID!]!
        $challengesConnection: ID!
        $id: ID!
        $language: String!
      ) {
        submitQuestion(id: $id, answerOptions: $answers) {
          correctAnswers {
            id
          }
          feedbackText(language: $language)
          taskSubmission {
            challenge {
              id @deleteEdge(connections: [$challengesConnection])
            }
            feedbackUpload {
              ...Feedback_upload
            }
            nextAssignment {
              ...Assignment_assignment @arguments(language: $language)
            }
            newChallenge
              @appendNode(
                connections: [$challengesConnection]
                edgeTypeName: "ChallengeEdge"
              ) {
              ...Challenge_challenge @arguments(language: $language)
            }
            task {
              completion {
                completedAt
              }
              ...TaskCompletedPopup_task @arguments(language: $language)
            }
          }
        }
      }
    `)

  const onAnswerClicked = useCallback(
    (answerId: string): void => {
      if (question.multipleAnswersAllowed) {
        if (selectedAnswers.includes(answerId)) {
          removeAnswer(answerId)
        } else {
          addAnswer(answerId)
        }
      } else {
        setSelectedAnswers([answerId])
      }
    },
    [
      addAnswer,
      question.multipleAnswersAllowed,
      removeAnswer,
      selectedAnswers,
      setSelectedAnswers,
    ]
  )

  const onSubmit = useCallback(() => {
    submit({
      variables: {
        answers: [...selectedAnswers],
        challengesConnection: commonStore.challengesConnection,
        id: question.id,
        language: i18n.language,
      },
    })
  }, [
    commonStore.challengesConnection,
    i18n.language,
    question.id,
    selectedAnswers,
    submit,
  ])
  const onClose = useCallback(() => {
    if (!submitResult.data?.submitQuestion) {
      return
    }

    if (submitResult.data.submitQuestion.taskSubmission.task) {
      replaceCurrentPopup({
        task: submitResult.data.submitQuestion.taskSubmission.task,
        type: 'task-completed',
      })
    } else {
      closeCurrentPopup()
    }
  }, [
    closeCurrentPopup,
    replaceCurrentPopup,
    submitResult.data?.submitQuestion,
  ])

  const submitResultToShow =
    question.previousSubmissionResult ?? submitResult.data?.submitQuestion

  return (
    <div>
      <div
        className={styles.question}
        dangerouslySetInnerHTML={{ __html: question.text }}
      />
      <div className={styles.instruction}>
        {t(
          question.multipleAnswersAllowed
            ? 'interactions.question.instruction.multiple'
            : 'interactions.question.instruction.single'
        )}
      </div>

      <div className={styles.answers}>
        {question.answerOptions.map((answer) => {
          let feedback = undefined
          const selected = selectedAnswers.includes(answer.id)
          if (submitResultToShow) {
            feedback = submitResultToShow.correctAnswers.some(
              (correctAnswer) => correctAnswer.id === answer.id
            )
              ? AnswerOptionFeedback.CORRECT
              : AnswerOptionFeedback.INCORRECT
          }

          return (
            <QuestionInteractionAnswerOption
              answerOption={answer}
              disabled={
                submitResult.loading ||
                submitResult.data !== null ||
                question.previousSubmissionResult !== null
              }
              feedback={feedback}
              key={answer.id}
              onClick={() => onAnswerClicked(answer.id)}
              selected={selected}
            />
          )
        })}
      </div>

      {submitResultToShow ? (
        <>
          <Feedback
            html={submitResultToShow.feedbackText}
            upload={submitResultToShow.taskSubmission.feedbackUpload}
          />

          <div className={styles.buttons}>
            {submitResult.data ? (
              <PrimaryButton onClick={onClose}>
                {t('interactions.question.close')}
              </PrimaryButton>
            ) : (
              props.children
            )}
          </div>
        </>
      ) : (
        <div className={styles.buttons}>
          <PrimaryButton
            className={styles.primaryButton}
            disabled={
              (question.multipleAnswersAllowed
                ? selectedAnswers.length <= 1
                : selectedAnswers.length === 0) || submitResult.loading
            }
            onClick={onSubmit}
          >
            {t('interactions.question.submit')}
          </PrimaryButton>

          {props.children}
        </div>
      )}
    </div>
  )
})
