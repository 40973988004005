/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ChallengeListPaginationQuery from "./ChallengeListPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type ChallengeList_challenges = {
    readonly challenges: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"Challenge_challenge">;
            };
        }>;
    } | null;
    readonly id: string;
    readonly " $refType": "ChallengeList_challenges";
};
export type ChallengeList_challenges$data = ChallengeList_challenges;
export type ChallengeList_challenges$key = {
    readonly " $data"?: ChallengeList_challenges$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChallengeList_challenges">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "challenges"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": ChallengeListPaginationQuery,
                "identifierField": "id"
            }
        },
        "name": "ChallengeList_challenges",
        "selections": [
            {
                "alias": "challenges",
                "args": null,
                "concreteType": "ChallengeConnection",
                "kind": "LinkedField",
                "name": "__ChallengeList_challenges_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChallengeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Challenge",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": [
                                            {
                                                "kind": "Variable",
                                                "name": "language",
                                                "variableName": "language"
                                            }
                                        ],
                                        "kind": "FragmentSpread",
                                        "name": "Challenge_challenge"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "kind": "ClientExtension",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                            }
                        ]
                    }
                ],
                "storageKey": null
            },
            (v1 /*: any*/)
        ],
        "type": "Mission",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '681feec03671f442ea2a4aba29b8a3e6';
export default node;
