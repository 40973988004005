import {
  CacheConfig,
  Environment,
  GraphQLResponse,
  Network,
  PayloadError,
  QueryResponseCache,
  RecordSource,
  RequestParameters,
  Store,
  UploadableMap,
  Variables,
} from 'relay-runtime'
import { WebService } from './services/WebService'

import { stores } from './stores'
import { handleThrottling } from './utils/handleThrottling'

type PayloadErrorWithExtensions = PayloadError & {
  extensions?: {
    category?: string
  }
}

const fifteenMinutes = 15 * 60 * 1000
const cache = new QueryResponseCache({ size: 250, ttl: fifteenMinutes })

function fetchQuery(
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
): Promise<GraphQLResponse> {
  const queryId = operation.text
  const isMutation = operation.operationKind === 'mutation'
  const isQuery = operation.operationKind === 'query'
  const forceFetch = cacheConfig && cacheConfig.force

  // Try to get data from cache on queries
  if (queryId) {
    const fromCache = cache.get(queryId, variables)
    if (isQuery && fromCache !== null && !forceFetch) {
      return Promise.resolve(fromCache)
    }
  }

  const token = stores.authStore.token

  let data:
    | FormData
    | { query: string | null | undefined; variables: Variables }
  const headers: Record<string, string> = {
    'X-Apollo-Client-Name': 'web-' + location.hostname,
    'X-Apollo-Client-Version': `${process.env.VERSION} (${process.env.SENTRY_RELEASE})`,
    'Accept-Language': stores.commonStore.language,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  }
  if (!uploadables) {
    data = { query: operation.text, variables }
    headers['Content-Type'] = 'application/json'
  } else {
    data = new FormData()
    data.append(
      'operations',
      JSON.stringify({ query: operation.text, variables })
    )

    // Mapping from files to where in variables they should end up.
    const mapping: Record<string, string[]> = {}
    let fileIndex = 0
    for (const key in uploadables) {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        mapping[fileIndex] = ['variables.' + key]

        data.append(String(fileIndex), uploadables[key])

        ++fileIndex
      }
    }

    data.append('map', JSON.stringify(mapping))
  }

  return WebService.getInstance()
    .sendRequest<GraphQLResponse>({
      url: process.env.APP_URL + '/graphql',
      method: 'POST',
      withCredentials: true,
      headers,
      data,
    })
    .then((response) => {
      if ('errors' in response.data && response.data.errors?.length) {
        for (const error of response.data.errors) {
          const errorWithExtensions = error as PayloadErrorWithExtensions
          if (errorWithExtensions.extensions?.category === 'throttling') {
            // Means we exceeded the limit of request throttling, retry when
            // we get credits again.
            return handleThrottling(response).then(() =>
              fetchQuery(operation, variables, cacheConfig, uploadables)
            )
          }
        }
      }

      // Update cache on queries
      if (isQuery && queryId && response.data) {
        cache.set(queryId, variables, response.data)
      }
      // Clear cache on mutations
      if (isMutation) {
        cache.clear()
      }

      return response.data
    })
}

export const environment = new Environment({
  configName: 'PowerApp',
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})
