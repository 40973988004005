import { forwardRef, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { Link } from 'react-router-dom'
import { graphql } from 'relay-runtime'
import { ChallengesOverview_stats$key } from '../../generated/ChallengesOverview_stats.graphql'
import { classNames } from '../../utils/classNames'
import { ForwardIcon } from '../common/ForwardIcon'
import { TertiaryButton } from '../common/TertiaryButton'

import styles from './ChallengesOverview.scss'
import { Stat } from './stats/Stat'

interface ChallengesOverviewStats {
  className?: string
  stats: ChallengesOverview_stats$key
}

export const ChallengesOverview = forwardRef<
  HTMLDivElement,
  ChallengesOverviewStats
>(function ChallengesOverview(props, ref): ReactElement {
  const { t, i18n } = useTranslation()
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.language),
    [i18n.language]
  )
  const { currentMissions } = useFragment(
    graphql`
      fragment ChallengesOverview_stats on Query
      @argumentDefinitions(first: { type: "Int!" }) {
        currentMissions: missions(filter: CURRENT, first: 1) {
          edges {
            node {
              availableChallenges: challenges(filter: OPEN, first: $first) {
                pageInfo {
                  total
                }
                edges {
                  node {
                    task {
                      type
                    }
                  }
                }
              }
              uncompletedChallenges: challenges(
                filter: UNCOMPLETED
                first: $first
              ) {
                pageInfo {
                  total
                }
              }
              totalChallenges: challenges(filter: ALL, first: 1) {
                pageInfo {
                  total
                }
              }
            }
          }
        }
      }
    `,
    props.stats
  )

  const totalChallenges =
    currentMissions?.edges[0].node.totalChallenges?.pageInfo.total ?? 0
  const lockedChallenges =
    (currentMissions?.edges[0].node.uncompletedChallenges?.pageInfo.total ??
      0) -
    (currentMissions?.edges[0].node.availableChallenges?.pageInfo.total ?? 0)
  const openIndividualChallenges =
    currentMissions?.edges[0].node.availableChallenges?.edges
      .map((edge) => edge.node)
      .filter((challenge) => challenge.task.type === 'INDIVIDUAL').length ?? 0
  const openTeamChallenges =
    currentMissions?.edges[0].node.availableChallenges?.edges
      .map((edge) => edge.node)
      .filter((challenge) => challenge.task.type === 'TEAM').length ?? 0
  const totalOpenChallenges = openIndividualChallenges + openTeamChallenges

  return (
    <section className={classNames(props.className, styles.outer)} ref={ref}>
      <h2>{t('challenges.title')}</h2>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.description} to='/challenges'>
              <h3>
                {totalOpenChallenges > 0
                  ? t('challenges.dashboard.motivation')
                  : t('challenges.dashboard.motivationDone')}
              </h3>

              <p>
                {t('challenges.dashboard.openChallenges', {
                  count: totalOpenChallenges,
                })}
              </p>

              {openIndividualChallenges > 0 && (
                <div className={styles.openChallengeStat}>
                  <span
                    className={classNames(styles.legend, styles.individual)}
                  />
                  {t('challenges.dashboard.individual', {
                    count: openIndividualChallenges,
                    formatted: numberFormatter.format(openIndividualChallenges),
                  })}
                </div>
              )}

              {openTeamChallenges > 0 && (
                <div className={styles.openChallengeStat}>
                  <span className={classNames(styles.legend, styles.team)} />
                  {t('challenges.dashboard.team', {
                    count: openTeamChallenges,
                    formatted: numberFormatter.format(openTeamChallenges),
                  })}
                </div>
              )}

              {lockedChallenges > 0 && (
                <div className={styles.lockedChallengesStat}>
                  {t('challenges.dashboard.locked', {
                    count: lockedChallenges,
                    formatted: numberFormatter.format(lockedChallenges),
                  })}
                </div>
              )}

              <TertiaryButton className={styles.button} icon>
                <ForwardIcon />
              </TertiaryButton>
            </Link>
          </div>

          <div className={styles.col}>
            <Stat
              className={styles.stats}
              number={totalOpenChallenges}
              scale={1.5}
              segments={[
                ...(openIndividualChallenges
                  ? [
                      {
                        color: '#00bcb3',
                        percentage:
                          (openIndividualChallenges / totalChallenges) * 100,
                      },
                    ]
                  : []),
                ...(openTeamChallenges
                  ? [
                      {
                        color: '#f3c000',
                        percentage:
                          (openTeamChallenges / totalChallenges) * 100,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  )
})
