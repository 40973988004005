/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TeamJoinedPopup_team = {
    readonly members: ReadonlyArray<{
        readonly id?: string | undefined;
        readonly " $fragmentRefs": FragmentRefs<"TeamMember_user">;
    }>;
    readonly " $refType": "TeamJoinedPopup_team";
};
export type TeamJoinedPopup_team$data = TeamJoinedPopup_team;
export type TeamJoinedPopup_team$key = {
    readonly " $data"?: TeamJoinedPopup_team$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TeamJoinedPopup_team">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamJoinedPopup_team",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
                {
                    "kind": "InlineFragment",
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeamMember_user"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Team",
    "abstractKey": null
} as any;
(node as any).hash = 'a6a34ad4560941d0aebe0629eaaddc34';
export default node;
