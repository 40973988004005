/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BadgesList_badges = {
    readonly badges: ReadonlyArray<{
        readonly id: string;
        readonly isReachable: boolean;
        readonly skill: {
            readonly name: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"Badge_badge">;
    }>;
    readonly me: {
        readonly badges: ReadonlyArray<{
            readonly id: string;
            readonly pivot: {
                readonly stars: number;
            } | null;
        }>;
    } | null;
    readonly " $refType": "BadgesList_badges";
};
export type BadgesList_badges$data = BadgesList_badges;
export type BadgesList_badges$key = {
    readonly " $data"?: BadgesList_badges$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BadgesList_badges">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "BadgesList_badges",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isReachable",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skill",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": (v1 /*: any*/),
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": (v1 /*: any*/),
                        "kind": "FragmentSpread",
                        "name": "Badge_badge"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticatedUser",
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Badge",
                        "kind": "LinkedField",
                        "name": "badges",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserBadge",
                                "kind": "LinkedField",
                                "name": "pivot",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "stars",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '0cc20c07bacc689af96f3e17ca7b2f6c';
export default node;
