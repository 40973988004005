import { PropsWithChildren, ReactElement, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import styles from './NavigationBar.scss'

interface NavigationBarLinkProps {
  exact?: boolean
  onClick?: (event: SyntheticEvent) => void
  to: string | string[]
}

function NavigationBarLink(
  props: PropsWithChildren<NavigationBarLinkProps>
): ReactElement {
  const match = useRouteMatch({
    exact: props.exact,
    path: props.to,
  })

  const onKeyPress = useEnterKeyHandler(props.onClick)
  return (
    <Link
      className={classNames(styles.menuItem, {
        [styles.active]: match !== null,
      })}
      onClick={props.onClick}
      onKeyPress={onKeyPress}
      tabIndex={0}
      to={Array.isArray(props.to) ? props.to[0] : props.to}
    >
      {props.children}
    </Link>
  )
}

export function NavigationBar(): ReactElement {
  const { t } = useTranslation()

  // NOTE: The SVG icons below are inlined rather than loaded from a file as it's
  // needed for fill='currentColor'.
  return (
    <nav className={styles.navigationBar}>
      <div className={styles.content}>
        <NavigationBarLink to='/' exact>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.icon}
            width='33.079'
            height='30.21'
            viewBox='0 0 33.079 30.21'
          >
            <g transform='translate(-3.5 -7.7)'>
              <path
                d='M35.579,8.7H21.3V24.786H35.579ZM4.5,8.7v9.768H18.776V8.7ZM18.776,20.824H4.5V36.91H18.776Zm16.8,6.318H21.3V36.91H35.579Z'
                transform='translate(0 0)'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
              />
            </g>
          </svg>

          <span className={styles.menuItemLabel}>{t('menu.dashboard')}</span>
        </NavigationBarLink>
        <NavigationBarLink to='/missions/current' exact>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.icon}
            width='38.805'
            height='38.771'
            viewBox='0 0 38.805 38.771'
          >
            <path
              d='M25.857,28.037H9.49v1.757H12.9a15.975,15.975,0,0,0,15.114,15.1V48.3h1.757V44.891A16,16,0,0,0,44.505,32.429H42.7A14.359,14.359,0,0,1,29.771,43.134v-4.69A9.567,9.567,0,0,0,37.8,32.429H35.885a7.9,7.9,0,0,1-6.114,4.263V31.951a3.185,3.185,0,0,0,2.157-2.157H48.3V28.037H44.885a15.975,15.975,0,0,0-15.114-15.1V9.53H28.014v3.411A16,16,0,0,0,13.289,25.364h1.8A14.287,14.287,0,0,1,28.014,14.706V19.4a9.571,9.571,0,0,0-8.019,5.969h1.923a7.892,7.892,0,0,1,6.1-4.225V25.88A3.185,3.185,0,0,0,25.857,28.037Zm4.438.878a1.405,1.405,0,0,1-.307.878,1.311,1.311,0,0,1-.217.217,1.411,1.411,0,0,1-1.757,0,1.311,1.311,0,0,1-.217-.217,1.411,1.411,0,0,1,0-1.757,1.311,1.311,0,0,1,.217-.217,1.411,1.411,0,0,1,1.757,0,1.311,1.311,0,0,1,.217.217A1.405,1.405,0,0,1,30.3,28.916Zm-4.438.878a3.185,3.185,0,0,0,2.157,2.157v4.741a7.84,7.84,0,0,1-6.9-6.9Zm-11.183,0h4.69a9.567,9.567,0,0,0,8.65,8.659v4.69A14.261,14.261,0,0,1,14.674,29.794Zm17.254-1.757a3.185,3.185,0,0,0-2.157-2.157V21.139a7.84,7.84,0,0,1,6.9,6.9Zm11.183,0h-4.69a9.567,9.567,0,0,0-8.65-8.659v-4.69A14.261,14.261,0,0,1,43.111,28.037Z'
              fill='currentColor'
              transform='translate(-9.49 -9.53)'
            />
          </svg>

          <span className={styles.menuItemLabel}>{t('menu.mission')}</span>
        </NavigationBarLink>
        <NavigationBarLink to='/challenges' exact>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.icon}
            width='29.241'
            height='31.802'
            viewBox='0 0 29.241 31.802'
          >
            <path
              d='M5.707.6A3.825,3.825,0,0,0,1.879,4.426v0A3.829,3.829,0,1,0,5.707.6Zm0,1.043A2.787,2.787,0,1,1,2.928,4.428,2.8,2.8,0,0,1,5.707,1.641Zm8.15,2.054a.523.523,0,1,0,0,1.046H29.6a.523.523,0,1,0,0-1.046Zm-8.15,8.477A3.823,3.823,0,0,0,1.879,16v0a3.829,3.829,0,1,0,3.828-3.828Zm0,1.041A2.787,2.787,0,1,1,2.928,16,2.8,2.8,0,0,1,5.707,13.213Zm7.936,2.264a.509.509,0,0,0-.518.523.5.5,0,0,0,.518.518H29.381a.521.521,0,1,0,0-1.041ZM5.707,23.744A3.823,3.823,0,0,0,1.879,27.57v0a3.829,3.829,0,1,0,3.828-3.828Zm0,1.041A2.787,2.787,0,1,1,2.92,27.572v0A2.8,2.8,0,0,1,5.707,24.785Zm7.936,2.27a.509.509,0,0,0-.518.523.5.5,0,0,0,.518.518H29.381a.516.516,0,0,0,.523-.518.523.523,0,0,0-.523-.523Z'
              transform='translate(-1.379 -0.098)'
              fill='currentColor'
              stroke='currentColor'
              strokeWidth='1'
            />
          </svg>

          <span className={styles.menuItemLabel}>{t('menu.challenges')}</span>
        </NavigationBarLink>
        <NavigationBarLink to='/badges' exact>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.icon}
            width='29'
            height='39.771'
            viewBox='0 0 29 39.771'
          >
            <g transform='translate(-8.39 0.5)'>
              <path
                d='M35.121,31.31a.632.632,0,0,0-.569-.37h-5.87l-2.532-5.119a.627.627,0,0,0-1.127,0L22.49,30.94H16.832a.625.625,0,0,0-.479,1.03l4.174,4.919-1.29,5.719a.629.629,0,0,0,.224.63.619.619,0,0,0,.666.067L25.586,40.6,31.044,43.3a.65.65,0,0,0,.279.067.615.615,0,0,0,.388-.133.629.629,0,0,0,.224-.63L30.644,36.9l4.374-4.919A.612.612,0,0,0,35.121,31.31Zm-5.628,5a.634.634,0,0,0-.145.551L30.42,41.6l-4.562-2.26a.616.616,0,0,0-.557,0L20.745,41.6l1.072-4.737a.633.633,0,0,0-.133-.545l-3.5-4.126h4.695a.63.63,0,0,0,.563-.351l2.145-4.331,2.145,4.331a.627.627,0,0,0,.563.351h4.865Z'
                transform='translate(-2.883 -10.04)'
                fill='currentColor'
                stroke='currentColor'
                strokeWidth='1'
              />
              <path
                d='M23.466,10.783V7.942H30.36a.623.623,0,0,0,.624-.624V.624A.619.619,0,0,0,30.36,0H15.736a.623.623,0,0,0-.624.624V7.312a.623.623,0,0,0,.624.624h6.476v2.847a14.052,14.052,0,1,0,1.254,0ZM16.36,1.254H29.736V6.688H16.36ZM22.89,37.517a12.746,12.746,0,0,1-.363-25.486c.164-.006.46-.006.636,0a12.744,12.744,0,0,1-.273,25.486Z'
                transform='translate(0)'
                fill='currentColor'
                stroke='currentColor'
                strokeWidth='1'
              />
            </g>
          </svg>

          <span className={styles.menuItemLabel}>{t('menu.badges')}</span>
        </NavigationBarLink>
      </div>
    </nav>
  )
}
