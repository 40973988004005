/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditableAvatar_user = {
    readonly avatar: string | null;
    readonly " $refType": "EditableAvatar_user";
};
export type EditableAvatar_user$data = EditableAvatar_user;
export type EditableAvatar_user$key = {
    readonly " $data"?: EditableAvatar_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditableAvatar_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditableAvatar_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '691e1ddf7540d10a2f760491407ba1e3';
export default node;
