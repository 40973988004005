import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { never } from '../../utils/assert'
import { Popup as PopupType, PopupProps } from '../../utils/hooks/usePopups'
import { BadgeEarnedPopup } from '../missions/BadgeEarnedPopup'
import { MissionCompletedPopup } from '../missions/MissionCompletedPopup'
import { NewMissionPopup } from '../missions/NewMissionPopup'
import { TaskCompletedPopup } from '../missions/TaskCompletedPopup'
import { TaskPopup } from '../missions/TaskPopup'

import { AvatarCropPopup } from '../profile/AvatarCropPopup'
import { EditProfilePopup } from '../profile/EditProfilePopup'
import { OnboardingPopup } from '../profile/OnboardingPopup'
import { ConfirmYesNoPopup } from './ConfirmYesNoPopup'

import { InternetRequiredPopup } from './InternetRequiredPopup'
import { MaintenanceModePopup } from './MaintenanceModePopup'
import styles from './Popup.scss'
import { PrivacyPolicyPopup } from './PrivacyPolicyPopup'
import { TaskforceJoinedPopup } from './TaskforceJoinedPopup'
import { TeamJoinedPopup } from './TeamJoinedPopup'
import { VersionConflictPopup } from './VersionConflictPopup'

const UNDISMISSABLE_POPUPS: PopupType['type'][] = [
  'mission-completed',
  'new-mission',
  'new-version',
  'onboarding',
  'task-completed',
  'taskforce-joined',
]

export const Popup = forwardRef(function Popup(
  props: PopupProps<PopupType>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const dialogElement = useRef<HTMLDivElement>(null)
  const { closeCurrentPopup, type } = props

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => dialogElement.current
  )

  useEffect(() => {
    document.body.classList.add(styles.modalOpen)

    return (): void => {
      if (props.popups.length === 1) {
        document.body.classList.remove(styles.modalOpen)
      }
    }
  }, [props.popups.length])

  const closePopupBackdropClick = useCallback(
    (event: MouseEvent): void => {
      const lightboxElement = document.body.querySelector('.ReactModalPortal')

      if (
        UNDISMISSABLE_POPUPS.includes(type) ||
        !(event.target instanceof Node) ||
        !dialogElement.current ||
        dialogElement.current.contains(event.target) ||
        lightboxElement?.contains(event.target)
      ) {
        return
      }

      closeCurrentPopup()
    },
    [closeCurrentPopup, type]
  )

  return (
    <>
      <div
        aria-labelledby='popup-label'
        aria-modal={true}
        className={styles.modal}
        onClick={closePopupBackdropClick}
        role='dialog'
        tabIndex={-1}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalRow}>
            <div
              className={styles.modalDialog}
              // NOTE: If you put the ref on another element, update animations.ts accordingly,
              // so it can find the backdrop element.
              ref={dialogElement}
              role='document'
            >
              <div className={styles.modalContent}>
                <InnerPopup {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.modalBackdrop} />
    </>
  )
})

function InnerPopup(props: PopupProps<PopupType>): ReactElement {
  switch (props.type) {
    case 'badge-earned':
      return <BadgeEarnedPopup {...props} />
    case 'confirm-yes-no':
      return <ConfirmYesNoPopup {...props} />
    case 'crop-avatar':
      return <AvatarCropPopup {...props} />
    case 'edit-profile':
      return <EditProfilePopup {...props} />
    case 'internet-required':
      return <InternetRequiredPopup />
    case 'maintenance':
      return <MaintenanceModePopup {...props} />
    case 'mission-completed':
      return <MissionCompletedPopup {...props} />
    case 'new-mission':
      return <NewMissionPopup {...props} />
    case 'new-version':
      return <VersionConflictPopup />
    case 'privacy-policy':
      return <PrivacyPolicyPopup {...props} />
    case 'task':
      return <TaskPopup {...props} />
    case 'task-completed':
      return <TaskCompletedPopup {...props} />
    case 'taskforce-joined':
      return <TaskforceJoinedPopup {...props} />
    case 'team-joined':
      return <TeamJoinedPopup {...props} />
    case 'onboarding':
      return <OnboardingPopup {...props} />
    default:
      never(props, `Invalid popup type: ${JSON.stringify(props)}`)
  }
}
