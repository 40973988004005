/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LtiInteraction_interaction = {
    readonly buttonText: string | null;
    readonly launchUrl: string;
    readonly " $refType": "LtiInteraction_interaction";
};
export type LtiInteraction_interaction$data = LtiInteraction_interaction;
export type LtiInteraction_interaction$key = {
    readonly " $data"?: LtiInteraction_interaction$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LtiInteraction_interaction">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LtiInteraction_interaction",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "buttonText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "launchUrl",
            "storageKey": null
        }
    ],
    "type": "LtiInteraction",
    "abstractKey": null
} as any;
(node as any).hash = '14c1cc0ee49f4e8642d6bb7d355e0e01';
export default node;
