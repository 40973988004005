/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TaskCompletedPopupUserQueryVariables = {};
export type TaskCompletedPopupUserQueryResponse = {
    readonly me: {
        readonly avatar: string | null;
        readonly xp: number;
        readonly level: number;
    } | null;
};
export type TaskCompletedPopupUserQuery = {
    readonly response: TaskCompletedPopupUserQueryResponse;
    readonly variables: TaskCompletedPopupUserQueryVariables;
};



/*
query TaskCompletedPopupUserQuery {
  me {
    avatar
    xp
    level
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xp",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "TaskCompletedPopupUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "TaskCompletedPopupUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "41778bc5fa55d12d7cb6dc45e0e1576e",
            "id": null,
            "metadata": {},
            "name": "TaskCompletedPopupUserQuery",
            "operationKind": "query",
            "text": "query TaskCompletedPopupUserQuery {\n  me {\n    avatar\n    xp\n    level\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'fe6e952139f4413495dfdb7d68f87513';
export default node;
