/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BadgeEarnedPopupClaimMutationVariables = {
    badgeId: string;
};
export type BadgeEarnedPopupClaimMutationResponse = {
    readonly claimBadge: {
        readonly pivot: {
            readonly lastClaimedStars: number;
        } | null;
    } | null;
};
export type BadgeEarnedPopupClaimMutation = {
    readonly response: BadgeEarnedPopupClaimMutationResponse;
    readonly variables: BadgeEarnedPopupClaimMutationVariables;
};



/*
mutation BadgeEarnedPopupClaimMutation(
  $badgeId: ID!
) {
  claimBadge(badgeId: $badgeId) {
    pivot {
      lastClaimedStars
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "badgeId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "badgeId",
            "variableName": "badgeId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "UserBadge",
        "kind": "LinkedField",
        "name": "pivot",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastClaimedStars",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BadgeEarnedPopupClaimMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "claimBadge",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BadgeEarnedPopupClaimMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "claimBadge",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5f09c55c3e4a37d0d6cd57f1ece83f90",
            "id": null,
            "metadata": {},
            "name": "BadgeEarnedPopupClaimMutation",
            "operationKind": "mutation",
            "text": "mutation BadgeEarnedPopupClaimMutation(\n  $badgeId: ID!\n) {\n  claimBadge(badgeId: $badgeId) {\n    pivot {\n      lastClaimedStars\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '62ead14a80daf20dc2f1fe3cba0e908c';
export default node;
