/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type InteractionOutsideGameUploadMutationVariables = {
    taskId: string;
    file: unknown;
};
export type InteractionOutsideGameUploadMutationResponse = {
    readonly uploadFile: {
        readonly completion: {
            readonly uploadedFile: {
                readonly fileName: string;
                readonly url: string;
            } | null;
        } | null;
    } | null;
};
export type InteractionOutsideGameUploadMutation = {
    readonly response: InteractionOutsideGameUploadMutationResponse;
    readonly variables: InteractionOutsideGameUploadMutationVariables;
};



/*
mutation InteractionOutsideGameUploadMutation(
  $taskId: ID!
  $file: Upload!
) {
  uploadFile(file: $file, taskId: $taskId) {
    completion {
      uploadedFile {
        fileName
        url
        id
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "file"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "taskId"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "file",
            "variableName": "file"
        } as any,
        {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileName",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "InteractionOutsideGameUploadMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "uploadFile",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UploadedFile",
                                    "kind": "LinkedField",
                                    "name": "uploadedFile",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "InteractionOutsideGameUploadMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "uploadFile",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskCompletion",
                            "kind": "LinkedField",
                            "name": "completion",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UploadedFile",
                                    "kind": "LinkedField",
                                    "name": "uploadedFile",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c0404bbdd8193013017e1633de9eba4b",
            "id": null,
            "metadata": {},
            "name": "InteractionOutsideGameUploadMutation",
            "operationKind": "mutation",
            "text": "mutation InteractionOutsideGameUploadMutation(\n  $taskId: ID!\n  $file: Upload!\n) {\n  uploadFile(file: $file, taskId: $taskId) {\n    completion {\n      uploadedFile {\n        fileName\n        url\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a6fb4b07ad8a6200c86624b941b57d97';
export default node;
