/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AssignmentStats_stats = {
    readonly missions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly allAssignments: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
                readonly completedAssignments: {
                    readonly pageInfo: {
                        readonly total: number;
                    };
                } | null;
            };
        }>;
    } | null;
    readonly " $refType": "AssignmentStats_stats";
};
export type AssignmentStats_stats$data = AssignmentStats_stats;
export type AssignmentStats_stats$key = {
    readonly " $data"?: AssignmentStats_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AssignmentStats_stats">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    } as any, v1 = [
        {
            "kind": "Literal",
            "name": "filter",
            "value": "ALL"
        } as any,
        (v0 /*: any*/)
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "AssignmentStats_stats",
        "selections": [
            {
                "alias": null,
                "args": (v1 /*: any*/),
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "MissionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Mission",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": "allAssignments",
                                        "args": (v1 /*: any*/),
                                        "concreteType": "AssignmentConnection",
                                        "kind": "LinkedField",
                                        "name": "assignments",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": "completedAssignments",
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": "COMPLETED"
                                            },
                                            (v0 /*: any*/)
                                        ],
                                        "concreteType": "AssignmentConnection",
                                        "kind": "LinkedField",
                                        "name": "assignments",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'f67f44303d4c4560856d9f9a4c4efdcf';
export default node;
