import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../../../../images/logo/white.svg'

import styles from './BottomBar.scss'

export function BottomBar(): ReactElement {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <img src={logo} className={styles.item + ' ' + styles.logo} />
      <span className={styles.item}>
        {t('common.copyright', { year: new Date().getFullYear() })}
      </span>
      <span className={styles.item} aria-hidden={true}>
        |
      </span>
      <span className={styles.item}>
        <a
          href='http://support.traintool.com'
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          {t('common.faq')}
        </a>
      </span>
    </footer>
  )
}
