/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LtiInteraction_task = {
    readonly completion: {
        readonly completedAt: string | null;
    } | null;
    readonly " $refType": "LtiInteraction_task";
};
export type LtiInteraction_task$data = LtiInteraction_task;
export type LtiInteraction_task$key = {
    readonly " $data"?: LtiInteraction_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LtiInteraction_task">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LtiInteraction_task",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "TaskCompletion",
            "kind": "LinkedField",
            "name": "completion",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Task",
    "abstractKey": null
} as any;
(node as any).hash = '6bf7ab6e8572558385190962956f4723';
export default node;
