import { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { classNames } from '../../utils/classNames'
import {
  PopupProps,
  TaskforceJoinedPopup as TaskforceJoinedPopupProps,
} from '../../utils/hooks/usePopups'
import { TutorialContext } from '../../utils/tutorial'
import { KalturaEmbed } from '../missions/KalturaEmbed'
import { PrimaryButton } from './PrimaryButton'

import teamBlueBackdrop from '../../../../../images/taskforces/team-blue.jpg'
import teamRedBackdrop from '../../../../../images/taskforces/team-red.jpg'
import teamWhiteBackdrop from '../../../../../images/taskforces/team-white.jpg'
import teamYellowBackdrop from '../../../../../images/taskforces/team-yellow.jpg'

import styles from './TaskforceJoinedPopup.scss'

export function TaskforceJoinedPopup(
  props: PopupProps<TaskforceJoinedPopupProps>
): ReactElement {
  const { closeCurrentPopup, hasPopup, replaceCurrentPopup } = props
  const [, , , setTutorialActive] = useContext(TutorialContext)
  const { t } = useTranslation()

  const taskforce = useFragment(
    graphql`
      fragment TaskforceJoinedPopup_taskforce on Taskforce {
        taskforceType
      }
    `,
    props.taskforce
  )
  const firstMission = useFragment(
    graphql`
      fragment TaskforceJoinedPopup_firstMission on Mission
      @argumentDefinitions(language: { type: "String!" }) {
        id
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...NewMissionPopup_mission @arguments(language: $language)
      }
    `,
    props.firstMission
  )

  const onClose = useCallback(() => {
    if (
      firstMission &&
      !hasPopup('new-mission', (popup) => popup.popupKey === firstMission.id)
    ) {
      replaceCurrentPopup({
        mission: firstMission,
        popupKey: firstMission.id,
        shouldStartTutorial: true,
        type: 'new-mission',
      })
    } else {
      setTutorialActive(true)
      closeCurrentPopup()
    }
  }, [
    closeCurrentPopup,
    firstMission,
    hasPopup,
    replaceCurrentPopup,
    setTutorialActive,
  ])

  return (
    <div
      className={classNames(styles.container, {
        [styles.blue]: taskforce.taskforceType === 'EMBEZZLING',
        [styles.red]: taskforce.taskforceType === 'CORRUPTION',
        [styles.white]: taskforce.taskforceType === 'MONEY_LAUNDERING',
        [styles.yellow]: taskforce.taskforceType === 'FRAUD',
      })}
    >
      <div className={styles.absolute} aria-hidden>
        {taskforce.taskforceType === 'CORRUPTION' && (
          <img src={teamRedBackdrop} />
        )}
        {taskforce.taskforceType === 'EMBEZZLING' && (
          <img src={teamBlueBackdrop} />
        )}
        {taskforce.taskforceType === 'MONEY_LAUNDERING' && (
          <img src={teamWhiteBackdrop} />
        )}
        {taskforce.taskforceType === 'FRAUD' && (
          <img src={teamYellowBackdrop} />
        )}

        <div className={styles.gradient} />
      </div>

      <div className={styles.flag} />

      <div className={styles.content}>
        <h1>{t('taskforce.name.' + taskforce.taskforceType.toLowerCase())}</h1>
        <p>{t('taskforce.welcome.' + taskforce.taskforceType.toLowerCase())}</p>

        {taskforce.taskforceType === 'CORRUPTION' && (
          <KalturaEmbed url='https://api.eu.kaltura.com/p/107/sp/10700/embedIframeJs/uiconf_id/23449338/partner_id/107?iframeembed=true&playerId=kaltura_player&entry_id=0_a096k06o&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en_GB&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_w85ulmk2' />
        )}
        {taskforce.taskforceType === 'EMBEZZLING' && (
          <KalturaEmbed url='https://api.eu.kaltura.com/p/107/sp/10700/embedIframeJs/uiconf_id/23449338/partner_id/107?iframeembed=true&playerId=kaltura_player&entry_id=0_1fuc6f8q&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en_GB&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_7l3azegv' />
        )}
        {taskforce.taskforceType === 'MONEY_LAUNDERING' && (
          <KalturaEmbed url='https://api.eu.kaltura.com/p/107/sp/10700/embedIframeJs/uiconf_id/23449338/partner_id/107?iframeembed=true&playerId=kaltura_player&entry_id=0_xzel3ov2&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en_GB&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_60v7s3ja' />
        )}
        {taskforce.taskforceType === 'FRAUD' && (
          <KalturaEmbed url='https://api.eu.kaltura.com/p/107/sp/10700/embedIframeJs/uiconf_id/23449338/partner_id/107?iframeembed=true&playerId=kaltura_player&entry_id=0_8yzf0ouj&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en_GB&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_nnxy3saf' />
        )}

        <PrimaryButton className={styles.button} onClick={onClose}>
          {t('taskforce.start')}
        </PrimaryButton>
      </div>
    </div>
  )
}
