/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OpenQuestionInteraction_task = {
    readonly completion: {
        readonly completedAt: string | null;
    } | null;
    readonly " $refType": "OpenQuestionInteraction_task";
};
export type OpenQuestionInteraction_task$data = OpenQuestionInteraction_task;
export type OpenQuestionInteraction_task$key = {
    readonly " $data"?: OpenQuestionInteraction_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OpenQuestionInteraction_task">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OpenQuestionInteraction_task",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "TaskCompletion",
            "kind": "LinkedField",
            "name": "completion",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Task",
    "abstractKey": null
} as any;
(node as any).hash = '063b6dbbed18b9d1ce53ae70d2958473';
export default node;
