/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TaskCompletedPopupClaimXpMutationVariables = {
    taskId: string;
};
export type TaskCompletedPopupClaimXpMutationResponse = {
    readonly claimXp: {
        readonly task: {
            readonly completion: {
                readonly xpClaimedAt: string | null;
            } | null;
        } | null;
        readonly user: {
            readonly level: number;
            readonly xp: number;
        };
    };
};
export type TaskCompletedPopupClaimXpMutation = {
    readonly response: TaskCompletedPopupClaimXpMutationResponse;
    readonly variables: TaskCompletedPopupClaimXpMutationVariables;
};



/*
mutation TaskCompletedPopupClaimXpMutation(
  $taskId: ID!
) {
  claimXp(taskId: $taskId) {
    task {
      completion {
        xpClaimedAt
        id
      }
      id
    }
    user {
      level
      xp
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xpClaimedAt",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xp",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TaskCompletedPopupClaimXpMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ClaimXpResult",
                    "kind": "LinkedField",
                    "name": "claimXp",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TaskCompletion",
                                    "kind": "LinkedField",
                                    "name": "completion",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TaskCompletedPopupClaimXpMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ClaimXpResult",
                    "kind": "LinkedField",
                    "name": "claimXp",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TaskCompletion",
                                    "kind": "LinkedField",
                                    "name": "completion",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1da9486ed44c220edd75fb2fbcd11b64",
            "id": null,
            "metadata": {},
            "name": "TaskCompletedPopupClaimXpMutation",
            "operationKind": "mutation",
            "text": "mutation TaskCompletedPopupClaimXpMutation(\n  $taskId: ID!\n) {\n  claimXp(taskId: $taskId) {\n    task {\n      completion {\n        xpClaimedAt\n        id\n      }\n      id\n    }\n    user {\n      level\n      xp\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '68ec087369917554ab655a51331bb081';
export default node;
