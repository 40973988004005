import { AxiosResponse } from 'axios'
import { wait } from './wait'

export function handleThrottling(response: AxiosResponse): Promise<void> {
  let retryAfter = parseInt(response.headers['retry-after'], 10)
  if (!retryAfter) {
    const serverTime = new Date(response.headers.date).getTime() / 1000
    const retryAt = parseInt(response.headers['x-ratelimit-reset'], 10)

    retryAfter = retryAt - serverTime
  }

  return wait(retryAfter * 1000)
}
