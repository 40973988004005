/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MissionStats_stats = {
    readonly missionsExceptKickOff: {
        readonly pageInfo: {
            readonly total: number;
        };
    } | null;
    readonly completed: {
        readonly pageInfo: {
            readonly total: number;
        };
    } | null;
    readonly " $refType": "MissionStats_stats";
};
export type MissionStats_stats$data = MissionStats_stats;
export type MissionStats_stats$key = {
    readonly " $data"?: MissionStats_stats$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MissionStats_stats">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    } as any, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "MissionStats_stats",
        "selections": [
            {
                "alias": "missionsExceptKickOff",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filter",
                        "value": "ALL_EXCEPT_KICKOFF"
                    },
                    (v0 /*: any*/)
                ],
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": "completed",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filter",
                        "value": "COMPLETED"
                    },
                    (v0 /*: any*/)
                ],
                "concreteType": "MissionConnection",
                "kind": "LinkedField",
                "name": "missions",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '4ee035ef7f38e9d67912a6820a353429';
export default node;
