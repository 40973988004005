import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { ChangeEvent, FormEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useMutation } from 'relay-hooks/lib'

import { Page } from '../../../containers/Page'
import { AuthenticationForgotPasswordPageMutation } from '../../../generated/AuthenticationForgotPasswordPageMutation.graphql'
import { useStores } from '../../../stores'
import { AuthHeader } from '../../auth/AuthHeader'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './AuthenticationForgotPasswordPage.scss'

export const AuthenticationForgotPasswordPage = observer(
  function AuthenticationForgotPasswordPage(props: RouteComponentProps) {
    const { authStore } = useStores()
    const { t } = useTranslation()

    const [requestNewPassword] =
      useMutation<AuthenticationForgotPasswordPageMutation>(graphql`
        mutation AuthenticationForgotPasswordPageMutation($email: String!) {
          requestNewPassword(email: $email)
        }
      `)

    const onUsernameChanged = (event: ChangeEvent<HTMLInputElement>): void => {
      authStore.setAuthUsername(event.target.value)
    }

    const onSubmit = useCallback(
      (event: FormEvent): void => {
        event.preventDefault()

        authStore.setAuthLoading(true)
        requestNewPassword({
          variables: { email: authStore.authUsername },
        })
          .then((response) => {
            authStore.requestNewPasswordSuccess(response.requestNewPassword)
            props.history.push('/')
          })
          .catch(() => {
            authStore.setAuthLoading(false)
          })
      },
      [authStore, props.history, requestNewPassword]
    )

    return (
      <>
        <AuthHeader />

        <Page narrow>
          <div className={styles.formGroup + ' ' + styles.formHeader}>
            <div className={styles.head}>{t('auth.header.remind')}</div>
            <div className={styles.sub}>{t('auth.remindText')}</div>
          </div>

          <form
            action='#'
            method='post'
            className={styles.formContainer}
            onSubmit={onSubmit}
          >
            <div className={styles.formGroup + ' ' + styles.prepend}>
              <div className={styles.prependIcon}>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <input
                className={styles.formControl + ' ' + styles.prependPadding}
                id='email'
                name='email'
                type='email'
                placeholder={t('auth.field.email')}
                onChange={onUsernameChanged}
                value={authStore.authUsername}
              />
            </div>

            <div className={styles.formFooter}>
              <div>
                <PrimaryButton
                  disabled={
                    authStore.authLoading ||
                    authStore.authUsername.trim().length === 0
                  }
                  type='submit'
                  className={styles.primaryButton}
                >
                  {t('auth.action.sendLink')}
                </PrimaryButton>
              </div>
              <div>
                {/* Back to login */}
                <Link
                  className={styles.formFooterLink}
                  tabIndex={0}
                  to='/login'
                >
                  {t('auth.action.backToLogin')}
                </Link>
              </div>
            </div>
          </form>
        </Page>
      </>
    )
  }
)
