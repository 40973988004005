import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useQuery } from 'relay-hooks'

import { ChallengesScreenQuery } from '../../../generated/ChallengesScreenQuery.graphql'
import { classNames } from '../../../utils/classNames'
import { GraphQlError } from '../../common/GraphQlError'
import { HeaderEditableAvatar } from '../../common/HeaderEditableAvatar'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { NavigationBar } from '../../common/NavigationBar'
import { ChallengeList } from '../../missions/ChallengeList'

import styles from './ChallengesScreen.scss'

export function ChallengesScreen(): ReactElement {
  const { t, i18n } = useTranslation()

  const missions = useQuery<ChallengesScreenQuery>(
    graphql`
      query ChallengesScreenQuery(
        $count: Int!
        $cursor: String
        $language: String!
      ) {
        missions(filter: CURRENT, first: 1) {
          edges {
            node {
              ...ChallengeList_challenges
                @arguments(count: $count, cursor: $cursor, language: $language)
            }
          }
        }
      }
    `,
    {
      // This count is actually limited on the server-side by the number of challenges that are available.
      count: 20,
      language: i18n.language,
    },
    { fetchPolicy: 'store-and-network' }
  )

  return (
    <div className={styles.outer}>
      <div className={styles.container}>
        <HeaderEditableAvatar />

        <div className={styles.row}>
          <NavigationBar />
        </div>

        <section className={classNames(styles.row, styles.challenges)}>
          <div className={styles.col}>
            <h1>{t('menu.challenges')}</h1>

            <p className={styles.introText}>{t('challenges.introText.0')}</p>
            <p className={styles.introText}>{t('challenges.introText.1')}</p>
          </div>

          <h2 className={classNames(styles.col, styles.listHeader)}>
            {t('challenges.title')}
          </h2>

          {missions.isLoading && <LoadingIndicator />}
          {missions.error && (
            <GraphQlError error={missions.error} retry={missions.retry} />
          )}

          {missions.data?.missions?.edges?.[0] && (
            <ChallengeList challenges={missions.data.missions.edges[0].node} />
          )}
        </section>
      </div>
    </div>
  )
}
