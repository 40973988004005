/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Feedback_upload = {
    readonly " $fragmentRefs": FragmentRefs<"TaskDescriptionMedia_upload">;
    readonly " $refType": "Feedback_upload";
};
export type Feedback_upload$data = Feedback_upload;
export type Feedback_upload$key = {
    readonly " $data"?: Feedback_upload$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Feedback_upload">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Feedback_upload",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskDescriptionMedia_upload"
        }
    ],
    "type": "UploadedFile",
    "abstractKey": null
} as any;
(node as any).hash = '532f6cfa038e84676903750516e43d29';
export default node;
