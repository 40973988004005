import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { ChallengeStats_stats$key } from '../../../generated/ChallengeStats_stats.graphql'
import { Stat } from './Stat'

interface ChallengeStatsProps {
  stats: ChallengeStats_stats$key
}

export function ChallengeStats(props: ChallengeStatsProps): ReactElement {
  const { t } = useTranslation()
  const stats = useFragment(
    graphql`
      fragment ChallengeStats_stats on Query
      @argumentDefinitions(first: { type: "Int!" }) {
        missions(first: $first, filter: ALL) {
          edges {
            node {
              allChallenges: challenges(first: $first, filter: ALL) {
                pageInfo {
                  total
                }
              }
              completedChallenges: challenges(
                first: $first
                filter: COMPLETED
              ) {
                pageInfo {
                  total
                }
              }
            }
          }
        }
      }
    `,
    props.stats
  )

  const completedChallenges =
    stats.missions?.edges
      .map((edge) => edge.node)
      .map((mission) => mission.completedChallenges?.pageInfo.total ?? 0)
      .reduce((acc, completed) => acc + completed, 0) ?? 0
  const totalChallenges =
    stats.missions?.edges
      .map((edge) => edge.node)
      .map((mission) => mission.allChallenges?.pageInfo.total ?? 0)
      .reduce((acc, completed) => acc + completed, 0) ?? 0

  return (
    <Stat
      caption={t('challenges.stat.completed')}
      color='#00abe9'
      number={completedChallenges}
      percentage={
        totalChallenges > 0 ? (completedChallenges / totalChallenges) * 100 : 0
      }
    />
  )
}
