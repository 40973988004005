import { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { LtiInteraction_interaction$key } from '../../../generated/LtiInteraction_interaction.graphql'
import { LtiInteraction_task$key } from '../../../generated/LtiInteraction_task.graphql'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './_interactions.scss'

interface LtiInteractionProps {
  task: LtiInteraction_task$key
  interaction: LtiInteraction_interaction$key
}

export function LtiInteraction(
  props: PropsWithChildren<LtiInteractionProps>
): ReactElement {
  const { t } = useTranslation()
  const task = useFragment(
    graphql`
      fragment LtiInteraction_task on Task {
        completion {
          completedAt
        }
      }
    `,
    props.task
  )
  const interaction = useFragment(
    graphql`
      fragment LtiInteraction_interaction on LtiInteraction
      @argumentDefinitions(language: { type: "String!" }) {
        buttonText(language: $language)
        launchUrl
      }
    `,
    props.interaction
  )

  return (
    <div className={styles.buttons}>
      {!task.completion?.completedAt && (
        <PrimaryButton
          className={styles.primaryButton}
          component='a'
          href={interaction.launchUrl}
        >
          {interaction.buttonText ?? t('interactions.lti.open')}
        </PrimaryButton>
      )}

      {props.children}
    </div>
  )
}
