/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LinkInteraction_task = {
    readonly id: string;
    readonly " $refType": "LinkInteraction_task";
};
export type LinkInteraction_task$data = LinkInteraction_task;
export type LinkInteraction_task$key = {
    readonly " $data"?: LinkInteraction_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LinkInteraction_task">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkInteraction_task",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Task",
    "abstractKey": null
} as any;
(node as any).hash = '3ed589b950dc77d8f702988da1af9a56';
export default node;
