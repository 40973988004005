import { ChangeEvent, useCallback } from 'react'

export function useOnChangeCallback(
  setterFunction: (value: string) => void
): (event: ChangeEvent<HTMLInputElement>) => void {
  return useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault()

      setterFunction(event.target.value)
    },
    [setterFunction]
  )
}
