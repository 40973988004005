import { ReactElement, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { NewMissionPopupAcknowledgeStartMutation } from '../../generated/NewMissionPopupAcknowledgeStartMutation.graphql'

import {
  NewMissionPopup as NewMissionPopupProps,
  PopupProps,
} from '../../utils/hooks/usePopups'
import { TutorialContext } from '../../utils/tutorial'
import { PrimaryButton } from '../common/PrimaryButton'
import { Stat } from '../dashboard/stats/Stat'

import styles from './NewMissionPopup.scss'

export function NewMissionPopup(
  props: PopupProps<NewMissionPopupProps>
): ReactElement {
  const { t } = useTranslation()
  const { closeCurrentPopup, connection, shouldStartTutorial } = props
  const [closing, setClosing] = useState(false)
  const [, , , setTutorialActive] = useContext(TutorialContext)

  const mission = useFragment(
    graphql`
      fragment NewMissionPopup_mission on Mission
      @argumentDefinitions(language: { type: "String!" }) {
        assignments(filter: ALL, first: 1) {
          pageInfo {
            total
          }
        }
        banner {
          url
        }
        challenges(filter: ALL, first: 1) {
          pageInfo {
            total
          }
        }
        # TODO: other start text
        description(language: $language)
        id
        title(language: $language)
      }
    `,
    props.mission
  )

  const [acknowledge, acknowledgementResult] =
    useMutation<NewMissionPopupAcknowledgeStartMutation>(graphql`
      mutation NewMissionPopupAcknowledgeStartMutation(
        $id: ID!
        $connection: ID!
      ) {
        acknowledgeMissionStart(id: $id) {
          id @deleteEdge(connections: [$connection])
        }
      }
    `)
  const onClose = useCallback(() => {
    acknowledge({
      variables: { id: mission.id, connection: connection ?? '' },
    }).then(() => {
      if (shouldStartTutorial) {
        setTutorialActive(true)
      }

      closeCurrentPopup()
      setClosing(true)
    })
  }, [
    acknowledge,
    closeCurrentPopup,
    connection,
    mission.id,
    setTutorialActive,
    shouldStartTutorial,
  ])

  return (
    <>
      <div
        className={styles.banner}
        style={{ backgroundImage: `url('${mission.banner.url}')` }}
      />
      <div className={styles.content}>
        <h1>{mission.title}</h1>

        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: mission.description }}
        />

        <h2>Deze missie bestaat uit:</h2>
        <div className={styles.stats}>
          <Stat
            caption={t('assignments.name', {
              count: mission.assignments?.pageInfo.total ?? 0,
            })}
            captionClassName={styles.caption}
            className={styles.stat}
            color='#8bbf53'
            number={mission.assignments?.pageInfo.total ?? 0}
            percentage={100}
            scale={0.8333}
          />

          <Stat
            caption={t('challenges.name', {
              count: mission.challenges?.pageInfo.total ?? 0,
            })}
            captionClassName={styles.caption}
            className={styles.stat}
            color='#f3c000'
            number={mission.challenges?.pageInfo.total ?? 0}
            percentage={100}
            scale={0.8333}
          />
        </div>

        <div className={styles.buttons}>
          <PrimaryButton
            className={styles.button}
            disabled={acknowledgementResult.loading || closing}
            onClick={onClose}
          >
            Start!
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}
