import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Taskforce_taskforce$key } from '../../generated/Taskforce_taskforce.graphql'
import { classNames } from '../../utils/classNames'

import styles from './Taskforce.scss'

interface TaskforceProps {
  containsCurrentUser: boolean
  rank: number
  stillInLastPlace: boolean
  taskforce: Taskforce_taskforce$key
}

export function Taskforce(props: TaskforceProps): ReactElement {
  const { i18n, t } = useTranslation()
  const taskforce = useFragment(
    graphql`
      fragment Taskforce_taskforce on Taskforce {
        lastSnapshotRank
        taskforceType
        totalXp
      }
    `,
    props.taskforce
  )

  return (
    <li
      className={classNames(styles.listItem, {
        [styles.containsUser]: props.containsCurrentUser,
      })}
    >
      <div className={styles.rank}>{props.rank}</div>
      <div className={styles.name}>
        {t('taskforce.name.' + taskforce.taskforceType.toLowerCase())}
      </div>
      <div className={styles.xp}>
        {t('xp.count', {
          count: taskforce.totalXp,
          formatted: new Intl.NumberFormat(i18n.language, {
            notation: 'compact',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).format(taskforce.totalXp),
        })}
      </div>
      <div className={styles.rankChange}>
        {props.rank < taskforce.lastSnapshotRank && (
          <FontAwesomeIcon className={styles.up} icon={faCaretUp} />
        )}
        {props.rank > taskforce.lastSnapshotRank && !props.stillInLastPlace && (
          <FontAwesomeIcon className={styles.down} icon={faCaretDown} />
        )}
      </div>
    </li>
  )
}
