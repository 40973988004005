/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Interaction_interactable = {
    readonly __typename: "InteractionOutsideGame" | "LinkInteraction" | "LtiInteraction" | "QuestionInteraction" | "OpenQuestionInteraction";
    readonly " $fragmentRefs": FragmentRefs<"InteractionOutsideGame_interaction" | "LinkInteraction_interaction" | "LtiInteraction_interaction" | "QuestionInteraction_interaction" | "OpenQuestionInteraction_question">;
    readonly " $refType": "Interaction_interactable";
};
export type Interaction_interactable$data = Interaction_interactable;
export type Interaction_interactable$key = {
    readonly " $data"?: Interaction_interactable$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Interaction_interactable">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "Interaction_interactable",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "args": (v0 /*: any*/),
                "kind": "FragmentSpread",
                "name": "InteractionOutsideGame_interaction"
            },
            {
                "args": (v0 /*: any*/),
                "kind": "FragmentSpread",
                "name": "LinkInteraction_interaction"
            },
            {
                "args": (v0 /*: any*/),
                "kind": "FragmentSpread",
                "name": "LtiInteraction_interaction"
            },
            {
                "args": (v0 /*: any*/),
                "kind": "FragmentSpread",
                "name": "QuestionInteraction_interaction"
            },
            {
                "args": (v0 /*: any*/),
                "kind": "FragmentSpread",
                "name": "OpenQuestionInteraction_question"
            }
        ],
        "type": "Interactable",
        "abstractKey": "__isInteractable"
    } as any;
})();
(node as any).hash = '11ff9eaca5096fd32782f925d5300220';
export default node;
