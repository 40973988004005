import { createContext, useReducer, useState } from 'react'
import { never } from './assert'

export const enum TutorialSteps {
  Dashboard,
  TaskForce,
  Mission,
  Badges,
  Challenges,
  XP,
  __Max,
}

type RectShape = {
  shape: 'rect'
  width: number
  height: number
  x: number
  y: number
}

export type TutorialStep = {
  step: TutorialSteps
  text: string
} & RectShape

export type Tutorial = Map<TutorialSteps, TutorialStep>
export type TutorialAction =
  | {
      type: 'set'
      value: TutorialStep
    }
  | {
      type: 'remove'
      step: TutorialSteps
    }

type TutorialData = [
  Tutorial,
  (action: TutorialAction) => void,
  boolean,
  (activated: boolean) => void
]
export const TutorialContext = createContext<TutorialData>(undefined as never)

export function useTutorial(): TutorialData {
  const [tutorials, updateTutorials] = useReducer(
    (previous: Tutorial, action: TutorialAction): Tutorial => {
      switch (action.type) {
        case 'set': {
          const updated = new Map([...previous])
          updated.set(action.value.step, action.value)

          return updated
        }
        case 'remove': {
          const updated = new Map([...previous])
          updated.delete(action.step)

          return updated
        }
        default:
          never(action, 'Invalid local popup action')
      }
    },
    null,
    () => new Map()
  )

  const [activated, setActivated] = useState(false)

  return [tutorials, updateTutorials, activated, setActivated]
}

export function rectFromBoundingRect(rect: DOMRect): RectShape {
  const margin = 8

  return {
    shape: 'rect',
    x: rect.left + window.scrollX - margin,
    y: rect.top + window.scrollY - margin,
    width: rect.width + margin * 2,
    height: rect.height + margin * 2,
  }
}
