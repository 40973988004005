import { Translations } from './types'

const translations: Translations = {
  assignments: {
    header: 'Assignments',
    availableAfterPreviousAssignments:
      'Deze assignment komt beschikbaar nadat je de vorige assignment(s) in deze missie voltooit',
    availableFromDate: 'Deze assignment komt beschikbaar op {{date}}',
    stat: {
      completed: 'Afgeronde assignments',
    },
    name_one: 'Assignment',
    name_other: 'Assignments',
  },
  task: {
    type: {
      label: 'Type',
      individual: {
        assignment: 'Individuele assignment',
        challenge: 'Individuele challenge',
      },
      team: {
        assignment: 'Team assignment',
        challenge: 'Team challenge',
      },
      assignment: 'Assignment',
      challenge: 'Challenge',
    },
    duration: 'Duur',
    skill: 'Skill',
    downloadFile: 'Download {{filename}}',
    completed: {
      collect: 'Verzamel',
      title: 'Afgerond',
      wellDone: 'Goed gedaan!',
    },
  },
  interactions: {
    link: {
      open: 'Open de link',
    },
    lti: {
      open: 'Open TrainTool',
    },
    outsideGame: {
      deleteFile: 'Verwijder geüpload bestand',
      submit: {
        assignment: 'Assignment afronden',
        challenge: 'Challenge afronden',
      },
      uploadFile: 'Upload jouw bestand',
      uploaded: 'Het bestand is geüpload.',
      uploading: 'Het bestand is aan het uploaden.',
      yourFile: 'Jouw geüploade bestand.',
    },
    question: {
      instruction: {
        single: 'Er is één antwoord mogelijk.',
        multiple: 'Er zijn meerdere antwoorden mogelijk.',
      },
      submit: 'Indienen',
      close: 'Verder',
    },
  },
  mission: {
    completed: 'Missie afgerond',
    nextAvailableFrom:
      'Jullie missie is volbracht. Goed gedaan! De volgende start {{availableFrom}}. Tot die tijd hoef je niet stil te zitten. Speel challenges om je skills te verbeteren!',
    nextMissionUnknown:
      'Goed gewerkt. Dit was voor nu jullie laatste missie, wie weet worden jullie binnenkort weer opgeroepen. Het werk stopt niet hier: Blijf verbeteren door challenges te spelen. De taskforce rekent op je!',
    stat: {
      completed: 'Voltooide missies',
    },
  },
  challenges: {
    title: 'Challenges',
    introText: [
      'Altijd wat te doen!',
      'Heb jij in je missie gemerkt dat je een skill kunt verbeteren? Of wil jij jezelf alsnog graag blijven ontwikkelen? Voltooi dan de challenges die bij jou passen. Voor elke challenge die je voltooit, verdien je XP én speel je een nieuwe challenge vrij.',
    ],
    new: 'Nieuw',
    empty:
      'Zo te zien heb je alle challenges voltooid! Maak je geen zorgen, zodra je volgende missie begint, komen er nieuwe challenges beschikbaar.',
    dashboard: {
      motivation: 'Blijf je ontwikkelen!',
      motivationDone: 'Goed gedaan!',
      openChallenges_zero: 'Je hebt alle challenges in deze missie voltooid.',
      openChallenges_one: 'Er staat nog een challenge voor je open:',
      openChallenges_other:
        'Er staan nog verschillende challenges voor je open:',
      individual_one: '{{formatted}} individuele challenge',
      individual_other: '{{formatted}} individuele challenges',
      team_one: '{{formatted}} team challenge',
      team_other: '{{formatted}} team challenges',
      locked_one: 'Er is één challenge die je nog kunt unlocken.',
      locked_other:
        'Er zijn {{formatted}} challenges die je nog kunt unlocken.',
    },
    stat: {
      completed: 'Afgeronde challenges',
    },
    name_one: 'Challenge',
    name_other: 'Challenges',
  },
  taskforce: {
    title: 'Taskforce ranglijst',
    rank: '{{ord}} plaats',
    ord: {
      zero: '{{num}}e',
      one: '{{num}}ste',
      two: '{{num}}de',
      other: '{{num}}e',
    },
    name: {
      corruption: 'Taskforce corruptie',
      embezzling: 'Taskforce verduistering',
      fraud: 'Taskforce fraude',
      money_laundering: 'Taskforce witwassen',
    },
    welcome: {
      corruption:
        'It takes a network to beat a network! Welkom bij taskforce Corruptie. Samen strijden wij tegen financiële misdrijven. Verzamel zoveel mogelijk XP zodat onze taskforce bovenaan de ranglijst komt te staan!',
      embezzling:
        'It takes a network to beat a network! Welkom bij taskforce Verduistering. Samen strijden wij tegen financiële misdrijven. Verzamel zoveel mogelijk XP zodat onze taskforce bovenaan de ranglijst komt te staan!',
      fraud:
        'It takes a network to beat a network! Welkom bij taskforce Fraude. Samen strijden wij tegen financiële misdrijven. Verzamel zoveel mogelijk XP zodat onze taskforce bovenaan de ranglijst komt te staan!',
      money_laundering:
        'It takes a network to beat a network! Welkom bij taskforce Witwassen. Samen strijden wij tegen financiële misdrijven. Verzamel zoveel mogelijk XP zodat onze taskforce bovenaan de ranglijst komt te staan!',
    },
    start: 'Aan de slag',
  },
  team: {
    title: 'Team',
    joined: 'Je bent toegevoegd aan een team!',
    welcome:
      'Samen sta je sterker! Jouw team is essentieel in het voltooien van de missies. Speel, spar en bepaal jullie strategie om dossiers te doorgronden en de taskforce naar de top van de ranking te stoten. Waar wacht je nog op? Ga naar het Missiescherm en start jullie eerste team assignment!',
    yours: 'Jouw team',
    start: 'Aan de slag!',
  },
  badges: {
    intro:
      'Bekijk welke skills jij al in huis hebt en welke badges je nog kunt halen om te verbeteren!',
    collected: 'Verzamelde badges',
    empty: 'Er zijn op dit moment geen badges beschikbaar.',
    lastEarnedBadge: 'Laatst behaalde badge',
    noneEarnedYet: 'Je hebt nog geen badges behaald',
    motivator: 'Voltooi missies en challenges om badges te verdienen.',
    earned: {
      wellDone: 'Goed gedaan!',
    },
  },
  tutorial: {
    dashboard:
      'Dit is jouw dashboard. Vanuit hier kan jij navigeren naar de verschillende onderdelen van de game en houd je het overzicht over je missies, challenges en achievements.',
    taskForce:
      'Beat the best! Met jouw team ben je onderdeel van een grote taskforce. Maak jezelf onmisbaar voor de taskforce en de bank door jezelf uit te dagen en zoveel mogelijk punten te verdienen!',
    mission:
      'Hier houd je zicht op de voortgang van jullie huidige missie en de assignments die jullie nog moeten voltooien.',
    badges:
      'Door assignments en challenges goed af te ronden behaal jij badges voor de skills die je beheerst. Hier zie je waar jij je al hebt bewezen en waar nog winst te behalen valt.',
    challenges:
      'Door challenges te spelen houd jij jezelf scherp op skills en vakinhoud en verzamel je meer punten voor de taskforce.',
    xp: 'Voor alles wat je in de game doet verdien je XP: experience points. Hiermee houd je zicht op jouw individuele voortgang en draag je bij aan de ranking van je taskforce.',
  },
  xp: {
    earned: '{{count}} xp verdiend',
    label: 'xp',
    labelCaps: 'XP',
    count: '{{formatted}} xp',
    progress: '{{from}} xp / {{to}} xp',
    stat: 'Behaalde punten',
  },
  level: {
    num: 'Level {{count}}',
  },
  popup: {
    confirm: {
      delete: 'Verwijderen',
      keep: 'Behouden',
    },
    comeBackLater: {
      heading: 'Kom later terug',
      explanation1:
        'Je hebt alle voor jou op dit moment beschikbare items afgerond. Kom later terug om verder te gaan met je onboarding.',
      explanation2: 'Jouw volgende update komt beschikbaar op:',
      buttonText: 'Naar mijn overzicht',
    },
    privacyPolicy: {
      heading: 'Privacybeleid',
      explanation1:
        'Door deze app te gebruiken ga je akkoord met ons privacybeleid.',
      buttonText: 'Accepteren',
    },
  },
  header: {
    'welcome-message': 'Hoi {{name}}!',
    welcome: 'Welkom.',
    welcomeBack: 'Welkom terug.',
  },
  login: {
    succesful: 'Je bent succesvol ingelogd.',
  },
  menu: {
    dashboard: 'Dashboard',
    mission: 'Mission',
    challenges: 'Challenges',
    badges: 'Badges',
    menu: 'Menu',
    profile: 'Profiel',
    'help-and-settings': 'Help & Instellingen',
    select: 'Onderwerpen',
    settings: 'Instellingen',
  },
  error: {
    internetdown: 'Geen verbinding met het internet!',
    maintenance: {
      title: 'Onderhoud',
      subtitle:
        'De PowerApp krijgt een onderhoudsbeurt. We zijn over enkele minuten weer terug.',
    },
    versionConflict: {
      title: 'Nieuwe versie',
      text: 'Er is een nieuwe versie van de applicatie beschikbaar. Om ervoor te zorgen dat iedereen de nieuwste versie schakelen we je nu over naar de nieuwste versie.',
      refresh: 'Ga naar de nieuwste versie',
    },
    refreshButton: 'Klik om te verversen',
    internetup: 'Verbonden met het internet.',
    timeoutajax:
      'Er is een fout met de verbinding. Druk op ok om de  pagina te verversen.',
    404: 'Server niet gevonden (HTTP 404).',
    500: 'Interne server error (HTTP 500).',
    unknown: 'Onbekende fout.',
    tryReconnect: 'Bezig verbinding te zoeken...',
    alreadyAnswered: 'Dit item heeft u al beantwoord.',
    serverError:
      'Er is een fout opgetreden tijdens het contact met de server. Klik hieronder om het opnieuw te proberen, of start de app opnieuw als het probleem zich blijft voordoen.',
    retry: 'Opnieuw proberen',
  },
  common: {
    CheckAnswer: 'Check mijn antwoord',
    CheckAnswers: 'Check mijn antwoorden',
    Emailaddress: 'E-mailadres',
    Name: 'Naam',
    Checking: 'Controleren...',
    Answer: 'Sla mijn antwoord op',
    Cancel: 'Annuleren',
    Close: 'Sluiten',
    browsePrevious: 'Vorige',
    browseNext: 'Volgende',
    Accept: 'Accepteren',
    'Log out': 'Uitloggen',
    Help: 'Help',
    Hello: 'Hoi',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    CorrectNotChosen: 'Was goed geweest',
    Round: 'Ronde',
    Version: 'Versie',
    selected: 'geselecteerd',
    'No Rating': 'Geen rating',
    'No Rank': 'Geen ranking',
    NoUserSelection: 'Er zijn geen gebruikers gevonden',
    monthNames: 'Jan Feb Mar Apr Mei Juni Juli Aug Sep Okt Nov Dec',
    or: 'of',
    on: 'aan',
    off: 'uit',
    back: 'Terug',
    minutes_one: '{{formatted}} minuut',
    minutes_other: '{{formatted}} minuten',
    copyright: '© {{year}} ABN AMRO & Bright Alley',
    faq: 'Veelgestelde vragen',
  },
  profile: {
    onboarding: {
      header: 'Welkom bij The Beat!',
      description:
        'Jouw inzet is essentieel om de missies te voltooien. Maak snel je profiel aan om toegang te krijgen tot de benodigde dossiers. Zodra je door de screening bent, krijg je toegang tot je team en jullie taskforce. Wacht niet langer en ga de uitdaging aan!',
      start: 'Start!',
    },
    edit: {
      header: 'Bewerk je profiel',
      description:
        'Je kunt op elk moment hier terugkomen om je naam of profielfoto aan te passen.',
    },
    fields: {
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      function: 'Functie',
      department: 'Team',
      avatar: 'Profielfoto',
    },
    save: 'Opslaan',
    title: 'Profiel',
    subtitle: 'Pas hier je profiel aan.',
    pictureEdit: 'Bekijk of bewerk je foto.',
    'file-submit': 'Uploaden',
    'picture-submit': 'Deze uitsnede gebruiken',
    pictureCancel: 'Annuleren',
    pictureConfirmDelete:
      'Weet je zeker dat je je profielfoto wilt verwijderen?',
    'picture-nofileselected': 'Je hebt geen bestand geselecteerd.',
    'picture-nofile': 'Je hebt geen bestand geupload.',
    'picture-invalidsize':
      'Deze afbeelding is te groot. De maximale hoogte of breedte mag niet meer zijn dan 2600 pixels',
    'picture-notimage': 'Dit bestand is geen afbeelding.',
    passwordEdit: 'Wachtwoord wijzigen',
    'picture-saved': 'Je profielfoto is aangepast.',
    passwordTitle: 'Wachtwoord aanpassen',
    passwordText:
      'Pas je wachtwoord aan door eerst je huidige, en daarna twee keer je nieuwe wachtwoord in te voeren.',
    'password-old': 'Huidig wachtwoord',
    'password-new': 'Nieuw wachtwoord',
    'password-repeat': 'Herhaal wachtwoord',
    'password-submit': 'Nieuw wachtwoord opslaan',
    'password-oldpasswordmismatch': 'Je huidige wachtwoord klopt niet.',
    'password-newpasswordmismatch':
      'Het herhaalde wachtwoord komt niet overeen.',
    'password-passwordtooshort':
      'Het nieuwe wachtwoord moet minimaal 6 tekens lang zijn.',
    'password-passwordsaved': 'Je wachtwoord is opgeslagen!',
    profilePictureTitle: 'Profielfoto',
    profilePictureText: "Deze foto is ook zichtbaar voor je collega's.",
    generalDataTitle: 'Persoonlijke gegevens',
    generalDataText: 'Niet alle persoonlijke gegevens kun je zelf wijzigen.',
    profilePageTitle: 'Pas hier je profiel aan.',
    noEditDataText: 'Deze gegevens kun je niet zelf wijzigen.',
    profileLanguagePreference: 'Taalvoorkeur',
    profileLanguageChoice: 'Taalkeuze',
    notificationsTitle: 'Notificaties',
    emailNotifications: 'E-mail notificaties',
    pushNotifications: 'Push notificaties',
  },
  auth: {
    header: {
      login: 'Aanmelden',
      expired: 'Link verlopen',
      remind: 'Wachtwoord vergeten?',
      create: 'Activeer je account',
      reset: 'Nieuw wachtwoord',
      welcome: 'Welkom!',
    },
    field: {
      email: 'E-mailadres',
      password: 'Wachtwoord',
      oldPassword: 'Huidig wachtwoord',
      newPassword: 'Nieuw wachtwoord',
      newRepeatPassword: 'Herhaal nieuw wachtwoord',
      acceptPrivacyPolicy: 'Ik accepteer het <2>privacybeleid</2>. ',
      viewPrivacyPolicy: 'Bekijk het privacybeleid.',
      rememberMe: 'Onthoud mijn gegevens',
    },
    action: {
      itemcompleted: 'Je hebt een item afgerond',
      updateprofile: 'Profile bijwerken',
      sendLink: 'Verstuur wachtwoord reset e-mail',
      backToLogin: 'Ik wil inloggen',
      sendActivateLink: 'Activeer account',
      alreadyActivatedLogin: 'Ik heb al geactiveerd en wil inloggen',
      savePassword: 'Sla mijn nieuwe wachtwoord op',
      newPassword: 'Je nieuwe wachtwoord',
      repeatNewPassword: 'Herhaal je nieuwe wachtwoord',
      newPasswordPolicyIntro: 'Wachtwoordsterkte:',
    },
    selfActivate: {
      header: 'Activeer je account',
    },
    logintext: 'Aanmelden',
    remindText:
      'Geen probleem. We sturen je een e-mail waarmee je een nieuw wachtwoord kan instellen.',
    resetText: 'Voer je nieuwe wachtwoord in.',
    createText:
      'Welkom! Maak een nieuw wachtwoord aan. Daarna ben je klaar om in te loggen.',
    passwords: {
      user: 'Er is geen gebruiker bekend met deze e-mail-wachtwoord combinatie.',
      token: 'Deze wachtwoord-reset link is niet (meer) geldig.',
      sent: 'Een e-mail met de wachtwoord-reset link is naar het opgegeven e-mail adres verstuurd!',
      reset:
        'Je wachtwoord is aangepast. Voer je wachtwoord nogmaals in om in te loggen.',
      forgot: 'Ik weet mijn wachtwoord niet',
      passwordExpiredTitle: 'Wachtwoord verlopen',
      submitPasswordExpiredForm: 'Wachtwoord opslaan',
      passwordExpiredText:
        'Je wachtwoord is meer dan {{days}} dagen oud. Gebruik het onderstaande formulier om een nieuw wachtwoord in te stellen. Daarna kan je weer verder leren in de PowerApp',
      passwordCompleteSsoTitle: 'Kies een wachtwoord',
      passwordCompleteSsoText:
        'Stel een wachtwoord in voor je {{app}}-account. Je zult deze nodig hebben om in te loggen wanneer inloggen via {{idpText}} niet beschikbaar is.',
    },
  },
}

export default translations
