import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, usePaginationFragment } from 'react-relay'
import { ChallengeList_challenges$key } from '../../generated/ChallengeList_challenges.graphql'
import { useStores } from '../../stores'
import { Challenge } from './Challenge'
import styles from './ChallengeList.scss'

interface ChallengeListProps {
  challenges: ChallengeList_challenges$key
}

export function ChallengeList(props: ChallengeListProps): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()
  const {
    data: { challenges },
  } = usePaginationFragment(
    graphql`
      fragment ChallengeList_challenges on Mission
      @refetchable(queryName: "ChallengeListPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String" }
        language: { type: "String!" }
      ) {
        challenges(first: $count, after: $cursor)
          @connection(key: "ChallengeList_challenges") {
          __id
          edges {
            node {
              id
              ...Challenge_challenge @arguments(language: $language)
            }
          }
        }
      }
    `,
    props.challenges
  )

  useEffect(() => {
    if (challenges?.__id) {
      commonStore.setChallengesConnection(challenges.__id)
    }
  }, [challenges?.__id, commonStore])

  return challenges?.edges.length === 0 ? (
    <div className={styles.empty}>
      <p>{t('challenges.empty')}</p>
    </div>
  ) : (
    <ol className={styles.list}>
      {challenges?.edges
        .map((edge) => edge.node)
        .map((challenge) => (
          <Challenge challenge={challenge} key={challenge.id} />
        ))}
    </ol>
  )
}
