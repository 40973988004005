import { ForwardedRef, forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../utils/classNames'

import styles from './ProgressBar.scss'

interface ProgressBarProps {
  alternativeText?: string
  alternativeTextStyle?: string
  className?: string
  hideText?: boolean
  percentage: number
  progressBarClassName?: string
}

export const ProgressBar = forwardRef(function ProgressBar(
  props: ProgressBarProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const { i18n } = useTranslation()
  const percentage = Math.round(props.percentage)

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.progress}>
        <div
          className={classNames(styles.progressBar, props.progressBarClassName)}
          role='progressbar'
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
          ref={ref}
          style={{ width: percentage + '%' }}
        />
      </div>

      {!props.hideText && (
        <div
          className={classNames(styles.percentage, props.alternativeTextStyle)}
        >
          {props.alternativeText
            ? props.alternativeText
            : Intl.NumberFormat(i18n.language, {
                style: 'percent',
              }).format(percentage / 100)}
        </div>
      )}
    </div>
  )
})
