import { graphql } from 'react-relay'
import { commitMutation } from 'relay-runtime'
import { environment } from '../app/environment'
import {
  jsBridgeStorePushTokenMutation,
  PushTokenPlatform
} from '../app/generated/jsBridgeStorePushTokenMutation.graphql'

export function init(): void {
  window.addEventListener('message', receiveMessage as EventListener) // for ios
  document.addEventListener('message', receiveMessage as EventListener) // for android

  ready()
}

interface GetStorageMessage {
  'get-storage': string
}
interface EnablePushMessage {
  'enable-push': {
    error?: string
    token?: string
    os?: 'ios' | 'android'
    deviceId?: string
  }
}
interface TriggerConnectivityEventMessage {
  'trigger-connectivity-event': { isConnected: boolean }
}
interface SetLocaleMessage {
  'set-locale': { locale: string }
}
type ReceivedMessage =
  | GetStorageMessage
  | EnablePushMessage
  | TriggerConnectivityEventMessage
  | SetLocaleMessage
  | number

async function storePushToken(
  token: string,
  platform: PushTokenPlatform,
  device: string
) {
  return commitMutation<jsBridgeStorePushTokenMutation>(environment, {
    mutation: graphql`
      mutation jsBridgeStorePushTokenMutation(
        $token: String!
        $platform: PushTokenPlatform!
        $device: String!
      ) {
        storePushToken(device: $device, platform: $platform, token: $token) {
          __typename
        }
      }
    `,
    variables: { token, platform, device }
  })
}

function receiveMessage(message: MessageEvent): void {
  if (!message.data || typeof message.data !== 'string') {
    return
  }

  let data = null

  try {
    data = JSON.parse(message.data) as ReceivedMessage
  } catch {
    // fail silently, because this message event didn't have the right structure
    return
  }

  // Make sure we're dealing with the right kind of data.
  if (typeof data !== 'object') {
    return
  }

  // debug(message.data) // uncomment to log all messages on the RN side
  if ('get-storage' in data) {
    // eslint-disable-line eqeqeq
    debug('Retrieved storage: ' + JSON.stringify(data['get-storage']))
  }

  if ('enable-push' in data) {
    if (data['enable-push'].error !== undefined) {
      // eslint-disable-line eqeqeq
      debug('Enable push failed!')

      return
    }

    const token = data['enable-push'].token
    const os = data['enable-push'].os
    const deviceId = data['enable-push'].deviceId
    if (token && os && deviceId) {
      storePushToken(
        token,
        os.toUpperCase() as PushTokenPlatform,
        deviceId
      ).catch(reason => debug('Store push token failed' + reason))
    }
  }

  if ('trigger-connectivity-event' in data) {
    if (data['trigger-connectivity-event'].isConnected) {
      // TODO: Update state.
      // $(document).trigger(StatusEvent.INTERNET_UP)
    } else {
      // TODO: Update state.
      // $(document).trigger(StatusEvent.INTERNET_DOWN)
    }
  }

  if ('set-locale' in data) {
    debug('received locale data: ' + data['set-locale'].locale)
  }
}

export function setValueForKey(key: string, value: string): void {
  // store data
  postMessage('set-storage', { key, value })
}

export function getValueForKey(key: string): void {
  // get data from storage
  postMessage('get-storage', { key })
}

export function openInappBrowser(url: string): void {
  // open this url in a native webview
  postMessage('inapp-browser', { url })
}

export function enablePushNotifications(): void {
  // request permission and token
  postMessage('enable-push')
}

export function restartAppAfterLogout(): void {
  postMessage('restart')
}

function ready(): void {
  // let's RN know the jsBridge is ready
  postMessage('ready')
}

function debug(msg: string): void {
  // log on the RN side
  postMessage('debug', { msg })
}

function postMessage(type: string, data?: Object): void {
  const msg = JSON.stringify({
    [type]: data || {}
  })

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(msg)
  } else {
    // fallback for previous version of powerwrap
    window.postMessage(msg, '*')
  }
}
