import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useQuery } from 'relay-hooks'

import { CurrentMissionScreenQuery } from '../../../generated/CurrentMissionScreenQuery.graphql'

import { MissionScreen } from './MissionScreen'

export function CurrentMissionScreen(): ReactElement {
  const { i18n } = useTranslation()
  const mission = useQuery<CurrentMissionScreenQuery>(
    graphql`
      query CurrentMissionScreenQuery($language: String!) {
        missions(filter: CURRENT, first: 1) {
          edges {
            node {
              ...MissionScreen_mission
                @arguments(assignmentsLimit: 10, language: $language)
            }
          }
        }
      }
    `,
    { language: i18n.language },
    { fetchPolicy: 'store-and-network' }
  )

  return (
    <MissionScreen
      error={mission.error}
      isLoading={mission.isLoading}
      mission={mission?.data?.missions?.edges?.[0].node || null}
    />
  )
}
