/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MissionScreen_mission = {
    readonly banner: {
        readonly url: string;
    };
    readonly description: string;
    readonly title: string;
    readonly assignments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"Assignment_assignment">;
            };
        }>;
    } | null;
    readonly " $refType": "MissionScreen_mission";
};
export type MissionScreen_mission$data = MissionScreen_mission;
export type MissionScreen_mission$key = {
    readonly " $data"?: MissionScreen_mission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MissionScreen_mission">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "assignmentsLimit"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "MissionScreen_mission",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "banner",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "assignmentsLimit"
                    }
                ],
                "concreteType": "AssignmentConnection",
                "kind": "LinkedField",
                "name": "assignments",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Assignment",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "id",
                                        "storageKey": null
                                    },
                                    {
                                        "args": (v0 /*: any*/),
                                        "kind": "FragmentSpread",
                                        "name": "Assignment_assignment"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Mission",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '2f1c1f41a0ecfbab005bc85a58683e2d';
export default node;
