/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MissionCompletedPopup_currentMission = {
    readonly id: string;
    readonly " $refType": "MissionCompletedPopup_currentMission";
};
export type MissionCompletedPopup_currentMission$data = MissionCompletedPopup_currentMission;
export type MissionCompletedPopup_currentMission$key = {
    readonly " $data"?: MissionCompletedPopup_currentMission$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MissionCompletedPopup_currentMission">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MissionCompletedPopup_currentMission",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Mission",
    "abstractKey": null
} as any;
(node as any).hash = '10dad88c125b2748354a26a211cc8a8a';
export default node;
