import { forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { XpStats_stats$key } from '../../../generated/XpStats_stats.graphql'
import { Stat } from './Stat'

interface XpStatsProps {
  stats: XpStats_stats$key
}

export const XpStats = forwardRef<HTMLDivElement, XpStatsProps>(
  function XpStats(props, ref): ReactElement {
    const { t } = useTranslation()
    const { me, stats } = useFragment(
      graphql`
        fragment XpStats_stats on Query {
          me {
            xp
          }
          stats {
            totalXp
          }
        }
      `,
      props.stats
    )

    return (
      <Stat
        caption={t('xp.stat')}
        color='#00bcb3'
        number={me?.xp ?? 0}
        numberLabel={t('xp.labelCaps')}
        percentage={((me?.xp ?? 0) / stats.totalXp) * 100}
        ref={ref}
      />
    )
  }
)
