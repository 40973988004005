import { RefObject, useCallback, useMemo, useReducer, useRef } from 'react'
import { BadgeEarnedPopup_badge$key } from '../../generated/BadgeEarnedPopup_badge.graphql'
import { EditProfilePopup_user$key } from '../../generated/EditProfilePopup_user.graphql'
import { MissionCompletedPopup_completedMission$key } from '../../generated/MissionCompletedPopup_completedMission.graphql'
import { MissionCompletedPopup_currentMission$key } from '../../generated/MissionCompletedPopup_currentMission.graphql'
import { NewMissionPopup_mission$key } from '../../generated/NewMissionPopup_mission.graphql'
import { OnboardingPopup_user$key } from '../../generated/OnboardingPopup_user.graphql'
import { TaskCompletedPopup_task$key } from '../../generated/TaskCompletedPopup_task.graphql'
import { TaskforceJoinedPopup_firstMission$key } from '../../generated/TaskforceJoinedPopup_firstMission.graphql'
import { TaskforceJoinedPopup_taskforce$key } from '../../generated/TaskforceJoinedPopup_taskforce.graphql'
import { TaskPopup_task$key } from '../../generated/TaskPopup_task.graphql'
import { TeamJoinedPopup_team$key } from '../../generated/TeamJoinedPopup_team.graphql'
import { never } from '../assert'

export type PopupAction<P extends Popup> =
  | { type: 'push'; popup: P }
  | { type: 'replace'; popup: P }
  | { type: 'pop' }
  | { type: 'unshift'; popup: P }
export type PopupController<P extends Popup> = (action: PopupAction<P>) => void
export type ClosePopupAction = () => void

let uniqueCounter = 0
function patchPopupKey<P extends Popup>(popup: P): P {
  if (!popup.popupKey) {
    popup.popupKey = `${popup.type}${uniqueCounter++}`
  }

  return popup
}

export interface PopupControllerProps<P extends Popup> {
  upToDatePopup: RefObject<P | null>
  closeCurrentPopup: ClosePopupAction
  hasPopup<T extends P['type'], U extends P & { type: T }>(
    type: T,
    filter?: (popup: U) => boolean
  ): boolean
  openPopup(popup: P, insertInFront?: boolean): void
  replaceCurrentPopup(popup: P): void
  popups: P[]
  updatePopups: PopupController<P>
}

export function usePopups<P extends Popup>(): PopupControllerProps<P> {
  const upToDatePopup = useRef<P | null>(null)
  const [popups, updatePopups] = useReducer(
    (previous: P[], action: PopupAction<P>): P[] => {
      const updated = (() => {
        switch (action.type) {
          case 'push':
            return [...previous, patchPopupKey(action.popup)]
          case 'replace':
            return [patchPopupKey(action.popup), ...previous.slice(1)]
          case 'pop':
            return previous.slice(1)
          case 'unshift':
            return [patchPopupKey(action.popup), ...previous]
          default:
            never(action, 'Invalid local popup action')
        }
      })()

      upToDatePopup.current = updated.length > 0 ? updated[0] : null
      return updated
    },
    []
  )
  const hasPopup = useCallback(
    <T extends P['type'], U extends P & { type: T }>(
      type: T,
      filter?: (popup: U) => boolean
    ): boolean => {
      const matchingPopups: U[] = popups.filter(
        (popup): popup is U => popup.type === type
      )

      if (filter) {
        return matchingPopups.filter(filter).length > 0
      }

      return matchingPopups.length > 0
    },
    [popups]
  )

  // Convenience methods
  const closeCurrentPopup = useCallback((): void => {
    updatePopups({ type: 'pop' })
  }, [])
  const openPopup = useCallback((popup: P, insertInFront = false): void => {
    updatePopups({
      type: insertInFront ? 'unshift' : 'push',
      popup,
    })
  }, [])
  const replaceCurrentPopup = useCallback((popup: P): void => {
    updatePopups({ type: 'replace', popup })
  }, [])

  return useMemo(
    () => ({
      closeCurrentPopup,
      hasPopup,
      openPopup,
      popups,
      replaceCurrentPopup,
      upToDatePopup,
      updatePopups,
    }),
    [closeCurrentPopup, hasPopup, openPopup, popups, replaceCurrentPopup]
  )
}

export function canShowPopup(
  popup: Popup,
  user: Record<string, unknown> | undefined | null
): boolean {
  // All popups can be shown if the user is logged in, otherwise only show
  // maintenance mode popups.
  return !!user || popup.type === 'maintenance'
}

export type AvatarCropPopup = {
  type: 'crop-avatar'
  image: string
  mime: string
  filename: string
}
export type BadgeEarnedPopup = {
  badge: BadgeEarnedPopup_badge$key
  type: 'badge-earned'
}
export type ConfirmYesNoPopup = {
  type: 'confirm-yes-no'
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
  onClose?: () => void
  onConfirm(): Promise<void>
}
export type EditProfilePopup = {
  type: 'edit-profile'
  user: EditProfilePopup_user$key
}
export type InternetRequiredPopup = { type: 'internet-required' }
export type MaintenanceModePopup = {
  type: 'maintenance'
}
export type MissionCompletedPopup = {
  connection: string
  completedMission: MissionCompletedPopup_completedMission$key
  currentMission: MissionCompletedPopup_currentMission$key
  type: 'mission-completed'
}
export type NewMissionPopup = {
  connection?: string
  mission: NewMissionPopup_mission$key
  shouldStartTutorial?: boolean
  type: 'new-mission'
}
export type NewVersionPopup = { type: 'new-version' }
export type PrivacyPolicyPopup = {
  type: 'privacy-policy'
}
export type TaskCompletedPopup = {
  task: TaskCompletedPopup_task$key
  type: 'task-completed'
}
export type TaskforceJoinedPopup = {
  firstMission: TaskforceJoinedPopup_firstMission$key | null
  taskforce: TaskforceJoinedPopup_taskforce$key
  type: 'taskforce-joined'
}
export type TaskPopup = {
  task: TaskPopup_task$key
  taskType: 'assignment' | 'challenge'
  type: 'task'
}
export type TeamJoinedPopup = {
  team: TeamJoinedPopup_team$key
  type: 'team-joined'
}
export type OnboardingPopup = {
  type: 'onboarding'
  user: OnboardingPopup_user$key
}

export type PopupProps<T = unknown, P extends Popup = Popup> = T &
  PopupControllerProps<P>
export type Popup = (
  | AvatarCropPopup
  | BadgeEarnedPopup
  | ConfirmYesNoPopup
  | EditProfilePopup
  | InternetRequiredPopup
  | MaintenanceModePopup
  | MissionCompletedPopup
  | NewMissionPopup
  | NewVersionPopup
  | PrivacyPolicyPopup
  | TaskforceJoinedPopup
  | TaskCompletedPopup
  | TaskPopup
  | TeamJoinedPopup
  | OnboardingPopup
) & { popupKey?: string }
