/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Badge_badge = {
    readonly image: {
        readonly url: string;
    };
    readonly skill: {
        readonly name: string;
    };
    readonly " $refType": "Badge_badge";
};
export type Badge_badge$data = Badge_badge;
export type Badge_badge$key = {
    readonly " $data"?: Badge_badge$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Badge_badge">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Badge_badge",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "language",
                            "variableName": "language"
                        }
                    ],
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Badge",
    "abstractKey": null
} as any;
(node as any).hash = '853fe7e688462911b73b0db0814fc3af';
export default node;
