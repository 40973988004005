/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppEnvironmentSettingsQueryVariables = {};
export type AppEnvironmentSettingsQueryResponse = {
    readonly settings: {
        readonly editableTranslations: ReadonlyArray<{
            readonly language: string;
            readonly translations: ReadonlyArray<{
                readonly key: string;
                readonly value: string;
            }> | null;
        }>;
        readonly language: string;
        readonly loginWithCompanyUserId: boolean;
        readonly singleSignOn: {
            readonly idpOnly: boolean;
            readonly idps: ReadonlyArray<{
                readonly description: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }> | null;
                readonly displayName: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }>;
                readonly isHybrid: boolean;
                readonly loginUrl: string;
                readonly name: string;
                readonly setPasswordInstruction: ReadonlyArray<{
                    readonly language: string;
                    readonly value: string;
                }> | null;
            }>;
        };
        readonly supportUrl: string;
        readonly privacyPolicyUrl: string;
        readonly xp: {
            readonly exponentBase: number;
            readonly xpForFirstLevel: number;
        };
    };
};
export type AppEnvironmentSettingsQuery = {
    readonly response: AppEnvironmentSettingsQueryResponse;
    readonly variables: AppEnvironmentSettingsQueryVariables;
};



/*
query AppEnvironmentSettingsQuery {
  settings {
    editableTranslations {
      language
      translations {
        key
        value
      }
    }
    language
    loginWithCompanyUserId
    singleSignOn {
      idpOnly
      idps {
        description {
          language
          value
        }
        displayName {
          language
          value
        }
        isHybrid
        loginUrl
        name
        setPasswordInstruction {
          language
          value
        }
      }
    }
    supportUrl
    privacyPolicyUrl
    xp {
      exponentBase
      xpForFirstLevel
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any, v2 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "EnvironmentSettings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EditableTranslations",
                    "kind": "LinkedField",
                    "name": "editableTranslations",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EditableTranslation",
                            "kind": "LinkedField",
                            "name": "translations",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "key",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loginWithCompanyUserId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SsoSettings",
                    "kind": "LinkedField",
                    "name": "singleSignOn",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "idpOnly",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SsoIdpConfiguration",
                            "kind": "LinkedField",
                            "name": "idps",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "description",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "displayName",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isHybrid",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "loginUrl",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LocalizedString",
                                    "kind": "LinkedField",
                                    "name": "setPasswordInstruction",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supportUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privacyPolicyUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "XpSettings",
                    "kind": "LinkedField",
                    "name": "xp",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "exponentBase",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "xpForFirstLevel",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AppEnvironmentSettingsQuery",
            "selections": (v3 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AppEnvironmentSettingsQuery",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "de91ba274cb9b09681eb9532a9207992",
            "id": null,
            "metadata": {},
            "name": "AppEnvironmentSettingsQuery",
            "operationKind": "query",
            "text": "query AppEnvironmentSettingsQuery {\n  settings {\n    editableTranslations {\n      language\n      translations {\n        key\n        value\n      }\n    }\n    language\n    loginWithCompanyUserId\n    singleSignOn {\n      idpOnly\n      idps {\n        description {\n          language\n          value\n        }\n        displayName {\n          language\n          value\n        }\n        isHybrid\n        loginUrl\n        name\n        setPasswordInstruction {\n          language\n          value\n        }\n      }\n    }\n    supportUrl\n    privacyPolicyUrl\n    xp {\n      exponentBase\n      xpForFirstLevel\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'fb2d3ad917c631bc89c56508b493b65f';
export default node;
